import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import EntityCard from "@components/fragments/EntityCard";

import Button from "@ui/Button";
import Spinner from "@ui/Spinner";
import TabButtons from "@ui/TabButtons";
import TabContent from "@ui/TabContent";

import {
  actTypeNameMap,
  axiosErrorMessages,
  labels,
  toastOptions,
  toastOptionsError,
} from "@constants";

import {
  setLastReachedStep,
  setValidatedData,
} from "@reducers/actCreationSlice";
import {
  selectPostState,
  setModalData,
  setPostState,
} from "@reducers/dataTransferSlice";
import { selectUser } from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";

import { EntityTabsProps, Lawyer, PathMap, SignatoriesParties } from "@types";

import { getPathMap } from "@utils";
import { fetchLawyers, fetchSignatories } from "@utils/api/api";

const EntityTabs = ({
  partyData,
  setPartyData,
  actions = true,
  actDetails,
}: EntityTabsProps) => {
  const { actType, publicId } = useParams();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [partyGetFinished, setPartyGetFinished] = useState<boolean>(false);
  const [lawyerGetFinished, setLawyerGetFinished] = useState<boolean>(false);
  const [lawyerData, setLawyerData] = useState<Lawyer[]>([]);
  const [initializing, setInitializing] = useState<boolean>(true);
  const [id, setId] = useState<number | null>(null);
  const [actCreator, setActCreator] = useState<string | null>(null);
  const [actInfo, setActInfo] = useState<any>([]);
  const [locked, setLocked] = useState<boolean>(false);
  const pathMap: PathMap = getPathMap(actType, publicId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const creationState = useSelector(selectPostState);

  useEffect(() => {
    if (!actDetails?.metaDataValues) {
      return;
    }
    setActInfo(actDetails);
    setId(actDetails.id);
    setActCreator(actDetails.actCreatorCNBF);
    // If the act type is different from the one we're on, we redirect to the correct one
    const actTypeDcm = actTypeNameMap[actDetails.businessType];
    const normalizedType =
      undefined !== actTypeDcm ? actTypeDcm : actTypeNameMap[actDetails.type];

    if (normalizedType !== actType) {
      navigate(`/acts/${normalizedType}/${publicId}/parties`);
    }
    const responseStep = actDetails.metaDataValues.find(
      (item: any) => item.code === "wfStep",
    )?.value;

    dispatch(setLastReachedStep(responseStep));
  }, [actDetails, actType, dispatch, navigate, publicId]);

  const partyDataUpdate = (dataOfParties: any[]) => {
    if (dataOfParties) {
      let partyUpdate: SignatoriesParties[] = [];

      // Create a mapping from lawyer codeCNBF to lawyer details
      const lawyerMap = new Map();

      lawyerData.forEach((lawyer: Lawyer) => {
        lawyerMap.set(lawyer.codeCNBF, {
          firstName: lawyer.firstName,
          lastName: lawyer.lastName,
        });
      });

      // Update the partyData with the advisedBy name from lawyerMap
      dataOfParties.forEach((party) => {
        if (party.advisedBy?.[0]?.id && lawyerMap.has(party.advisedBy[0].id)) {
          const lawyerDetails = lawyerMap.get(party.advisedBy[0].id);

          if (party.advisedBy[0].name === "") {
            const newParty = {
              ...party,
              advisedBy: party.advisedBy.map(
                (advisor: Lawyer, index: number) =>
                  index === 0
                    ? {
                        ...advisor,
                        name: `${lawyerDetails.firstName} ${lawyerDetails.lastName}`,
                      }
                    : advisor,
              ),
            };
            partyUpdate.push(newParty);
          } else {
            partyUpdate.push(party);
          }
        } else {
          partyUpdate.push(party);
        }
      });

      setPartyData(partyUpdate);
    }
  };

  // Add party to advisingPartyName array of the new lawyer
  const lawyerDataUpdate = (dataOfLawyers: Lawyer[]) => {
    if (dataOfLawyers) {
      let lawyerUpdate: Lawyer[] = [];

      // Create a mapping from lawyer codeCNBF to advised parties
      const lawyerPartyMap = new Map();

      partyData.forEach((party: any) => {
        dataOfLawyers.forEach((lawyer) => {
          if (party.advisedBy?.[0]?.id === lawyer.codeCNBF) {
            // Initialize the array if it doesn't exist
            if (!lawyerPartyMap.has(lawyer.codeCNBF)) {
              lawyerPartyMap.set(lawyer.codeCNBF, []);
            }

            // Push the party details into the array
            lawyerPartyMap.get(lawyer.codeCNBF).push({
              id: `${party.id}`,
              name:
                party.type === "TYPE_LEGAL"
                  ? party.companyName
                  : `${party.firstName} ${party.lastName}`,
            });
          }
        });
      });

      // Update the lawyerData with the aggregated advisedPartyName
      lawyerData.forEach((lawyer) => {
        const newLawyer = { ...lawyer };
        newLawyer.advisingPartyName = lawyerPartyMap.get(lawyer.codeCNBF);
        lawyerUpdate.push(newLawyer);
      });

      setLawyerData(lawyerUpdate);
      // dispatch(setPageData({ lawyerData: lawyerUpdate }));
    }
  };

  useEffect(() => {
    // Exit early if we reached the end of initializing logic
    // to prevent duplicate requests
    if (!initializing && !creationState) {
      return;
    }

    if (id === null || user === null) {
      return;
    }

    const initializeData = async () => {
      try {
        const [signatoriesData, lawyersData] = await Promise.all([
          fetchSignatories(publicId, user, labels),
          fetchLawyers(publicId, user, labels),
        ]);
        setPartyData(signatoriesData);
        setPartyGetFinished(true);
        setLawyerData(lawyersData);
        setLawyerGetFinished(true);
      } catch (error) {
        // Error handling is already managed within the fetch functions
      }
    };

    initializeData();
    setInitializing(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, creationState, user]);

  useEffect(() => {
    if (partyGetFinished && lawyerGetFinished) {
      partyDataUpdate(partyData);
      lawyerDataUpdate(lawyerData);
      setPartyGetFinished(false);
      setLawyerGetFinished(false);
      dispatch(setPostState(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyGetFinished, lawyerGetFinished]);

  useEffect(() => {
    if (partyData.length === 0) {
      dispatch(setValidatedData(false));
      setLocked(false);
      return;
    }

    if (!locked && actType !== "convention" && actType !== "digital") {
      dispatch(setValidatedData(false));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editPartyCallback = (index: number) => {
    const preparedData = {
      party: partyData[index],
      index: index,
      id: id,
    };

    dispatch(setModalData(preparedData));
    dispatch(showModal("editParty"));
  };

  const editLawyerCallback = (index: number) => {
    const preparedData = {
      lawyerCode: lawyerData[index].codeCNBF || {},
      lawyer: lawyerData[index],
      index: index,
      id: id,
    };

    dispatch(setModalData(preparedData));
    dispatch(showModal("editLawyer"));
  };

  const handlePartyNotification = (partyId: string) => {
    axios
      .post(
        `/api/v1/signbooks/${publicId}/signatories/${partyId}/notification?type=VERIFY`,
      )
      .then((response) => {
        if (response.status === 204) {
          toast.success(labels.notificationSuccess, toastOptions);
        }
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const deletePartyButtonTitle =
    "convention" === actType
      ? labels.deleteClientLabel
      : labels.deletePartyLabel;

  const notifyParty =
    actInfo.signingStatus === "BEFORE_SIGNING" &&
    (actInfo.step === "created" || actInfo.step === "shared") &&
    actInfo.type !== "numerise"
      ? {
          label:
            actInfo.type !== "econvention"
              ? labels.notifyParty
              : labels.notifyClient,
          onClick: (index: number) => {
            handlePartyNotification(partyData[index].id);
          },
        }
      : "";

  const dropdownOptionsForSignatories = [
    {
      label: labels.modificationParty,
      onClick: editPartyCallback,
    },
    ...(notifyParty ? [notifyParty] : []),
    {
      label: labels.changeLawyer,
      onClick: (index: number) => {
        const preparedData = {
          party: partyData[index],
          lawyerData: lawyerData,
          index: index,
          signBookNumber: publicId,
        };
        dispatch(setModalData(preparedData));
        dispatch(showModal("changeLawyer"));
      },
    },
    {
      label: deletePartyButtonTitle,
      onClick: (index: number) => {
        const preparedData = {
          partyId: partyData[index].id,
          lawyerId: partyData[index].advisor,
          actCreator,
        };
        dispatch(setModalData(preparedData));
        dispatch(showModal("deleteParty"));
      },
    },
  ];

  if (actType === "convention") {
    // Remove the first option
    dropdownOptionsForSignatories.splice(0, 1);
    // Remove the 3rd option
    dropdownOptionsForSignatories.splice(1, 1);
  }
  if (actType === "digital") {
    dropdownOptionsForSignatories.splice(0, 1);
  }

  const dropdownOptionsForLawyers = [
    {
      label: labels.modificationLawyer,
      link: "",
      onClick: editLawyerCallback,
    },
  ];

  const firstStepPath = publicId ? `/${publicId}/information` : pathMap[1].url;
  const thirdStepPath = pathMap[3]?.url || "";

  const nextButtonLabel =
    "convention" === actType || "digital" === actType || locked
      ? labels.next
      : labels.lockParty;

  useEffect(() => {
    const extractPartiesForSealing = partyData.filter(
      (party: any) => party.linkedTo === user.numCNBF,
    );

    const allLocked = extractPartiesForSealing.every(
      (extract: any) => extract.isLocked,
    );

    setLocked(allLocked);
  }, [partyData, user.numCNBF]);

  const disabledState = () => {
    if (actType === "divorce") {
      return partyData.length < 2;
    }
    return partyData.length === 0;
  };

  if (initializing) {
    return (
      <div className="flex justify-center items-center p-6 -mb-2 bg-[#F9F9F9]">
        <Spinner className="size-10 !border-t-black" />
      </div>
    );
  }

  const tables = [
    <EntityCard
      parties={partyData}
      setParties={setPartyData}
      dropdownOptions={dropdownOptionsForSignatories}
      buttonLabel=""
      icon=""
      infoMessage={true}
      hasButton={true}
      className="border-none shadow-md shadow-stone-400 rounded-lg px-4 py-2 h-[107px] mt-6"
      onClick={editPartyCallback}
      actInfo={actInfo}
    />,
    <EntityCard
      parties={lawyerData}
      setParties={setLawyerData}
      dropdownOptions={dropdownOptionsForLawyers}
      buttonLabel=""
      icon=""
      infoMessage={true}
      hasButton={true}
      className="border-none shadow-md shadow-stone-400 rounded-lg px-4 py-2 h-[107px] mt-6"
      onClick={editLawyerCallback}
      actInfo={actInfo}
    />,
  ];

  if ("convention" === actType) {
    tables.pop();
  }

  const scrollClass =
    partyData.length > 3 || lawyerData.length > 3
      ? "overflow-y-auto h-[410px]"
      : "";
  const loadingClass = creationState ? "animate-pulse opacity-10" : "";

  let tabsList =
    "convention" !== actType
      ? [
          {
            title: labels.parties,
            id: 0,
            testId: "parties-tab",
          },
          {
            title: labels.lawyers,
            id: 1,
            testId: "lawyers-tab",
          },
        ]
      : [];

  return (
    <>
      <div className="flex flex-col max-md:ml-0 max-md:w-full mb-10">
        <div className="flex flex-col self-stretch my-auto max-sm:mt-0 max-md:mt-10 max-md:max-w-[620px]">
          {/* Tabs */}
          <TabButtons
            tab={activeTab}
            setTab={setActiveTab}
            tabsList={tabsList}
          />
          <TabContent
            activeTab={activeTab}
            data={tables}
            className={`${scrollClass} border-t mt-[5px] px-1 py-2 ${loadingClass}`}
          />
        </div>
      </div>

      {actions && (
        <div className="lg:auto text-black body-lg">
          <section className="w-full lg:w-[605px] mx-auto">
            <div className="flex justify-between items-center">
              <Link
                to={`/acts/${actType}${firstStepPath}`}
                className="btn-base btn-secondary"
              >
                {labels.previous}
              </Link>

              <Button
                type="button"
                className="btn-base btn-primary"
                onClick={() => {
                  if (
                    actType !== "convention" &&
                    actType !== "digital" &&
                    !locked
                  ) {
                    dispatch(showModal("sealMyParties"));
                    return;
                  }

                  if (partyData.length > 0) {
                    navigate(`/acts/${actType}${thirdStepPath}`);
                  }
                }}
                disabled={disabledState()}
                data-testid="next"
              >
                {nextButtonLabel}
              </Button>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default EntityTabs;

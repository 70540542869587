import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "@components/layout/Header";
import Sidebar from "@components/layout/Sidebar";

import EAFDebug from "@eaf/components/EAFDebug";

import Modals from "@modals/Modals";

import { selectUser, selectVersion } from "@reducers/metadataSlice";

import { LayoutProps } from "@types";

const Layout = ({
  onboarding = false,
  sidebar = false,
  children,
  backgroundColor,
  quitButton,
  downloadButton,
  printButton,
  quitButtonWithoutModal,
  helpButton = true,
  logoLink = true,
}: LayoutProps) => {
  const version = useSelector(selectVersion);
  const user = useSelector(selectUser);

  const bgColor = backgroundColor || "bg-white";

  return (
    <div className={`${bgColor} min-h-screen`}>
      <ToastContainer className="body-lg" />
      <Modals />
      <div className="flex mx-auto">
        {sidebar && <Sidebar version={version} />}
        <section className="w-full">
          <Header
            sidebar={sidebar}
            quitButton={quitButton}
            quitButtonWithoutModal={quitButtonWithoutModal}
            downloadButton={downloadButton}
            printButton={printButton}
            onboarding={onboarding}
            helpButton={helpButton}
            userName={
              user.firstName ? `${user.firstName} ${user.lastName}` : ""
            }
            logoLink={logoLink}
          />
          <main className="w-full">{children}</main>
        </section>
      </div>

      <EAFDebug />
    </div>
  );
};

export default Layout;

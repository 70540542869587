import { ErrorMessage, Field } from "formik";
import ReactDatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import CustomDatePickerHeader from "@ui/DatepickerCustomHeader";
import FileUpload from "@ui/FileUpload";
import Icon from "@ui/Icon";
import PhoneField from "@ui/PhoneField";

import {
  DOCUMENTS_MAX_SIZE,
  countryPhoneCodes,
  labels,
  toastOptions,
  toastOptionsError,
} from "@constants";

import { Document } from "@types";

import { bytesToMegabytes, formatLabel } from "@utils";

const PhysicalPerson = ({
  files,
  setFiles,
  uploadFileControl,
  formik,
  locked,
}: {
  files: Document[];
  setFiles: React.Dispatch<React.SetStateAction<Document[]>>;
  uploadFileControl: string;
  formik: any;
  locked: boolean;
}) => {
  const { actType } = useParams();
  const isDivorce = "divorce" === actType;
  const isFilesPresent = files.length > 0;

  const storeFiles = (
    filesToStore: Document[],
    existingFiles: Document[] = files,
  ) => {
    // Calculate the total filesize of every file in bytes.
    const newFiles = [...existingFiles, ...filesToStore];

    const totalFileSize = newFiles.reduce((acc, file) => acc + file.size, 0);

    // Check if the total filesize exceeds the limit.
    if (totalFileSize > DOCUMENTS_MAX_SIZE) {
      toast.error(
        formatLabel(
          labels.filesizeLimitReached,
          bytesToMegabytes(DOCUMENTS_MAX_SIZE).toString(),
        ),
        toastOptionsError,
      );
      return;
    }
    setFiles(newFiles);

    if (newFiles.length > 0) {
      toast.success(labels.documentSuccessfullyAdded, toastOptions);
    }
  };

  // Handle the timezone offset when the date of birth is changed.
  const handleDateBirthChange = (
    date: {
      getTime: () => number;
      getTimezoneOffset: () => number;
    } | null,
  ) => {
    if (date) {
      // Create a new Date object with the same day, month, and year, but in the local timezone
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000,
      );
      formik.setFieldValue("birthDate", localDate);
    }
  };

  const disabledInputStyle = locked ? "border-[#F5F5F5]" : "border-ea-gray-200";

  return (
    <div className="mt-8">
      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex flex-col md:w-[280px]">
          <label htmlFor="lastName" className="body-sm mb-[10px] text-gray-400">
            {labels.surname}
          </label>
          <Field
            type="text"
            id="lastName"
            name="lastName"
            className={`input border rounded p-2 bg-white body-lg h-[34px] ${disabledInputStyle}`}
            placeholder=""
            disabled={locked}
          />
          <ErrorMessage name="lastName">
            {(msg) => (
              <span className="text-[10px] max-w-[293px] text-ea-red">
                {msg}
              </span>
            )}
          </ErrorMessage>
        </div>
        <div className="flex flex-col md:w-[280px]">
          <label
            htmlFor="firstName"
            className="body-sm mb-[10px] text-gray-400"
          >
            {labels.name}
          </label>
          <Field
            type="text"
            id="firstName"
            name="firstName"
            className={`input border rounded p-2 bg-white body-lg h-[34px] ${disabledInputStyle}`}
            placeholder=""
            disabled={locked}
          />
          <ErrorMessage name="firstName">
            {(msg) => (
              <span className="text-[10px] max-w-[293px] text-ea-red">
                {msg}
              </span>
            )}
          </ErrorMessage>
        </div>
      </div>

      <div className="flex flex-col mt-6 md:flex-row justify-between ">
        <div className="flex flex-col md:w-[180px]">
          <label
            htmlFor="birthDate"
            className="body-sm mb-[10px] text-gray-400"
          >
            {labels.birthDate}
          </label>
          <div className="flex relative flex-col md:w-[180px]">
            <Icon
              type="calendar"
              color="#000"
              className="absolute top-[50%] left-[16px] transform -translate-y-1/2 w-[17px] h-[19px] z-[1]"
            />
            <ReactDatePicker
              renderCustomHeader={(props) => (
                <CustomDatePickerHeader {...props} />
              )}
              className={`input border rounded body-lg h-[34px] p-2 bg-white md:w-[183px] text-center ${disabledInputStyle}`}
              selected={formik.values.birthDate}
              onChange={(date) => handleDateBirthChange(date)}
              onBlur={() => formik.setFieldTouched("birthDate", true)}
              dateFormat="dd/MM/yyyy"
              disabled={locked}
            />
          </div>
          <ErrorMessage name="birthDate">
            {(msg) => (
              <span className="text-[10px] max-w-[293px] text-ea-red">
                {msg}
              </span>
            )}
          </ErrorMessage>
        </div>
        <div className="flex flex-col md:w-[180px]">
          <label htmlFor="email" className="body-sm mb-[10px] text-gray-400">
            {labels.email}
          </label>
          <Field
            type="email"
            id="email"
            name="email"
            className="input border border-ea-gray-200 rounded body-lg h-[34px] p-2 bg-white"
          />
          <ErrorMessage name="email">
            {(msg) => (
              <span className="text-[10px] max-w-[293px] text-ea-red">
                {msg}
              </span>
            )}
          </ErrorMessage>
        </div>
        {/* Phone */}
        <div className="flex flex-col md:w-[180px]">
          <label
            htmlFor="phone-input"
            className="body-sm mb-[10px] text-gray-400"
          >
            {labels.phone}
          </label>
          <div className="flex items-center md:w-[180px] relative">
            <PhoneField
              codes={countryPhoneCodes}
              phoneCodeName="countryCode"
              setFieldValue={formik.setFieldValue}
            />
          </div>

          <ErrorMessage name="phone">
            {(msg) => (
              <span className="text-[10px] max-w-[293px] text-ea-red">
                {msg}
              </span>
            )}
          </ErrorMessage>
        </div>
      </div>
      <div>
        {actType !== "convention" && (
          <div className="flex flex-col mt-6">
            <label
              htmlFor="fileUpload"
              className="body-sm mb-[10px] text-gray-400"
            >
              {labels.addNewID}
            </label>

            <Field name="IDdocument">
              {() => (
                <div className="modal-file-upload">
                  <FileUpload
                    files={files}
                    setFiles={storeFiles}
                    minimized={true}
                    acceptedFileTypes={{
                      "image/jpeg": [],
                      "image/png": [],
                      "image/bmp": [],
                      "application/pdf": [],
                    }}
                    onChange={(files: Document[]) => {
                      const IDfiles =
                        0 !== files.length
                          ? files.map((file) => {
                              const { name } = file;

                              return {
                                name,
                              };
                            })
                          : [];

                      formik.setFieldValue("IDdocument", IDfiles);
                    }}
                  />

                  {0 !== files.length && (
                    <div className="scrollbar-outside max-h-[130px] overflow-y-auto space-y-[10px] mt-[10px]">
                      {files.map((file, index) => {
                        return (
                          <div
                            key={index}
                            className="flex items-center py-1 px-2 border border-black border-dashed rounded-md h-[34px] w-full mx-auto relative"
                          >
                            <span className="body-sm truncate max-w-[165px]">
                              {file.name || file.path}
                            </span>
                            <div className="absolute top-0 right-0 px-2 h-full flex justify-center items-center">
                              <Button
                                type="button"
                                onClick={() => {
                                  if (0 === files.length) {
                                    return;
                                  }

                                  const newFiles = (files as Document[]).filter(
                                    (_, i) => i !== index,
                                  );

                                  setFiles(newFiles);

                                  if (0 === newFiles.length) {
                                    formik.setFieldValue("IDdocument", []);
                                  }
                                }}
                              >
                                <Icon type="trash" className="size-[18px]" />
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </Field>
            <div>
              {isFilesPresent ? null : (
                <span
                  className={`text-[10px] max-w-[293px] text-ea-red ${uploadFileControl}`}
                >
                  {formatLabel(labels.requiredField, labels.addNewID)}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 w-[180px]">
        <label
          className={`${isDivorce && "disabled"} flex relative body-md text-center items-center custom-checkbox-container`}
        >
          <Field name="signatureInPresence">
            {({ field }: any) => {
              return (
                <input
                  {...field}
                  type="checkbox"
                  id="signatureInPresence"
                  className="custom-checkbox"
                  disabled={isDivorce}
                  checked={isDivorce ? true : field.value}
                />
              );
            }}
          </Field>
          {labels.signatureInPresence}
        </label>
      </div>
    </div>
  );
};

export default PhysicalPerson;

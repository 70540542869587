import { Field, useFormikContext } from "formik";
import { useEffect, useState } from "react";

import Button from "@ui/Button";
import Icon from "@ui/Icon";

import { PhoneFieldProps } from "@types";

const PhoneField = ({
  phoneName = "phone",
  phoneCodeName = "phoneCode",
  codes,
  setFieldValue,
  inversedDropdown = false,
  required = false,
  disabled = false,
  validate,
}: PhoneFieldProps) => {
  const [visiblityClass, setVisiblityClass] = useState("hidden");
  const { getFieldProps } = useFormikContext();
  const phoneCodeValue = getFieldProps(phoneCodeName);
  const dropdownPositionClass = inversedDropdown ? "bottom-full" : "top-full";

  const fieldClass =
    "block input border border-[#B4B4B4] rounded-r body-lg h-[34px] p-2 ml-[-1px] w-full md:w-[calc(100%+1px)]";
  const fieldClassDisabled = fieldClass.replace(
    "border-[#B4B4B4]",
    "border-[#F5F5F5] bg-white",
  );
  const fieldClassFinal = disabled ? fieldClassDisabled : fieldClass;
  const phoneCodeSelectClass = disabled
    ? "phone-code-select phone-code-select-disabled"
    : "phone-code-select";

  useEffect(() => {
    const hideDropdown = () => {
      if (visiblityClass === "block") {
        setVisiblityClass("hidden");
      }
    };

    window.addEventListener("click", hideDropdown);

    return () => {
      window.removeEventListener("click", hideDropdown);
    };
  });

  return (
    <div className="flex items-center relative w-full">
      <div className="self-stretch relative">
        <Field as="select" className="hidden" name={phoneCodeName}>
          {codes.map((code: string, index: number) => (
            <option key={index} value={code}>
              {code}
            </option>
          ))}
        </Field>
        <div
          className={phoneCodeSelectClass}
          onClick={(event) => {
            if (disabled) {
              return;
            }

            event.stopPropagation();
            setVisiblityClass(visiblityClass === "hidden" ? "block" : "hidden");
          }}
          data-testid="phone-code-select-dropdown-toggle"
        >
          {phoneCodeValue.value}
        </div>
        <Icon
          type="dropdown-arrow"
          className="size-[12px] absolute top-1/2 right-[8px] -translate-y-2/4 pointer-events-none"
          color="#000"
        />
        <div
          className={`phone-code-select-dropdown ${dropdownPositionClass} ${visiblityClass}`}
          data-testid="phone-code-select-dropdown"
        >
          <ul className="py-2 text-sm">
            {codes.map((code: string, index: number) => (
              <li key={index}>
                <Button
                  type="button"
                  className="inline-flex w-full px-4 py-2 text-sm body-sm"
                  role="menuitem"
                  onClick={() => {
                    setFieldValue(phoneCodeName, code);
                    setVisiblityClass("hidden");
                  }}
                >
                  <div className="inline-flex items-center">{code}</div>
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Field
        type="text"
        id={phoneName}
        name={phoneName}
        className={fieldClassFinal}
        required={required}
        disabled={disabled}
        validate={validate ? validate : undefined}
      />
    </div>
  );
};

export default PhoneField;

import axios from "axios";
import { Field, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import ButtonDropdown from "@ui/ButtonDropdown";
import Icon from "@ui/Icon";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { setModalData } from "@reducers/dataTransferSlice";
import { selectUser } from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";

import {
  FormikEmailFormProps,
  SignatoriesParties,
  toggleInfoProps,
} from "@types";

import { capitalize, formatLabel, noop } from "@utils";

const EntityCard = ({
  parties,
  setParties,
  dropdownOptions,
  buttonLabel,
  icon,
  infoMessage,
  className,
  hasButton,
  onClick,
  actInfo,
}: FormikEmailFormProps) => {
  const formik = useFormik({
    initialValues: parties,
    onSubmit: noop,
  }) as any;

  const { actType, publicId } = useParams();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const allPartiesHaveSigned = (parties as SignatoriesParties[])
    .filter((party: any) => party.type !== "lawyer")
    .map((party: any) => party.isSignBookSigned)
    .every(Boolean);
  const actStatus = actInfo.signingStatus;

  const handleAlertColor = (title: string) => {
    if (actType === "convention") {
      return "inline-flex";
    }

    return title || publicId
      ? "text-ea-gray-300 flex"
      : "bg-ea-yellow inline-flex";
  };

  const toggleInfo = ({
    toBeSealedMessage,
    toBeSealedBy,
    advisedByMessage,
    advisedBy,
    master,
  }: toggleInfoProps) => {
    // Mapping the name of the parties to be sealed and add a comma between them
    const toBeSealedByName = Array.isArray(toBeSealedBy)
      ? toBeSealedBy.map((item: any) => item.name).join(", ")
      : "";

    // Mapping the name of the parties advised by or advising the party and add a comma between them
    // const advisedByName = Array.isArray(advisedBy)
    //   ? advisedBy.map((item: any) => item.name).join(", ")
    //   : "";
    const advisedByName =
      advisedBy === undefined
        ? ""
        : Array.isArray(advisedBy)
          ? advisedBy.map((item: any) => item.name).join(", ")
          : "";

    let toBeSealedByInfoMessage = "";

    if ("" !== toBeSealedByName) {
      switch (toBeSealedMessage) {
        case labels.validateParty:
          toBeSealedByInfoMessage = toBeSealedMessage;
          break;
        default:
          toBeSealedByInfoMessage = `${toBeSealedMessage} ${toBeSealedByName}`;
      }
    }

    if (toBeSealedMessage === labels.draftingLawyer) {
      toBeSealedByInfoMessage = toBeSealedMessage;
    }

    // Show 'Avocat rédacteur' label when the lawyer is not linked to party but is the creator of the act
    if (toBeSealedMessage === undefined && master) {
      toBeSealedByInfoMessage = labels.draftingLawyer;
    }

    if (toBeSealedMessage === labels.identityToBeSealed) {
      toBeSealedByInfoMessage = toBeSealedMessage;
    }

    let advisedByInfoMessage = "";

    if ("" !== advisedByName) {
      switch (advisedByMessage) {
        case labels.advisingTheParty:
          advisedByInfoMessage = advisedByMessage;
          break;
        default:
          advisedByInfoMessage = `${advisedByMessage} ${advisedByName}`;
      }
    }

    // If there is no "sealed by" message, but there is an "advised" message
    // capitalize the first letter of the "advised" message
    if ("" === toBeSealedByInfoMessage && "" !== advisedByInfoMessage) {
      advisedByInfoMessage = capitalize(advisedByInfoMessage);
    }

    // E-convention message
    const conventionClientMessage = "";

    // Other acts messages (excluding empty ones) are stored in an array
    // so we can properly output a comma where/if needed
    const partyMessages = [
      toBeSealedByInfoMessage,
      advisedByInfoMessage,
    ].filter((message) => message !== "");

    const fullMessage =
      actType === "convention"
        ? conventionClientMessage
        : partyMessages.join(", ");

    const alertColorClass = handleAlertColor(master);

    return (
      <>
        {infoMessage && fullMessage && (
          <div
            className={`text-center justify-between items-center z-0 h-[26px] py-[5px] px-[6px] relative ${alertColorClass}`}
          >
            {!master && fullMessage && !publicId && (
              <Icon
                type="warning"
                className="icon z-10 w-[15px] h-[15px] mr-2"
              />
            )}
            {master ? (
              <div className="text-xs items-center text-center body-md line-clamp-1">
                <p className="text-left line-clamp-1 z-0">{fullMessage}</p>
              </div>
            ) : (
              <div className="text-xs items-center text-center body-md line-clamp-1">
                <p className="text-left line-clamp-1 z-0">{fullMessage}</p>
              </div>
            )}
            {fullMessage.length >= 95 && (
              <span className="relative group cursor-pointer mx-1">
                <Icon
                  type="expand-circle-right"
                  className="text-gray-500 size-[17px]"
                />
                <span className="tooltip group-hover:block top-full right-[-10px]">
                  {advisedByName}
                </span>
              </span>
            )}
          </div>
        )}
      </>
    );
  };

  const sealedButtonLabel = (status: string) => {
    return actType === "convention" || status === "true"
      ? labels.modificationParty
      : labels.relaunchLawyer;
  };

  const sealedStatusCheck = (status: string, party: any) => {
    switch (actType) {
      case "digital":
        return labels.modificationParty;
      case "convention":
        return labels.modifyClient;
    }

    if (status === "relaunched" && party.type === "TYPE_NATURAL") {
      return labels.relaunched;
    }

    // If the party is advised by someone else, the lawyer can only relaunch the lawyer
    // If the party is advised by someone else but linked to the lawyer, the lawyer can seal the party
    const sealingLawyer = party.linkedTo === user.numCNBF;

    if (
      !party.advisedBy?.[0]?.id ||
      (user.numCNBF !== party.advisedBy[0].id &&
        !sealingLawyer &&
        (actInfo.signingStatus === "BEFORE_SIGNING" ||
          ["created", "shared"].includes(actInfo.step) ||
          actInfo.type !== "numerise" ||
          !party.isLocked))
    ) {
      // When clicked and relaunched and the request is pending
      if (loading) {
        return labels.relaunchInProgress;
      }
      return labels.relaunchLawyer;
    }

    return status === "sealed" || party.isLocked || !sealingLawyer
      ? sealedButtonLabel("true")
      : labels.sealIdentity;
  };

  const handleButtonLabel = (status: string, party: any) => {
    if (status === "relaunched") {
      return labels.relaunched;
    }
    if ("signing" === actInfo?.step) {
      return labels.relaunch;
    }

    if (actStatus === "SIGNING") {
      if (party.type === "TYPE_NATURAL" && !party.isSignBookSigned) {
        return labels.relaunch;
      }
    }
    return !buttonLabel ? sealedStatusCheck(status, party) : buttonLabel;
  };

  const handleDropdownOptions = (status: string, party: any) => {
    if ("signing" === actInfo?.step) {
      return [
        {
          label: labels.modify,
          onClick: (index: number) => {
            onClick && onClick(index);
          },
        },
      ];
    }

    if (
      actStatus === "SIGNING" &&
      party.type === "TYPE_NATURAL" &&
      !party.isSignBookSigned
    ) {
      return [];
    }
    if (status !== "sealed" && !party.isLocked) {
      return dropdownOptions;
    }

    // Get the first item from the dropdown options and update its label.
    const deleteOption = [...(dropdownOptions as Record<string, any>[])].pop();

    // Sealed parties only have the option to modify and delete,
    // so we remove the ability to change the lawyer.
    const sealedDropdownOptions = [deleteOption];

    if ("convention" === actType || "signing" === actInfo?.step) {
      sealedDropdownOptions.shift();
    }

    return status === "sealed" || party.isLocked
      ? sealedDropdownOptions
      : dropdownOptions;
  };

  const handleHiddenBoxWidth = infoMessage ? "w-[174px]" : "w-[145px]";

  const margin = !className ? "pr-[9px] mb-6 mt-6" : "mb-2 mt-2";

  const handleHasButton = (status: string, index: number) => {
    const party = parties[index];

    if (party.signedActs && party.signedActs.length > 0) {
      const findSignedActId = party.signedActs.find(
        (act: any) => act.id === publicId,
      );

      if (findSignedActId) {
        return (
          <div className="body-md flex item-center">
            <Icon type="circle-check" className="size-[20px] mr-[8px]" />
            <span>{formatLabel(labels.signedOn, "11/03/2026")}</span>
          </div>
        );
      }
    }

    const sealParty = () => {
      axios
        .post(`/api/v1/signbooks/${publicId}/signatories/${party.id}/sealing`)
        .then((response) => {
          if (response.status === 204) {
            if (setParties) {
              setParties((prevParties: any) => {
                return [...prevParties].map((item, itemIndex) =>
                  index === itemIndex
                    ? { ...item, status: "sealed", isLocked: true }
                    : item,
                );
              });
            }
          }
        })
        .catch((error) => {
          toast.error(axiosErrorMessages[error.message], toastOptionsError);
        });
    };

    const relaunchLawyer = () => {
      setLoading(true);
      axios
        .post(
          `/api/v1/signbooks/${publicId}/signatories/${party.id}/seal-request`,
        )
        .then((response) => {
          if (response.status === 204) {
            if (setParties) {
              setParties((prevParties: any) => {
                return [...prevParties].map((item, itemIndex) =>
                  index === itemIndex
                    ? { ...item, status: "relaunched", isLocked: true }
                    : item,
                );
              });
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(axiosErrorMessages[error.message], toastOptionsError);
        });
    };

    // Send reminder email to party
    const handleReminderParty = (index: number) => {
      const party = parties[index];
      setLoading(true);
      axios
        .post(`/api/v1/signbooks/${publicId}/signatories/${party.id}/reminder`)
        .then((response) => {
          if (response.status === 204) {
            if (setParties) {
              setParties((prevParties: any) => {
                return [...prevParties].map((item, itemIndex) =>
                  index === itemIndex
                    ? { ...item, status: "relaunched" }
                    : item,
                );
              });
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(axiosErrorMessages[error.message], toastOptionsError);
        });
    };

    // Send reminder email to lawyer
    const handleReminderLawyer = (signatoryCode: string) => {
      setLoading(true);
      axios
        .post(`/api/v1/signbooks/${publicId}/lawyers/${signatoryCode}/reminder`)
        .then((response) => {
          if (response.status === 204) {
            if (setParties) {
              setParties((prevParties: any) => {
                return [...prevParties].map((item, itemIndex) =>
                  index === itemIndex
                    ? { ...item, status: "relaunched" }
                    : item,
                );
              });
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(axiosErrorMessages[error.message], toastOptionsError);
        });
    };

    let iconType = status === "relaunched" ? "check" : icon;

    if ("signing" === actInfo?.step && status !== "relaunched") {
      iconType = "paper-plane";
    }

    const iconColor = status === "relaunched" ? "#00C45A" : "#000";

    const buttonToDisplay =
      party.type === "lawyer" ? (
        // Show the single relance button for the lawyer that is different from the main one
        actType !== "digital" &&
        actStatus === "SIGNING" &&
        allPartiesHaveSigned ? (
          <Button
            className="btn-secondary flex items-center px-3 py-1 rounded-lg border h-[34px] body-lg"
            key={index}
            disabled={loading}
            onClick={(event) => {
              event.stopPropagation();
              handleReminderLawyer(party.codeCNBF ?? "");
            }}
          >
            <Icon
              type={iconType || "paper-plane"}
              className="mr-2 size-4"
              color={iconColor}
            />
            {status === "relaunched" ? labels.relaunched : labels.relaunch}
          </Button>
        ) : (
          <Button
            className="btn-secondary flex items-center px-3 py-1 rounded-lg border h-[34px] body-lg"
            key={index}
            onClick={(event) => {
              event.stopPropagation();
              onClick && onClick(index);
            }}
          >
            {labels.modificationLawyer}
          </Button>
        )
      ) : (
        <div className="inline-flex rounded-md shadow-sm">
          <ButtonDropdown
            options={handleDropdownOptions(status, party) as any}
            buttonLabel={handleButtonLabel(status, party)}
            iconType={iconType}
            iconColor={iconColor}
            payload={index}
            disabled={loading}
            onClick={() => {
              if ("signing" === actInfo?.step) {
                handleReminderParty(index);
                return;
              }

              // Logic for the relaunch button
              if (actStatus === "SIGNING" && actType !== "digital") {
                if (
                  party.type === "TYPE_NATURAL" &&
                  party.isSignBookSigned === false
                ) {
                  handleReminderParty(index);
                }

                return labels.relaunch;
              }

              // Sealed party
              if (status === "relaunched" && party.type === "TYPE_NATURAL") {
                return;
              }

              const buttonLabel = handleButtonLabel(status, party);

              if (
                buttonLabel === labels.modify ||
                buttonLabel === labels.modificationParty ||
                buttonLabel === labels.modifyClient
              ) {
                const preparedData = {
                  party: parties[index],
                  index: index,
                };

                dispatch(setModalData(preparedData));
                dispatch(showModal("editParty"));

                return;
              }

              // Unsealed party
              if (
                status !== "sealed" &&
                setParties &&
                handleButtonLabel(status, party) !== labels.relaunchLawyer
              ) {
                setParties((prevParties: any) => {
                  return [...prevParties].map((item, itemIndex) =>
                    index === itemIndex ? { ...item, status: "sealed" } : item,
                  );
                });
              }

              if (buttonLabel === labels.sealIdentity) {
                sealParty();
              }

              if (
                buttonLabel === labels.relaunchLawyer &&
                party.type === "TYPE_NATURAL"
              ) {
                relaunchLawyer();
              }
            }}
          />
        </div>
      );

    return hasButton ? (
      buttonToDisplay
    ) : (
      <div className={`inline-flex ${handleHiddenBoxWidth}`}></div>
    );
  };

  return (
    <FormikProvider value={formik}>
      {(parties as Array<any>).map((party, index) => {
        const lastRowClass = index === parties.length - 1 ? "last-row" : "";

        const clickableCard =
          !party.editor && onClick && !party.isSignBookSigned;
        const cursorClass = clickableCard ? "cursor-pointer" : "";

        return (
          <div
            className={`relative ${cursorClass} ${lastRowClass}`}
            key={index}
            onClick={() => {
              if (clickableCard) {
                onClick(index);
              }
            }}
          >
            <Field
              type="hidden"
              name={`party-${party.type}-name-[${index}]`}
              value={party.name}
            />
            <Field
              type="hidden"
              name={`party-${party.type}-email-[${index}]`}
              value={party.email || ""}
            />
            <div className={className}>
              <div className={`flex justify-between items-center ${margin}`}>
                <div className="flex justify-between items-center w-full">
                  <div className="flex items-center mr-3 lg:w-[200px]">
                    <div>
                      {party.image ? (
                        <img
                          className="w-[38px] h-[38px] rounded-[80.50px] mr-[12px]"
                          srcSet={party.image}
                          alt="avatar"
                        />
                      ) : (
                        <Icon
                          type={
                            ["TYPE_NATURAL", "lawyer"].includes(party.type)
                              ? "avatar"
                              : "apartment"
                          }
                          className="w-[38px] h-[38px] aspect-auto rounded-[80.50px] mr-[12px]"
                        />
                      )}
                    </div>
                    <div className="flex truncate">
                      {party.master && (
                        <p className="body-md mr-1">{party.master}</p>
                      )}
                      {party.type === "TYPE_LEGAL" ? (
                        <p className="body-md">{party.companyName}</p>
                      ) : (
                        <>
                          <p className="body-md mr-1">{party.firstName}</p>
                          {("TYPE_NATURAL" === party.type ||
                            "lawyer" === party.type) && (
                            <p className="body-md">{party.lastName}</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="body-md hidden lg:block lg:w-[200px] items-center text-center">
                    {party.email}
                  </div>
                  <div className="lg:w-[200px] flex justify-end">
                    {!party.editor ? (
                      !party.isSignBookSigned &&
                      handleHasButton(party.status, index)
                    ) : (
                      <div
                        className={`inline-flex ${handleHiddenBoxWidth}`}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
              {infoMessage && party.type !== "lawyer"
                ? toggleInfo({
                    toBeSealedMessage: party.toBeSealedMessage,
                    toBeSealedBy: party.linkedTo,
                    advisedByMessage: party.advisedByMessage,
                    advisedBy: party.advisedBy,
                    master: party.master,
                    lastEConventionDate: party.lastEConventionDate,
                  })
                : party.type === "lawyer" && party.master
                  ? toggleInfo({
                      toBeSealedMessage: party.editorNote,
                      advisedByMessage: party.advisingThePartyMessage,
                      advisedBy: party.advisingPartyName,
                      master: party.master,
                      lastEConventionDate: party.lastEConventionDate,
                    })
                  : toggleInfo({
                      toBeSealedMessage: party.toBeSealedMessage,
                      toBeSealedBy: party.linkedTo,
                      advisedByMessage: party.advisingThePartyMessage,
                      advisedBy: party.advisingPartyName,
                      master: party.master,
                      lastEConventionDate: party.lastEConventionDate,
                    })}
            </div>
          </div>
        );
      })}
    </FormikProvider>
  );
};

export default EntityCard;

import Button from "./Button";
import Icon from "./Icon";

import { getMonth, getYear } from "date-fns";
import React, { useEffect, useState } from "react";

import { months, years } from "@constants";

interface CustomDatePickerHeaderProps {
  date: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

const CustomDatePickerHeader: React.FC<CustomDatePickerHeaderProps> = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState("");

  useEffect(() => {
    const hideDropdown = () => {
      if (dropdownVisible !== "") {
        setDropdownVisible("");
      }
    };

    window.addEventListener("click", hideDropdown);

    return () => {
      window.removeEventListener("click", hideDropdown);
    };
  });

  return (
    <>
      <p className="headline-sm">{months[getMonth(date)]}</p>
      <div className="m-1 flex items-center justify-center">
        <button
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          className="border border-solid border-ea-gray-100 rounded p-1"
        >
          {"<"}
        </button>
        <div>
          <select
            className="hidden"
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(Number(value))}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <div className="w-[60px] self-stretch relative">
            <div
              className="m-1 p-1 text-left bg-white rounded border border-solid border-ea-gray-100"
              onClick={(event) => {
                event.stopPropagation();
                setDropdownVisible("year");
              }}
              data-testid="phone-code-select-dropdown-toggle"
            >
              {getYear(date)}
            </div>
            <Icon
              type="dropdown-arrow"
              className="size-[12px] absolute top-1/2 right-[8px] -translate-y-2/4 pointer-events-none"
              color="#000"
            />

            <div
              className={`datepicker-select-dropdown !w-[60px] ${dropdownVisible === "year" ? "" : "hidden"}`}
              data-testid="phone-code-select-dropdown"
            >
              <ul className="py-2 text-sm">
                {years.map((year: number, index: number) => (
                  <li key={index}>
                    <Button
                      type="button"
                      className="inline-flex w-full px-4 py-2 text-sm body-sm"
                      role="menuitem"
                      onClick={() => changeYear(year)}
                    >
                      <div className="inline-flex items-center">{year}</div>
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div>
          <select
            className="hidden"
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <div className="w-[100px] self-stretch relative">
            <div
              className="m-1 p-1 text-left bg-white rounded border border-solid border-ea-gray-100"
              onClick={(event) => {
                event.stopPropagation();
                setDropdownVisible("month");
              }}
              data-testid="phone-code-select-dropdown-toggle"
            >
              {months[getMonth(date)]}
            </div>
            <Icon
              type="dropdown-arrow"
              className="size-[12px] absolute top-1/2 right-[8px] -translate-y-2/4 pointer-events-none"
              color="#000"
            />

            <div
              className={`datepicker-select-dropdown !w-[100px] ${dropdownVisible === "month" ? "block" : "hidden"}`}
              data-testid="phone-code-select-dropdown"
            >
              <ul className="py-2 text-sm">
                {months.map((month: string, index: number) => (
                  <li key={index}>
                    <Button
                      type="button"
                      className="inline-flex w-full px-4 py-2 text-sm body-sm"
                      role="menuitem"
                      onClick={() => changeMonth(index)}
                    >
                      <div className="inline-flex items-center">{month}</div>
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <button
          className="m-1 flex items-center justify-center"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          {">"}
        </button>
      </div>
    </>
  );
};

export default CustomDatePickerHeader;

import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import {
  axiosErrorMessages,
  labels,
  toastOptions,
  toastOptionsError,
} from "@constants";

import { selectModalData, setPageData } from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

const Confirm = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);
  const data = useSelector(selectModalData);

  const title = modalData?.title || labels.confirm;
  const description = modalData?.description || "";
  const ctaButtonLabel = modalData?.ctaButtonLabel || labels.confirm;

  const cancelAct = () => {
    setLoading(true);
    axios
      .post(`/api/v1/signbooks/${data.publicId}/cancellation`)
      .then(() => {
        const actToUpdate = {
          ...data.actToUpdate,
          status: "cancelled",
          publicId: data.publicId,
        };

        dispatch(
          setPageData({
            confirm: true,
            actCancelled: true,
            actToUpdate,
            index: data.index,
          }),
        );

        dispatch(hideModal());

        if (data.skipSuccessToast) {
          return;
        }

        toast.success(labels.actCancelledSuccessfully, toastOptions);
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = axiosErrorMessages[error.message] || error.message;

        toast.error(errorMessage, toastOptionsError);
      });
  };

  return (
    <Modal title={title} description={description} width={400}>
      <p className="mt-5 body-sm">{labels.cancelationOfExistingActPrompt}</p>
      <p className="mb-[42px] body-sm">{labels.continuePrompt}</p>
      <div className="flex items-center justify-between mt-[40px]">
        <Button
          className="btn-secondary rounded-[8px] px-[13px] py-[6px] body-md"
          onClick={() => {
            dispatch(
              setPageData({
                confirm: false,
              }),
            );
            dispatch(hideModal());
          }}
        >
          {labels.cancel}
        </Button>
        <Button
          className="btn-primary rounded-[8px] px-[13px] py-[6px] body-md flex items-center justify-center"
          onClick={cancelAct}
          data-testid="confirm-button"
          disabled={loading}
        >
          {loading && <Spinner className="size-4 mr-4" />}
          {ctaButtonLabel}
        </Button>
      </div>
    </Modal>
  );
};

export default Confirm;

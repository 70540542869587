import axios from "axios";
import { Field, Form, Formik } from "formik";
import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Layout from "@components/layout/Layout";
import DocumentForSigningTable from "@components/tables/DocumentsForSigningTable";
import DocumentsForValidatingTable from "@components/tables/DocumentsForValidatingTable";
import PartsToBeSealedTable from "@components/tables/PartsToBeSealedTable";
import PaymentToBeDoneTable from "@components/tables/PaymentToBeDoneTable";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Notifications from "@ui/Notifications";
import Pagination from "@ui/Pagination";
import PhoneMessage from "@ui/PhoneMessage";
import ProgressBar from "@ui/ProgressBar";
import Spinner from "@ui/Spinner";
import TabButtons from "@ui/TabButtons";
import TabContent from "@ui/TabContent";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { selectPostState, setPostState } from "@reducers/dataTransferSlice";
import { selectRequiredActionsCount } from "@reducers/metadataSlice";

import { DocumentForSigningTableProps } from "@types";

import { addQueryParams } from "@utils";

export const RequiredActionsContext = createContext<Record<string, any>>({});

const RequiredAction = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const actToSign = queryParams.get("signBookNumber") || "";
  const actTitle = queryParams.get("signBookName") || "";
  const actorCode = queryParams.get("actorCode") || "";

  const [options, setOptions] = useState<Record<
    string,
    string | number | null
  > | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [tableData, setTableData] = useState({
    signing: [],
    sealing: [],
    validating: [],
    payments: [],
  });

  const [loadedStatuses, setLoadedStatuses] = useState<Record<string, number>>(
    {},
  );
  const [loadedStatusesError, setLoadedStatusesError] = useState<string[]>([]);
  const sealedState = useSelector(selectPostState);
  const requiredActionsCount = useSelector(selectRequiredActionsCount);
  const dispatch = useDispatch();

  const getStatus = (publicId: string) => {
    axios
      .get(`/api/v1/signbooks/${publicId}/status`)
      .then((response) => {
        setLoading(false);

        if (!response.data.progress) {
          return;
        }

        setLoadedStatuses((prev) => {
          return {
            ...prev,
            [publicId]: response.data.progress,
          };
        });
      })
      .catch((error) => {
        setLoadedStatusesError((prev) => [...prev, publicId]);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
        setLoading(false);
      });
  };

  const ActStatus = ({
    actData,
  }: {
    actData: DocumentForSigningTableProps;
  }) => {
    const publicId = actData.publicId;

    if (!publicId) {
      return null;
    }

    const progress = loadedStatuses[publicId] || null;
    const error = loadedStatusesError.includes(publicId);

    const getStatusText = () => {
      switch (actData.signingStatus) {
        case "BEFORE_SIGNING":
          if (actData.status === "created") {
            return labels.created;
          }
          if (actData.status === "shared") {
            return labels.sharing;
          }
          if (actData.status === "cancelled") {
            return labels.cancelled;
          }
          break;
        case "AFTER_SIGNING":
          if (actData.status === "waitingForPayment") {
            return labels.waitingForPayment;
          }
          if (actData.status === "closed") {
            return labels.closedAndArchived;
          }
          if (actData.status === "cancelled") {
            return labels.cancelled;
          }
          break;
        default:
          return labels.signing;
      }
    };

    if (error) {
      return (
        <p className="text-ea-red 2xl:ml-2 2xl:mt-0 mt-2">{labels.noInfo}</p>
      );
    }

    if (!progress) {
      return <Spinner className="size-4" dark={true} />;
    }

    const showProgress =
      actData.status &&
      !["closed", "cancelled", "expired"].includes(actData.status);

    return (
      <div className="flex flex-col 2xl:flex-row 2xl:items-center items-start">
        {showProgress && (
          <div className="min-w-[107px]">
            <ProgressBar currentStep={progress} maxSteps={100}></ProgressBar>
          </div>
        )}
        <p className="2xl:ml-2 2xl:mt-0 mt-2">{getStatusText()}</p>
      </div>
    );
  };

  const tabUrl = location.pathname.split("/").pop();
  const tabsList = [
    {
      title: labels.forSigningTabLabel,
      id: 0,
      url: "sign-documents",
      hasExpiringSoon: Number(requiredActionsCount.countToSign) > 0,
      testId: "sign-documents-tab",
    },
    {
      title: labels.forSealingTabLabel,
      url: "seal-documents",
      id: 1,
      testId: "seal-documents-tab",
      hasExpiringSoon: Number(requiredActionsCount.countToSealMember) > 0,
    },
    {
      title: labels.forValidatingTabLabel,
      id: 2,
      url: "validate-documents",
      testId: "validate-documents-tab",
      hasExpiringSoon: Number(requiredActionsCount.countToValidate) > 0,
    },
    {
      title: labels.forPaymentsToBeMadeTabLabel,
      id: 3,
      url: "payments",
      hasExpiringSoon: Number(requiredActionsCount.countForPayment) > 0,
      testId: "payments-tab",
    },
  ];
  const activeTabId = tabsList.find((tab) => tab.url === tabUrl)?.id || 0;
  const [activeTab, setActiveTab] = useState<number>(activeTabId);

  const fetchActions = (options: any) => {
    setLoading(true);

    const { endpoint, page } = options;

    axios
      .post(
        addQueryParams(
          `/api/v1/signbooks/${endpoint}?page=${page - 1}&size=10`,
          {
            searchPublicId: actToSign || (options.number as string) || "",
            title: actTitle || (options.title as string) || "",
            actorCode: actorCode || "",
          },
        ),
      )
      .then((response) => {
        const searchResults = response.data.results;
        const pages = response.data.pages;

        setTotalPages(pages);

        if (!searchResults) {
          setLoading(false);
          return;
        }

        const endpointMap: Record<string, string> = {
          "search-to-sign": "signing",
          "search-to-sealmember": "sealing",
          "search-to-validatedocument": "validating",
          "search-for-payment": "payments",
        };

        const endpointKey = endpointMap[endpoint];

        setTableData({
          ...tableData,
          [endpointKey]: searchResults,
        });

        setLoading(false);

        if ("search-to-sign" !== endpoint) {
          return;
        }

        searchResults.forEach((result: Record<string, string>) => {
          const { publicId } = result;

          if (["closed", "cancelled", "expired"].includes(result.status)) {
            setLoadedStatuses((prev) => {
              return {
                ...prev,
                [publicId]: 100,
              };
            });
            return;
          }

          getStatus(publicId);
        });
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!options || !options.endpoint || "number" !== typeof options.page) {
      return;
    }

    fetchActions(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  useEffect(() => {
    if (sealedState) {
      fetchActions(options);
      dispatch(setPostState(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sealedState]);

  useEffect(() => {
    const endPoints = [
      "search-to-sign",
      "search-to-sealmember",
      "search-to-validatedocument",
      "search-for-payment",
    ];

    setOptions({
      ...options,
      page: 1,
      endpoint: endPoints[activeTab],
      title: "",
      number: "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (!options) {
      return;
    }

    setOptions({
      ...options,
      page,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const exportData = () => {
    const endpointMap: Record<number, string> = {
      0: "search-to-sign",
      1: "search-to-sealmember",
      2: "search-to-validatedocument",
      3: "search-for-payment",
    };

    const endpoint = endpointMap[activeTab];
    if (endpoint === "") {
      return;
    }
    setLoadingExport(true);
    axios
      .post(
        addQueryParams(`/api/v1/signbooks/${endpoint}/export`, {
          searchPublicId: actToSign || (options?.number as string) || "",
          title: actTitle || (options?.title as string) || "",
          actorCode: actorCode || "",
        }),
      )
      .then((response) => {
        const encodingData = "\uFEFF";
        const url = window.URL.createObjectURL(
          new Blob([encodingData + response.data], {
            type: "text/csv;charset=utf-8;",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${tabsList[activeTab].title}.csv`);
        document.body.appendChild(link);
        link.click();
        setLoadingExport(false);
      })
      .catch((error) => {
        setLoadingExport(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const tables = [
    <DocumentForSigningTable />,
    <PartsToBeSealedTable />,
    <DocumentsForValidatingTable />,
    <PaymentToBeDoneTable />,
  ];

  return (
    <Layout sidebar={true} backgroundColor="bg-stone-50">
      <PhoneMessage />
      <div className="flex flex-col h-[calc(100%-77px)] pb-20 px-[54px] pt-[38px] bg-stone-50 relative">
        <Notifications className="absolute top-[7px] left-1/2 -translate-x-2/4 w-[90%] md:w-[653px] xl:w-[800px] z-10 mb-[20px]" />
        <h1 className="mt-24 xl:mt-1 text-xl font-bold text-black max-md:max-w-full">
          {labels.actionRequired}
        </h1>

        <div className="flex flex-col max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
            {/* Tabs */}
            <TabButtons
              tab={activeTab}
              setTab={setActiveTab}
              tabsList={tabsList}
            />

            {/* Body of tabs */}
            <RequiredActionsContext.Provider
              value={{
                loading,
                setPage,
                tableData,
                ActStatus,
              }}
            >
              <div className="flex flex-col pb-10 bg-white rounded-lg max-md:max-w-full">
                <div className="flex flex-col pt-[30px] font-medium max-md:px-5 max-md:max-w-full z-10">
                  <div className="flex justify-end px-6">
                    <Button
                      type="button"
                      className="btn-base btn-secondary flex items-center "
                      disabled={loadingExport || loading}
                      onClick={() => {
                        exportData();
                      }}
                    >
                      {loadingExport && <Spinner className="size-4 mr-4" />}
                      {labels.export}
                    </Button>
                  </div>
                  <div className="flex gap-5 justify-between px-6 w-full mb-7 md:mb-[14px] max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        title: options?.title || actTitle || "",
                        number: options?.number || actToSign || "",
                      }}
                      onSubmit={(values) => {
                        setOptions({
                          ...options,
                          title: values.title,
                          number: values.number,
                        });
                      }}
                    >
                      <Form>
                        <div className="flex flex-col md:flex-row items-center gap-2.5">
                          <div className="w-full md:w-auto flex flex-col">
                            <label
                              htmlFor="title"
                              className="body-sm mb-[10px]"
                            >
                              {labels.title}
                            </label>
                            <div className="relative">
                              <Icon
                                type="search"
                                color="black"
                                className="absolute w-[16px] h-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                              />
                              <Field
                                name="title"
                                type="text"
                                className="flex pl-[47px] pr-[10px] py-2 max-w-[260px] lg:max-w-[317px] text-xs bg-white rounded border border-solid border-neutral-300 text-neutral-400"
                              />
                            </div>
                          </div>
                          <div className="w-full md:w-auto flex flex-col">
                            <label
                              htmlFor="title"
                              className="body-sm mb-[10px]"
                            >
                              {labels.number}
                            </label>
                            <div className="relative">
                              <Icon
                                type="search"
                                color="black"
                                className="absolute w-[16px] h-[16px] top-[50%] translate-y-[-50%] left-[14px]"
                              />
                              <Field
                                name="number"
                                type="text"
                                className="flex pl-[47px] pr-[10px] py-2 max-w-[260px] lg:max-w-[317px] text-xs bg-white rounded border border-solid border-neutral-300 text-neutral-400"
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <Button
                              onClick={() => {
                                setOptions({
                                  ...options,
                                  title: "",
                                  number: "",
                                });
                              }}
                              type="button"
                              disabled={loading}
                            >
                              <Icon
                                type="refresh"
                                color="#7F7F7F"
                                className="w-[16px] h-[16px] mr-[16px] mt-7"
                              />
                            </Button>
                            <Button
                              type="submit"
                              className="btn-base btn-secondary-emphasize mt-6 flex items-center justify-center"
                              disabled={loading}
                            >
                              {loading && <Spinner className="size-4 mr-4" />}
                              {labels.search}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>

                  {!loading && (
                    <Pagination
                      page={page}
                      totalPages={totalPages}
                      setPage={setPage}
                    />
                  )}
                  <TabContent activeTab={activeTab} data={tables} />
                </div>
              </div>
            </RequiredActionsContext.Provider>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RequiredAction;

import axios from "axios";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Icon from "@ui/Icon";

import {
  axiosErrorMessages,
  labels,
  pdfs,
  toastOptions,
  toastOptionsError,
} from "@constants";

import { selectUser } from "@reducers/metadataSlice";
import { hideModal } from "@reducers/modalsSlice";

import { Document, FileUploadProps } from "@types";

import {
  addQueryParams,
  bytesToMegabytes,
  downloadPDF,
  formatLabel,
  generateUUID,
} from "@utils";

const FileUpload = ({
  files,
  setFiles,
  generateIDs = false,
  compactMode = false,
  minimized = false,
  isLocked = false,
  acceptedFileTypes = {
    "application/pdf": [],
  },
  disabled = false,
  onChange,
  url,
  maxFileSize,
  setLoading,
  accessToken,
}: FileUploadProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const backLocation = location.pathname;
  const user = useSelector(selectUser);

  const handleDrop = (acceptedFiles: File[]) => {
    const newFiles = [...acceptedFiles] as Document[];

    // Check for duplicates by filename.
    const hasDuplicateFiles = newFiles.find(
      (file: Document | Record<string, string>) =>
        files.some((existingFile) => existingFile.name === file.name),
    );

    if (hasDuplicateFiles) {
      toast.error(labels.fileDuplicationForbidden, toastOptionsError);
      return;
    }

    // Add id prop to each file if needed.
    if (generateIDs) {
      newFiles.forEach((file) => {
        file.id = generateUUID();
      });
    }

    if (maxFileSize) {
      // Calculate the total filesize of every file in bytes
      // taking existing uploaded files into account.
      const totalFileSize = [...(files as Document[]), ...newFiles].reduce(
        (acc, file) => acc + file.size,
        0,
      );

      // Check if the total filesize exceeds the limit.
      if (totalFileSize > maxFileSize) {
        toast.error(
          formatLabel(
            labels.filesizeLimitReached,
            bytesToMegabytes(maxFileSize).toString(),
          ),
          toastOptionsError,
        );
        return;
      }
    }

    const formData = new FormData();

    newFiles.forEach((file) => {
      formData.append("files", file);
    });

    if (setLoading) {
      setLoading(true);
    }
    if (url) {
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            // If it has token in the props use it, otherwise use the one from the store.
            Authorization: accessToken
              ? `Bearer ${accessToken}`
              : `Bearer ${user?.keycloackToken}`,
          },
        })
        .then((response) => {
          const uploadedFiles = response.data.map((file: Document) => ({
            id: file.documentUid,
            name: file.documentName,
            size: file.fileSize,
            fixed: false,
          }));

          toast.success(labels.documentSuccessfullyAdded, toastOptions);

          setFiles(uploadedFiles);
          if (setLoading) {
            setLoading(false);
          }
        })
        .catch((error) => {
          if (setLoading) {
            setLoading(false);
          }

          const errorStatus = error.response?.status;
          const errorData = error.response?.data;

          let errorMessage = error.message;

          if (504 === errorStatus) {
            errorMessage = labels.requestTookTooLong;
          } else if (400 === errorStatus) {
            if (
              errorData &&
              errorData.startsWith("File duplication forbidden")
            ) {
              errorMessage = labels.fileDuplicationForbidden;
            }
          } else {
            errorMessage = axiosErrorMessages[errorMessage];
          }

          toast.error(errorMessage, toastOptionsError);
        });
    } else {
      setFiles(newFiles);
    }

    if (onChange) {
      onChange(newFiles);
    }
  };

  const DropzoneMarkup = () => {
    if (compactMode) {
      return (
        <div className="flex items-center">
          {files.length === 0 ? (
            <>
              <Icon type="cloud" className="size-[20px] mr-[6px]" />
              <p className="body-sm">
                {`${labels.dragAndDropOr} `}
                <span className="underline underline-offset-2 font-bold">
                  {labels.import}
                </span>
                {` ${labels.oneDocument}`}
              </p>
            </>
          ) : (
            <>
              <span className="body-sm truncate max-w-[200px]">
                {files[0].name}
              </span>
              <div
                className="absolute top-0 right-0 px-2 h-full flex justify-center items-center cursor-default"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Link
                  className="mr-6"
                  to={addQueryParams(`/acts/birth/new/documents/preview`, {
                    documentId: files[0].id,
                    returnPath: backLocation,
                    lawyer: user?.id ? "true" : "",
                  })}
                  onClick={() => {
                    dispatch(hideModal());
                  }}
                >
                  <Icon type="preview" className="size-[18px]" />
                </Link>
                <Button
                  type="button"
                  onClick={() => {
                    downloadPDF(pdfs);
                  }}
                >
                  <Icon type="download" className="size-[18px]" />
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center w-full">
        <Icon type="cloud" className="size-[54px]" />
        <p className="body-sm">
          {`${labels.dragAndDropDocuments} `}
          <span className="underline underline-offset-2 font-bold">
            {labels.importDocuments}
          </span>
        </p>
      </div>
    );
  };
  const disabledColor = disabled ? "!border-ea-gray-200" : "";
  const aspectClass = minimized ? "py-2" : "aspect-[1.69]";
  const wrapperClass = compactMode
    ? "py-1 px-2 border rounded-md h-[34px]"
    : `${aspectClass} border-2 rounded-lg ${disabledColor}`;
  const justifyClass = files.length === 0 ? "justify-center" : "justify-start";
  const centerClass = minimized ? "justify-center" : "";

  return (
    <Dropzone
      onDrop={(acceptedFiles) => {
        handleDrop(acceptedFiles);
      }}
      accept={acceptedFileTypes}
      multiple={!compactMode}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          className={
            !isLocked
              ? `file-upload-wrapper ${wrapperClass} ${justifyClass} ${centerClass}`
              : `hidden`
          }
          {...getRootProps()}
          data-testid="dropzone"
        >
          <input {...getInputProps()} />
          <DropzoneMarkup />
        </div>
      )}
    </Dropzone>
  );
};

export default FileUpload;

import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Button from "@ui/Button";
import Modal from "@ui/Modal";

import { labels } from "@constants";

import { hideModal } from "@reducers/modalsSlice";

const DivorceConfirmation = () => {
  const navigate = useNavigate();
  const initialValues = {
    confirmation: false,
    readAll: false,
  };

  const validationSchema = Yup.object().shape({
    confirmation: Yup.boolean().oneOf([true]),
    readAll: Yup.boolean().oneOf([true]),
  });

  const dispatch = useDispatch();

  return (
    <Modal title={labels.confirmation} width={640}>
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {
          dispatch(hideModal());
          // For test purposes, the link is hardcoded to be modified later
          navigate(
            "/acts/divorce/new/documents/preview?documentId=multiple&mustReadAll=true&action=sign&inPersonSignature=true&returnPath=/acts/divorce&lawyer=true",
          );
        }}
      >
        {(formik) => (
          <Form>
            <div className="modal-content">
              Une fois l'acte validé, vous devrez vous acquitter du prix de
              l'acte sélectionné.
              <h2>1. Validation de l'acte</h2>
              <p>
                La validation de l'acte opère la création de l'acte final qui
                est le résultat de la fusion et de la compression des documents
                préalablement déposés sur la plateforme.
              </p>
              <p>
                Elle rend toute modification ultérieure de l'acte final
                impossible.
              </p>
              <p>
                <a href="/" onClick={(event) => event.preventDefault()}>
                  Télécharger l'acte final
                </a>
              </p>
              <div className="custom-checkbox-container custom-checkbox-container-multiline">
                <label>
                  <Field
                    name="confirmation"
                    type="checkbox"
                    className="custom-checkbox"
                  />
                  En cochant cette case, je valide que le document final est en
                  tout point conforme aux documents préalablement déposés sur la
                  plateforme.
                </label>
              </div>
              <h2>2. Récapitulatif</h2>
              <p>
                Cliquer{" "}
                <a href="/" onClick={(event) => event.preventDefault()}>
                  ici
                </a>{" "}
                pour ouvrir les conditions générales de services dans une
                nouvelle fenêtre
              </p>
              <div className="custom-checkbox-container custom-checkbox-container-multiline">
                <label>
                  <Field
                    name="readAll"
                    type="checkbox"
                    className="custom-checkbox"
                  />
                  En cochant cette case, je reconnais avoir pris connaissance
                  des conditions générales de services.
                </label>
              </div>
              <p>
                La convention de divorce sera archivée pour une durée de 5 ans à
                compter de la contre-signature par l'avocat rédacteur.
              </p>
            </div>
            <div className="flex justify-between items-center">
              <Button
                className="btn-base btn-secondary"
                type="button"
                onClick={() => {
                  dispatch(hideModal());
                }}
              >
                {labels.cancel}
              </Button>
              <Button
                className="btn-base btn-primary"
                type="submit"
                disabled={!formik.isValid}
              >
                {labels.continue}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DivorceConfirmation;

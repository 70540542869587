import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import ButtonDropdown from "@ui/ButtonDropdown";
import Icon from "@ui/Icon";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import Modals from "@modals/Modals";

import { selectModalData, setModalData } from "@reducers/dataTransferSlice";
import { showModal } from "@reducers/modalsSlice";

import { Document } from "@types";

import {
  addQueryParams,
  getActorDataItem,
  handleSessionExpiration,
  refreshToken,
  setTokenData,
} from "@utils";

const DocumentValidation = () => {
  // Will be used when BE is ready
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [files, setFiles] = useState<Document[]>([]);
  // Will be used when BE is ready
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(selectModalData);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const signBookNumber = queryParams.get("signbookNumber") || "";
  const actorCode = queryParams.get("actorCode") || "";
  const [accessToken, setAccessToken] = useState<string | null>(
    getActorDataItem(actorCode, "accessToken"),
  );
  const [tokenForRefresh, setTokenForRefresh] = useState<string | null>(
    getActorDataItem(actorCode, "refreshToken"),
  );
  const [accessTokenExpiresIn, setAccessTokenExpiresIn] = useState(
    getActorDataItem(actorCode, "expiresIn"),
  );
  const [refreshTokenExpiresIn, setRefreshTokenExpiresIn] = useState(
    getActorDataItem(actorCode, "refreshToken"),
  );

  useEffect(() => {
    if (!accessToken) {
      dispatch(showModal("restrictedAccess"));
      dispatch(setModalData({ initiatedFrom: "documentValidation" }));
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const currentTime = Date.now();

    // Get expiration timestamps from localStorage if they exist
    let accessTokenExpiryTime = Number(
      getActorDataItem(actorCode, "accessTokenExpiryTimestamp"),
    );
    let refreshTokenExpiryTime = Number(
      getActorDataItem(actorCode, "refreshTokenExpiryTimestamp"),
    );

    // If no stored expiry timestamps, calculate new ones based on expiresIn
    if (!accessTokenExpiryTime) {
      accessTokenExpiryTime = currentTime + Number(accessTokenExpiresIn) * 1000;
      setTokenData(actorCode, {
        accessTokenExpiryTimestamp: String(accessTokenExpiryTime),
      });
    }
    if (!refreshTokenExpiryTime) {
      refreshTokenExpiryTime =
        currentTime + Number(refreshTokenExpiresIn) * 1000;

      setTokenData(actorCode, {
        refreshTokenExpiryTimestamp: String(refreshTokenExpiryTime),
      });
    }

    const remainingAccessTokenTime = accessTokenExpiryTime - currentTime;
    const remainingRefreshTokenTime = refreshTokenExpiryTime - currentTime;

    // If the access token is expired, try to refresh it
    if (remainingAccessTokenTime <= 0) {
      if (remainingRefreshTokenTime > 0) {
        refreshToken(
          signBookNumber,
          actorCode,
          tokenForRefresh ?? "",
          accessTokenExpiresIn ?? "",
          refreshTokenExpiresIn ?? "",
          setAccessToken,
          setTokenForRefresh,
          `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/token/refresh`,
        );
      } else {
        // Both tokens are expired, re-authenticate
        handleSessionExpiration(actorCode);
        dispatch(showModal("restrictedAccess"));

        return;
      }
    } else {
      // Timeout to refresh the token when it is about to expire
      const tokenRefreshTimeout = setTimeout(() => {
        refreshToken(
          signBookNumber,
          actorCode,
          tokenForRefresh ?? "",
          accessTokenExpiresIn ?? "",
          refreshTokenExpiresIn ?? "",
          setAccessToken,
          setTokenForRefresh,
          `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/token/refresh`,
        );
      }, remainingAccessTokenTime);

      return () => clearTimeout(tokenRefreshTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, tokenForRefresh]);

  useEffect(() => {
    if (!data?.authenticated) {
      return;
    }

    setAccessToken(getActorDataItem(actorCode, "accessToken"));
    setTokenForRefresh(getActorDataItem(actorCode, "refreshToken"));
    setAccessTokenExpiresIn(getActorDataItem(actorCode, "expiresIn"));
    setRefreshTokenExpiresIn(getActorDataItem(actorCode, "refreshExpiresIn"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    getDocumentsForValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const getDocumentsForValidation = async () => {
    setLoading(true);
    axios
      .get(
        `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/signbook/documents/validation`,
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )
      .then((response) => {
        const documents = response.data;
        if (documents.length > 0) {
          const newDocuments = documents.map(
            (document: Document, index: number) => {
              const formattedDocument = {
                id: document.documentUid,
                name:
                  document.documentName?.split("/")[1] || document.documentName,
                size: document.fileSize,
                order:
                  document.type === "CGU" ? 0 : document.order || index + 1,
                validated:
                  document.type === "CGU"
                    ? document?.metadata?.conversionValidated
                    : document?.isValidated,
                fixed: document.type === "CGU",
              };
              return formattedDocument;
            },
          );
          newDocuments.sort((a: any, b: any) => a.order - b.order);
          setFiles(newDocuments);
        }

        setLoading(false);
        setInitializing(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  // Validate only the document which documentid is passed
  const validateFile = (documentId: string) => {
    setLoading(true);
    axios
      .post(
        `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/signbook/documents/${documentId}/acceptance`,
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )
      .then(() => {
        const newDocuments = files.map((file) => {
          if (file.id === documentId) {
            file.validated = true;
          }
          return file;
        });
        setFiles(newDocuments);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  return (
    <>
      <ToastContainer className="body-lg" />
      <Modals />
      <header className="relative z-[12] w-full h-[77px] flex justify-between border-b-[1px] px-[24px] lg:px-0 bg-white">
        <img
          srcSet="/assets/logo.svg"
          alt=""
          className="lg:block w-[102px] h-[60px] mt-[8px] xl:ml-[74px]"
        />
      </header>
      {initializing ? (
        <div className="flex justify-center py-10">
          <Spinner className="size-10" dark={true} />
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <div className="bg-white lg:w-[695px] mx-6 lg:mx-auto rounded-t-lg pb-2 mb-16 my-8 p-[40px] max-w-[579px]">
            <h1 className="headline-lg mb-5">{labels.forValidatingTabLabel}</h1>

            <div className="mb-12">
              <p className="body-sm">
                {labels.receivedDocumentForValidationLabel}
              </p>
              <p className="body-sm">{labels.readTheDocumentsAndValidate}</p>
            </div>

            <div>
              {files.map((file, index) => {
                const validated = file.validated;

                const buttonOptions = [
                  {
                    label: labels.view,
                    link: addQueryParams(
                      `/signatory-confirmation/document-preview`,
                      {
                        documentId: file.id,
                        actorCode: actorCode,
                        signBookNumber: signBookNumber,
                        returnPath: `/document-validation?signBookNumber=${signBookNumber}&actorCode=${actorCode}`,
                        documentName: file.name,
                      },
                    ),
                  },
                ];

                // If the document is validated, we don't need the "validate" option.
                if (validated) {
                  buttonOptions.shift();
                }

                return (
                  <div
                    key={index}
                    className="flex justify-between items-center mt-[24px] first:mt-0 mb-[24px]"
                  >
                    <span className="flex w-full items-center py-[5px]">
                      <img src="/assets/pdf-icon.png" alt="" />
                      <span className="ml-4 body-sm">{file.name}</span>
                    </span>

                    {validated && (
                      <Icon
                        type="check"
                        color="#00C45A"
                        className="size-[20px] mr-[20px]"
                      />
                    )}

                    <ButtonDropdown
                      options={buttonOptions}
                      disabled={loading}
                      buttonLabel={validated ? labels.view : labels.validate}
                      onClick={() => {
                        // If the document is not validated we validated it on click and return.
                        // Otherwise, we navigate to the preview page.
                        if (!validated) {
                          validateFile(file.id);
                          return;
                        }

                        navigate(
                          addQueryParams(
                            `/signatory-confirmation/document-preview`,
                            {
                              documentId: file.id,
                              actorCode: actorCode,
                              signBookNumber: signBookNumber,
                              returnPath: `/document-validation?signBookNumber=${signBookNumber}&actorCode=${actorCode}`,
                              documentName: file.name,
                            },
                          ),
                        );
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentValidation;

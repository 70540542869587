import axios from "axios";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import {
  axiosErrorMessages,
  labels,
  toastOptions,
  toastOptionsError,
} from "@constants";

import { selectModalData, setModalData } from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";
import {
  deleteImage,
  selectImage,
  selectUseByDefault,
  setImage,
  setSaveImageAction,
  setUseByDefault,
} from "@reducers/signatureSlice";
import { setSignedDocument } from "@reducers/validationSlice";

import { CertificateDataProps } from "@types";

import {
  base64ToFile,
  formatTimestamp,
  getActorDataItem,
  handleSessionExpiration,
} from "@utils";

const Certificate = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const processNumber = queryParams.get("processNumber") ?? "";
  const actorCode = queryParams.get("actorCode") ?? "";
  const accessToken = getActorDataItem(actorCode, "accessToken");
  const dispatch = useDispatch();
  const signature = useSelector(selectImage);
  const [clientSignature, setClientSignature] = useState(false);
  const useByDefault = useSelector(selectUseByDefault);
  const initialFormValues = {
    signatureUse: useByDefault,
  };
  const data = useSelector(selectModalData);
  const [documents, setDocuments] = useState([]);
  const [certificateData, setCertificateData] = useState<CertificateDataProps>({
    tokenReference: "",
    issuedFor: "",
    issuedBy: "",
    validityStartDate: [],
    validityEndDate: [],
    organization: "",
    organizationIdentifier: "",
    waitingTimeBeforeNextGeneration: 0,
  });
  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer | null
  >(signature);
  const signatureRef = useRef<SignatureCanvas>(null);
  const [showSignatureInput, setShowSignatureInput] = useState(false);
  // eslint-disable-next-line
  const [signedDocumentsCount, setSignedDocumentsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!data || !data.documentsData || !data.certificate) {
      return;
    }

    setDocuments(data.documentsData);
    setCertificateData(data.certificate);
  }, [data]);

  const documentForSining = () => {
    return documents.map((document: any) => {
      return (
        <p className="flex body-lg font-bold mt-1">{document.documentName}</p>
      );
    });
  };

  // Format a date array
  const formatDate = (dateArray: []) => {
    const date = new Date(...dateArray);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  // Calculate the validity of the certificate
  const calculateValidity = () => {
    const validityEndDate = certificateData.validityEndDate;
    let startDateFormatted = "";
    if (typeof validityEndDate === "string") {
      // If it's a timestamp
      startDateFormatted = formatTimestamp(validityEndDate);
    } else if (Array.isArray(validityEndDate)) {
      // If it's an array
      startDateFormatted = formatDate(validityEndDate);
    }
    const validityText = `Validité : 24h à partir du ${startDateFormatted}`;
    return validityText;
  };

  const signDocument = (documentId: string) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("tokenPin", "123456");
      if (signatureRef.current && !signatureRef.current.isEmpty()) {
        const dataUrl = signatureRef.current.toDataURL();
        const file = base64ToFile(dataUrl, "image");

        if (!file) {
          return reject();
        }
        formData.append("file", file);
      }

      axios
        .post(
          `/shared-api/v1/process/${processNumber}/actors/${actorCode}/main-documents/${documentId}/sign`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        )
        .then((response) => {
          setSignedDocumentsCount((prevCount) => {
            const newCount = prevCount + 1;

            // Check if all documents are signed
            if (newCount === documents.length) {
              dispatch(setModalData(null));
              toast.success(labels.successfullySignedAct, toastOptions);
              dispatch(hideModal());
              dispatch(setSignedDocument(true));
              handleSessionExpiration(actorCode);
            }

            return newCount;
          });
          resolve(response);
          setLoading(false);
        })
        .catch((error) => {
          toast.error(axiosErrorMessages[error.message], toastOptionsError);
          reject(error);
        });
    });
  };

  const handleSignDocuments = async () => {
    const documentIds = documents.map((document: any) => document.documentUid);
    for (const documentId of documentIds) {
      try {
        await signDocument(documentId);
      } catch (error: any) {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
        break;
      }
    }
  };

  const handleImageDelete = () => {
    dispatch(deleteImage());
    dispatch(setImage({ image: null }));
    dispatch(setSaveImageAction({ saveImageAction: true }));
    dispatch(setUseByDefault({ useByDefault: false }));
  };

  return (
    <Modal width={466} closable={false}>
      <Formik
        initialValues={initialFormValues}
        onSubmit={() => {
          handleSignDocuments();
        }}
      >
        {() => (
          <Form>
            {showSignatureInput ? (
              <>
                <h2 className="headline-lg pr-4 text-left">
                  {labels.signature}
                </h2>
                <p className="body-sm text-justify mt-[10px]">
                  {labels.signatureDescriptionSignatory}
                </p>

                <div className="relative border rounded-[8px] w-[209px] h-[174px] mt-[16px] mx-auto overflow-hidden">
                  {selectedImage && "string" === typeof selectedImage ? (
                    <img
                      src={selectedImage}
                      alt={labels.signature}
                      className="absolute top-0 left-0 w-full h-full object-contain"
                    />
                  ) : (
                    <SignatureCanvas
                      ref={signatureRef}
                      canvasProps={{
                        width: 209,
                        height: 174,
                      }}
                    />
                  )}
                  <Button
                    className="flex absolute left-[10px] bottom-[10px] bg-white size-[32px] rounded-full items-center justify-center shadow-md"
                    type="button"
                    onClick={() => {
                      setSelectedImage(null);
                      if (signatureRef.current) {
                        signatureRef.current.clear();
                      }
                      handleImageDelete();
                    }}
                  >
                    <Icon
                      type="trash"
                      className="size-[24px] text-ea-gray-200"
                    />
                  </Button>
                </div>
                <div className="flex justify-between items-center select-none mt-[24px]">
                  <Button
                    className="btn-secondary body-lg leading-[31px] h-[33px] px-[17px] rounded-[8px]"
                    type="button"
                    onClick={() => {
                      setShowSignatureInput(false);
                      setClientSignature(false);
                    }}
                  >
                    {labels.cancel}
                  </Button>

                  <Button
                    className="btn-primary body-md ml-[10px] h-[33px] px-[13px] rounded-[8px]"
                    type="button"
                    onClick={() => {
                      dispatch(
                        setImage({ image: signatureRef.current?.toDataURL() }),
                      );
                      setShowSignatureInput(false);
                    }}
                  >
                    {labels.validateSignature}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col">
                  <div className="flex flex-row items-center justify-center mb-[10px]">
                    <Icon
                      type="certificate"
                      color="black"
                      className="size-[24px]"
                    />
                    <h2 className="flex headline-lg ">
                      {labels.generatedCertificate}
                    </h2>
                  </div>
                  <div>
                    <p className="flex body-sm">
                      {labels.certificateDescription}
                    </p>
                    <div className="flex flex-col justify-center items-start">
                      <p className="flex mt-3 body-lg font-bold">
                        {labels.deliveredTo} : {certificateData.issuedFor}
                      </p>
                      <p className="flex mt-3 body-lg font-bold mx-6">
                        {labels.deliveredBy} : {certificateData.issuedBy}
                      </p>
                      <p className="flex mt-3 body-lg font-bold mx-6">
                        {labels.validity} : {calculateValidity() || ""}
                      </p>
                    </div>
                    <p className="flex body-sm mt-3">
                      {labels.warningSigningCertificate}
                    </p>
                    <div>{documentForSining()}</div>
                    <p className="flex body-sm mt-3">
                      {labels.beysTrustedSolutionDescription}
                    </p>
                  </div>
                  {clientSignature ? (
                    <>
                      <div className="flex justify-center mt-8">
                        <div className="relative w-[80px] h-[62px] lg:w-[101px] lg:h-[80px] bg-slate-200 rounded-md">
                          {signature && "string" === typeof signature && (
                            <img
                              src={signature}
                              alt={labels.signature}
                              className="absolute top-0 left-0 w-full h-full object-contain"
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex justify-center mt-8">
                        <Button
                          type="submit"
                          id="clientSignature"
                          disabled={loading}
                          className="flex btn-primary rounded-[8px] px-[13px] py-[6px] body-md items-center justify-center cursor-pointer"
                        >
                          {loading && <Spinner className="size-3 mr-1" />}

                          {labels.acceptAndSign}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className="flex justify-between mt-6">
                      <Button
                        type="button"
                        className="flex body-md items-center justify-center w-auto cursor-pointer"
                        onClick={() => {
                          setClientSignature(true);
                          setShowSignatureInput(true);
                        }}
                      >
                        {labels.applyHandwrittenSignatureClient}
                      </Button>
                      <Button
                        type="submit"
                        disabled={loading}
                        id="clientSignature"
                        className="flex btn-primary rounded-[8px] px-[13px] py-[6px] body-md items-center justify-center cursor-pointer"
                      >
                        {loading && <Spinner className="size-3 mr-1" />}
                        {labels.acceptAndSign}
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default Certificate;

import axios from "axios";
import "pdfjs-dist/build/pdf.worker.entry";
import { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Spinner from "@ui/Spinner";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import useIsMobile from "@hooks/useIsMobile";
import useOrientation from "@hooks/useOrientation";

import { getActorDataItem } from "@utils";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

const DocumentPreviewSignatoryConfirmation = () => {
  const [pdf, setPdf] = useState<number>(0);
  const [mimeType, setMimeType] = useState<string>("application/pdf");
  const [fileUrl, setFileUrl] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [numPagesMap, setNumPagesMap] = useState<Record<string, number>>({});
  const [zoom, setZoom] = useState<number>(1);
  const documentRef = useRef(null);
  const pdfContainerRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const documentId: string | null = queryParams.get("documentId");
  const signBookNumber = queryParams.get("signBookNumber");
  const actorCode = queryParams.get("actorCode") || "";
  const actTitle = queryParams.get("actTitle") || "";
  const [pdfUrls, setPdfUrls] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const isPortrait = useOrientation();
  const isMobile = useIsMobile();

  const accessToken = getActorDataItem(actorCode, "accessToken");

  const fetchPdfData = async (documentId: string) => {
    setLoading(true);

    let requestUrl = `/shared-api/v1/signbooks/${signBookNumber}/actors/${actorCode}/signbook/documents/download/${documentId}`;

    try {
      const response = await axios.get(requestUrl, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const contentType = response.headers["content-type"];
      setMimeType(contentType);
      const fileBlob = new Blob([response.data], { type: contentType });
      const fileUrl = URL.createObjectURL(fileBlob);

      if (mimeType !== "application/pdf") {
        setFileUrl(fileUrl);
        setLoading(false);
        return;
      } else {
        setPdfUrls([...pdfUrls, fileUrl]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(axiosErrorMessages[error.message], toastOptionsError);
    }
  };

  useEffect(() => {
    if (!documentId || !accessToken) {
      return;
    }

    fetchPdfData(documentId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, accessToken, mimeType]);

  useEffect(() => {
    const pdfPreview = document.querySelector(".pdf-container");

    if (pdfPreview) {
      (pdfPreview as HTMLElement).style.overflow =
        zoom !== 1 ? "auto" : "hidden";
    }
  }, [zoom]);

  useEffect(() => {
    let lastPageSeen = false;
    if (fileUrl.length > 0) {
      return;
    }
    const checkLastPageVisibility = () => {
      const lastPdf = pdfUrls.length - 1;

      const lastPage = numPagesMap[pdfUrls[pdfUrls.length - 1]];
      const lastPageElement = document.getElementById(
        `pdf_${lastPdf}_page_${lastPage}`,
      );

      if (lastPageElement) {
        const containerElement = lastPageElement.closest(
          ".scrollable-container",
        );

        if (containerElement) {
          const containerRect = containerElement.getBoundingClientRect();
          const lastPageRect = lastPageElement.getBoundingClientRect();
          const isVisible =
            lastPageRect.top >= containerRect.top &&
            lastPageRect.bottom <= containerRect.bottom;

          if (isVisible) {
            // Mark the last page as seen and set hasReadAll to true
            lastPageSeen = true;
          } else {
            // If the last page is not visible and has been seen before, reset lastPageSeen
            if (lastPageSeen) {
              lastPageSeen = false;
            }
          }
        }
      }
    };

    const containerElement = document.querySelector(".scrollable-container");
    if (containerElement) {
      containerElement.addEventListener("scroll", checkLastPageVisibility);
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener("scroll", checkLastPageVisibility);
      }
    };
  }, [numPagesMap, pdfUrls, fileUrl]);

  // When the component is loading, disable scroll
  useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [loading]);

  const onDocumentLoadSuccess = (
    { numPages }: { numPages: number },
    pdfUrl: string,
  ): void => {
    setNumPagesMap({
      ...numPagesMap,
      [pdfUrl]: numPages,
    });
  };

  const scrollToThumbnail = (pdf: number, page: number) => {
    const thumbnail: HTMLElement | null = document.getElementById(
      `pdf_${pdf}_page_${page}`,
    );

    if (thumbnail) {
      const scrollableContainer: HTMLElement | Window =
        (thumbnail.closest(".scrollable-container") as HTMLElement) || window;
      const thumbnailRect: DOMRect = thumbnail.getBoundingClientRect();
      const containerRect: DOMRect =
        scrollableContainer instanceof Window
          ? {
              top: 0,
              left: 0,
              bottom: window.innerHeight,
              right: window.innerWidth,
              width: window.innerWidth,
              height: window.innerHeight,
              x: 0,
              y: 0,
              toJSON: () => {
                /* empty implementation */
              },
            }
          : scrollableContainer.getBoundingClientRect();

      const scrollOptions: ScrollToOptions = {
        top:
          thumbnailRect.top -
          containerRect.top +
          (scrollableContainer instanceof Window
            ? window.scrollY
            : scrollableContainer.scrollTop) -
          containerRect.height / 2 +
          thumbnailRect.height / 2,
        left:
          thumbnailRect.left -
          containerRect.left +
          (scrollableContainer instanceof Window
            ? window.scrollX
            : scrollableContainer.scrollLeft) -
          containerRect.width / 2 +
          thumbnailRect.width / 2,
        behavior: "smooth",
      };

      if (scrollableContainer instanceof Window) {
        window.scrollTo(scrollOptions);
      } else {
        scrollableContainer.scrollTo(scrollOptions);
      }
    }
  };

  const prevPage = () => {
    let thumbnailPdf = pdf;
    let thumbnailPage = page;

    if (page > 1) {
      setPage(page - 1);
      thumbnailPage = page - 1;
    } else if (pdf > 0) {
      setPdf(pdf - 1);
      setPage(numPagesMap[pdfUrls[pdf - 1]]);

      thumbnailPdf = pdf - 1;
      thumbnailPage = numPagesMap[pdfUrls[pdf - 1]];
    } else if (pdf === 0 && page === 1) {
      setPdf(pdfUrls.length - 1);
      setPage(numPagesMap[pdfUrls[pdfUrls.length - 1]]);

      thumbnailPdf = pdfUrls.length - 1;
      thumbnailPage = numPagesMap[pdfUrls[pdfUrls.length - 1]];
    }

    scrollToThumbnail(thumbnailPdf, thumbnailPage);
  };

  const nextPage = () => {
    if (loading) {
      return;
    }
    let thumbnailPdf = pdf;
    let thumbnailPage = page;

    // If you have not reached the last page of the document
    if (page < numPagesMap[pdfUrls[pdf]]) {
      setPage(page + 1);
      thumbnailPage = page + 1;
      //If the last page of the document has been reached
    } else if (pdf < pdfUrls.length - 1) {
      setPdf(pdf + 1);
      setPage(1);
      thumbnailPdf = pdf + 1;
      thumbnailPage = 1;
      // If you are on the last page of the last document and there are no more documents to fetch
    } else if (pdf === pdfUrls.length && page === numPagesMap[pdfUrls[pdf]]) {
      setPdf(0);
      setPage(1);

      thumbnailPdf = 0;
      thumbnailPage = 1;
    }

    scrollToThumbnail(thumbnailPdf, thumbnailPage);
  };

  const handleChangingPage = (
    pdfIndex: number,
    index: number,
    pdfUrl: string,
  ) => {
    setPdf(pdfIndex);
    setPage(index + 1);
  };

  const documentWidth = isMobile ? (isPortrait ? 300 : 250) : 600;

  const documentDistanceClass = isMobile
    ? isPortrait
      ? "top-[280px]"
      : "top-[128px]"
    : "top-[280px]";

  return (
    <>
      <header className="relative z-[12] w-full h-[77px] flex justify-between border-b-[1px] px-[24px] lg:px-0 bg-white">
        <img
          srcSet="/assets/logo.svg"
          alt=""
          className="lg:block w-[102px] h-[60px] mt-[8px] xl:ml-[74px]"
        />
      </header>
      <div className="relative overflow-hidden min-h-[calc(100dvh-77px)] h-full">
        <div className="z-10 flex flex-col items-center lg:items-start justify-center w-full lg:w-auto mx-auto lg:mr-[80px] fixed lg:left-[150px]">
          <h2 className="headline-lg mb-1 lg:mb-[17px]">{actTitle}</h2>
          {/* Arrows previous/next */}
          <div className="flex items-center text-center">
            <div className="flex items-center">
              <Button
                onClick={() => {
                  prevPage();
                }}
                type="button"
                className="px-[20px] py-[10px] btn-secondary border-r-0 rounded-l-[8px]"
              >
                <Icon
                  type="arrow-left-pagination"
                  className="w-[7px] h-[14px]"
                />
              </Button>
              <p className="body-md border border-ea-darkblue px-[10px] py-[8px] bg-white">
                {labels.page} {page} - {labels.doc} {pdf + 1}
              </p>
              <Button
                onClick={() => {
                  nextPage();
                }}
                type="button"
                className="px-[20px] py-[10px] btn-secondary border-l-0 rounded-r-[8px]"
              >
                <Icon
                  type="arrow-right-pagination"
                  className="w-[7px] h-[14px]"
                />
              </Button>
            </div>
          </div>

          {/* Zoom in/out */}
          <div className="z-10 items-center text-center bg-white rounded-md border border-slate-700 fixed right-[350px] hidden lg:flex">
            <Button
              onClick={() => {
                setZoom((prevZoom) => Math.min(3.25, prevZoom + 0.25));
              }}
              type="button"
              className="px-[10px] py-[5px] border-r border-slate-700"
            >
              <Icon type="zoom-in" className="size-[18px]" />
            </Button>
            <Button
              onClick={() => {
                setZoom((prevZoom) => Math.max(0.25, prevZoom - 0.25));
              }}
              type="button"
              className="px-[10px] py-[5px]"
            >
              <Icon type="zoom-out" className="size-[18px]" />
            </Button>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-start justify-between min-h-[calc(100vh-77px)]">
          <div className="flex-column md:flex-row w-full">
            {/* PDF */}
            {loading ? (
              <div className="flex items-center justify-center w-full h-screen">
                <Spinner className="size-20 !border-t-slate-500" />
              </div>
            ) : (
              <div
                className="flex flex-col items-center justify-center pdf-container relative w-full min-h-[calc(100dvh-77px)]"
                ref={pdfContainerRef}
              >
                {/* PDF */}
                <div className="cursor-zoom-in">
                  {mimeType?.startsWith("image/") && (
                    <img
                      src={fileUrl}
                      alt="Document"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                  {mimeType === "application/pdf" &&
                    pdfUrls.length > 0 &&
                    pdfUrls.map((pdfUrl, index) => {
                      const visibleClass = pdf === index ? "" : "hidden";

                      if (pdfContainerRef.current) {
                        const container =
                          pdfContainerRef.current as HTMLElement;
                        container.scrollTop = (647 * zoom) / 2;
                        container.scrollLeft = (600 * zoom) / 2;
                      }

                      return (
                        <div
                          className={`absolute ${documentDistanceClass} md:top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4`}
                          key={`pdf_preview_${index + 1}`}
                        >
                          <div
                            className={`${visibleClass}`}
                            style={{
                              transform: `scale(${zoom})`,
                              transformOrigin: "top center",
                            }}
                          >
                            <TransformWrapper
                              initialScale={1}
                              initialPositionX={0}
                              initialPositionY={0}
                              maxScale={3}
                            >
                              <TransformComponent>
                                {pdf === index && (
                                  <Document
                                    file={pdfUrl}
                                    ref={documentRef}
                                    loading={<p>{labels.loading}</p>}
                                    error={<p>{labels.errorWhileLoadingPdf}</p>}
                                  >
                                    <Page
                                      pageNumber={page}
                                      renderTextLayer={false}
                                      renderAnnotationLayer={false}
                                      width={documentWidth}
                                      loading={<p>{labels.loading}</p>}
                                      error={
                                        <p>{labels.errorWhileLoadingPdf}</p>
                                      }
                                    />
                                  </Document>
                                )}
                              </TransformComponent>
                            </TransformWrapper>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
          {/* Thumbnails */}
          <div className="relative w-full md:w-[290px] pt-[69px] pb-[50px] pr-[20px] pl-[37px] bg-white flex-col justify-between items-center min-h-[calc(100dvh-77px)] h-full hidden lg:flex">
            <div
              className={`max-h-[600px] overflow-auto mb-2 scrollable-container`}
            >
              {fileUrl.length > 0 && (
                <img src={fileUrl} alt="Document" className="mb-4" />
              )}
              {/* Thumbnails */}
              {mimeType === "application/pdf" &&
                pdfUrls.length > 0 &&
                pdfUrls.map((pdfUrl, pdfIndex) => {
                  return (
                    <Document
                      file={pdfUrl}
                      key={`pdf_${pdfIndex + 1}`}
                      onLoadSuccess={(args) =>
                        onDocumentLoadSuccess(args, pdfUrl)
                      }
                      loading={<p>{labels.loading}</p>}
                      error={<p>{labels.errorWhileLoadingPdf}</p>}
                    >
                      <div className="flex">
                        <h2 className="mr-4 font-bold body-lg">
                          {labels.doc} {pdfIndex + 1}
                        </h2>
                        <div className="pt-1 mr-[30px]">
                          {Array.from(Array(numPagesMap[pdfUrl]).keys()).map(
                            (_, index) => {
                              const pageBorderClass =
                                pdf === pdfIndex && index + 1 === page
                                  ? "border-red-700 ring-2 ring-red-700"
                                  : "border-slate-200";

                              return (
                                <div
                                  id={`pdf_${pdfIndex}_page_${index + 1}`}
                                  key={`page_${index + 1}`}
                                >
                                  <Page
                                    pageNumber={index + 1}
                                    width={140}
                                    onClick={() => {
                                      handleChangingPage(
                                        pdfIndex,
                                        index,
                                        pdfUrl,
                                      );
                                    }}
                                    className={`${pageBorderClass} cursor-pointer mb-4 border`}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                    loading={<p>{labels.loading}</p>}
                                    error={<p>{labels.errorWhileLoadingPdf}</p>}
                                  />
                                </div>
                              );
                            },
                          )}
                        </div>
                      </div>
                    </Document>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentPreviewSignatoryConfirmation;

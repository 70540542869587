import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import DndComponent from "@ui/DndComponent";
import Icon from "@ui/Icon";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { selectUser } from "@reducers/metadataSlice";

import { Document, DocumentsSignProps } from "@types";

import { addQueryParams } from "@utils";

const DocumentsSign = ({
  changeOrderDisabled,
  actTitle,
}: DocumentsSignProps) => {
  const { publicId, actType } = useParams();
  const [documentsList, setDocumentsList] = useState<Document[]>([]);
  const [cguFile, setCguFile] = useState<any>();
  const overflowControl = documentsList.length > 3 ? "overflow-y-auto" : "";
  const user = useSelector(selectUser);

  useEffect(() => {
    if (publicId) {
      // GET request for uploaded documents
      axios
        .get(`/api/v1/signbooks/${publicId}/documents`)
        .then((response) => {
          const documents = response.data;
          if (documents.length > 0) {
            const cguDocuments: any[] = [];
            const otherDocuments: any[] = [];
            documents.map((document: Document, index: number) => {
              const formattedDocument = {
                id: document.documentUid,
                name:
                  document.documentName?.split("/")[1] || document.documentName,
                order:
                  document.type === "CGU" ? 0 : document.order || index + 1,
                validated: document?.metadata?.conversionValidated,
                fixed: document.type === "CGU",
              };

              if (document.type === "CGU") {
                setCguFile(formattedDocument);
                cguDocuments.push(formattedDocument);
              } else {
                otherDocuments.push(formattedDocument);
              }
              return formattedDocument;
            });
            otherDocuments.sort((a: any, b: any) => a.order - b.order);
            setDocumentsList(otherDocuments);
            if (cguDocuments.length > 0) {
              setCguFile(cguDocuments[0]);
            }
          }
        })
        .catch((error) => {
          toast.error(axiosErrorMessages[error.message], toastOptionsError);
        });
    }
  }, [publicId]);

  const Document = (props: { data: Document; id: string }) => {
    const title = props.data.name;
    const validatedDocument = props.data.validated;
    const { active, attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: props.id, disabled: changeOrderDisabled });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    const handleIcon = active?.id === props.id ? "handle-active" : "handle";

    return (
      <div
        className="flex items-center justify-between mb-[24px]"
        ref={setNodeRef}
        style={style}
      >
        <div className="flex">
          {documentsList.length > 1 && !changeOrderDisabled && (
            <span
              className="cursor-pointer mr-[14px] hidden md:block"
              {...attributes}
              {...listeners}
            >
              <Icon type={handleIcon} className="size-[24px]" />
            </span>
          )}
          <div className="flex">
            <img
              src={"/assets/pdf-icon.png"}
              alt={"pdf icon"}
              className="w-[18px] h-[22px] mr-[17px]"
            />
            <p className="body-md">{title}</p>
          </div>
        </div>
        <div className="flex items-center">
          {validatedDocument && (
            <Icon
              type="check"
              color="#00C45A"
              className="w-[14px] h-[10px] md:mr-[20px]"
            />
          )}
          <Link
            to={addQueryParams(`/acts/${actType}/new/documents/preview`, {
              documentId: props.id,
              returnPath: `/acts/${actType}/act-details/${publicId}`,
              actId: publicId,
              actTitle: actTitle,
              documentName: title,
              lawyer: user?.id ? "true" : "",
            })}
            className="btn-secondary body-md px-[15px] py-[6px] rounded-[8px] mr-2 hidden md:block"
          >
            {labels.view}
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className="headline-md mx-[12px] md:mx-6 lg:auto text-black mt-16">
      <section className="w-full lg:w-[612px] mx-auto">
        <div className="flex justify-between items-center pr-[9px]">
          <h2 className="flex">{labels.documentsToSign}</h2>
        </div>
        <div className="mt-8">
          <div
            className={`mt-[25px] mx-[8px] md:mx-0 mb-[40px] max-h-[200px] ${overflowControl}`}
          >
            {cguFile && (
              <div className="flex justify-between items-center mt-[24px] first:mt-0 mb-[24px]">
                <span className="flex w-full items-center py-[5px]">
                  <img src="/assets/pdf-icon.png" alt="" />
                  <span className="ml-4 body-sm">{cguFile.name}</span>
                </span>

                <div className="flex items-center">
                  {cguFile.validated && (
                    <Icon
                      type="check"
                      color="#00C45A"
                      className="w-[14px] h-[10px] mr-[20px]"
                    />
                  )}

                  <Link
                    to={addQueryParams(
                      `/acts/${actType}/new/documents/preview`,
                      {
                        documentId: cguFile.id,
                        returnPath: `/acts/${actType}/act-details/${publicId}`,
                        actId: publicId,
                        actTitle: actTitle,
                        documentName: cguFile.name,
                        lawyer: user?.id ? "true" : "",
                      },
                    )}
                    className="btn-secondary body-md px-[15px] py-[6px] rounded-[8px] mr-2"
                  >
                    {labels.view}
                  </Link>
                </div>
              </div>
            )}
            <DndComponent items={documentsList} setItems={setDocumentsList}>
              {documentsList.map((document, index) => (
                <Document key={index} data={document} id={document.id} />
              ))}
            </DndComponent>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DocumentsSign;

import Company from "@components/fragments/party-form/Company";
import PhysicalPerson from "@components/fragments/party-form/PhysicalPerson";

export const PartyForm = ({
  formValue,
  files,
  setFiles,
  uploadFileControl,
  formik,
  locked,
}: any) => {
  switch (formValue) {
    case "TYPE_NATURAL":
      return (
        <PhysicalPerson
          files={files}
          setFiles={setFiles}
          uploadFileControl={uploadFileControl}
          formik={formik}
          locked={locked}
        />
      );
    case "TYPE_LEGAL":
      return (
        <Company
          files={files}
          setFiles={setFiles}
          uploadFileControl={uploadFileControl}
          formik={formik}
          locked={locked}
        />
      );
    default:
      return (
        <PhysicalPerson
          files={files}
          setFiles={setFiles}
          uploadFileControl={uploadFileControl}
          formik={formik}
          locked={locked}
        />
      );
  }
};

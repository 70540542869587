import { useKeycloak } from "@react-keycloak/web";

import axios from "axios";
import { fr } from "date-fns/locale/fr";
import { useEffect } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import sanitizeHtml from "sanitize-html";

import CookieBotComponent from "@components/cookies/CookieBot";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { initEaf, sendLogs, sendStats } from "@eaf";

import useApiHook from "@hooks/useApiHook";

import ActDetails from "@pages/ActDetails";
import DocumentPreview from "@pages/DocumentPreview";
import Documents from "@pages/Documents";
import Home from "@pages/Home";
import Profile from "@pages/Profile";
import RequiredAction from "@pages/RequiredAction";
import Signatures from "@pages/Signatures";
import NewAct from "@pages/new-act";
import Onboarding from "@pages/onboarding";

import { setEafOn, setTokens } from "@reducers/eafSlice";
import { selectLocale } from "@reducers/localeSlice";
import {
  selectErrors,
  selectSettings,
  selectUser,
  selectVersion,
  setErrors,
  setNotifications,
  setPrepaidAccountInfo,
  setRequiredActionsCount,
  setSettings,
  setUser,
  setVersion,
} from "@reducers/metadataSlice";
import { hideModal, selectModal } from "@reducers/modalsSlice";
import { hideOverlay, selectOverlay } from "@reducers/overlaySlice";

import { ResponseNotificationsProps } from "@types";

import { getPrepaidAccountInfo } from "@utils/api/prepaidAccountApi";

const App = () => {
  const dispatch = useDispatch();
  const version = useSelector(selectVersion);
  const user = useSelector(selectUser);
  const errors = useSelector(selectErrors);
  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();
  const modal = useSelector(selectModal);
  const settings = useSelector(selectSettings);

  if (keycloak.token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
  }

  // Load user information and notifications.
  const getMetaData = async () => {
    // Get user information.
    axios
      .get("/api/v1/me")
      .then(async (res: any) => {
        // Check if all user info fields are present.
        const fields = ["firstname", "lastname", "email", "publicId"];

        if (!fields.every((field) => res.data[field])) {
          dispatch(setErrors({ ...errors, userInfo: true }));
          toast.error(labels.errorLoadingUserInfo, toastOptionsError);
          return;
        }

        const { publicId, firstname, lastname, ...rest } = res.data;
        const userData = {
          firstName: firstname,
          lastName: lastname,
          numCNBF: publicId,
          username: publicId,
          publicId,
          keycloackToken: keycloak.token,
          ...rest,
        };

        dispatch(setUser(userData));

        // Get user preferences.
        axios
          .get(`/api/v1/users/${publicId}/preferences`)
          .then(async (res: any) => {
            const { actTypes } = res.data;

            dispatch(
              setUser({
                ...userData,
                actTypes: actTypes,
              }),
            );
          });

        // Get cookieBotId and act
        axios.get("/api/v1/settings").then(async (res: any) => {
          const { cookieBotId, dcmIntro, ...rest } = res.data;
          const cleanedDcmIntro = sanitizeHtml(dcmIntro, {
            allowedTags: [
              "a",
              "p",
              "strong",
              "em",
              "u",
              "ul",
              "li",
              "span",
              "div",
              "br",
            ],
            allowedAttributes: {
              a: ["href", "tabindex", "target"],
              div: ["class", "id"],
              span: ["class"],
              p: ["class"],
            },
            allowedIframeHostnames: [
              "https://www.ar24.fr/",
              "https://partage.cnb.avocat.fr/",
            ],
          });

          dispatch(
            setSettings({
              cookieBotId: cookieBotId,
              dcmIntro: cleanedDcmIntro,
              ...rest,
            }),
          );
        });

        axios
          .post("/api/v1/signbooks/count-searches")
          .then(async (res: any) => {
            dispatch(setRequiredActionsCount(res.data));
          });
      })
      .catch(() => {
        toast.error(labels.errorLoadingUserInfo, toastOptionsError);
        dispatch(setErrors({ ...errors, user: true }));
      });

    // Get notifications.
    axios
      .get(`/api/v1/notifications`)
      .then((response) => {
        const cleanedNotifications = response.data.map(
          (notification: ResponseNotificationsProps) => {
            return {
              content: sanitizeHtml(notification.content, {
                allowedTags: ["a", "p", "strong", "em", "u", "br", "marquee"],
                allowedAttributes: {
                  a: ["href", "tabindex", "target"],
                },
              }),
              date: sanitizeHtml(notification.date),
              title: sanitizeHtml(notification.title, {
                allowedTags: ["a", "p", "strong", "em", "u", "br"],
              }),
            };
          },
        );

        dispatch(setNotifications(cleanedNotifications));
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
    // Get prepaid account information.
    getPrepaidAccountInfo(keycloak?.token).then((data) => {
      if (!data) {
        return;
      }
      dispatch(
        setPrepaidAccountInfo({
          balance: (data.balance / 100).toFixed(2),
          currentEstimatedBalance: data.currentEstimatedBalance,
          warningNotificationLimit: data.warningNotificationLimit,
          warningNotificationLimitType: data.warningNotificationLimitType,
        }),
      );
    });
  };

  // Load the application version from the backend.
  const { data: appVersion, error } = useApiHook<any>(
    `${process.env.REACT_APP_BE_BASEURL}`,
    "/api/v1/version",
  );

  // Initialize EAF.
  useEffect(() => {
    const eaf = initEaf();

    if (eaf) {
      eaf
        .onLoadSuccess(() => {
          dispatch(setEafOn(true));
        })
        .onLoadError(function () {
          // "Load error: " + JSON.stringify(arguments)
        })
        .onError(() => {
          // "Error"
        })
        .onListTokens((tokens: Record<string, any>) => {
          dispatch(setTokens(tokens));
        })
        .init();
    }

    const handleLogsEvent = (_: CustomEvent, logs: string[]) => {
      sendLogs(logs);
    };

    const handleStatsEvent = (_: CustomEvent, stats: string[]) => {
      sendStats(stats);
    };

    document.addEventListener("eafOnLogs", handleLogsEvent as EventListener);
    document.addEventListener("eafOnStats", handleStatsEvent as EventListener);

    return () => {
      document.removeEventListener(
        "eafOnLogs",
        handleLogsEvent as EventListener,
      );

      document.removeEventListener(
        "eafOnStats",
        handleStatsEvent as EventListener,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get version on startup.
  useEffect(() => {
    // If we don't login we can't get the token so we skip entirely.
    if ("true" === process.env.REACT_APP_SKIP_LOGIN) {
      return;
    }

    if (error && !errors.version) {
      dispatch(setErrors({ ...errors, version: true }));
      toast.error(labels.errorLoadingVersion, toastOptionsError);
      return;
    }

    if (appVersion && "" === version) {
      dispatch(setVersion(appVersion));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appVersion]);

  // Get user info on startup.
  useEffect(() => {
    // If we don't login we can't get the token so we skip entirely.
    if ("true" === process.env.REACT_APP_SKIP_LOGIN) {
      return;
    }

    if (keycloak.authenticated && "" === user.firstName && !errors.userInfo) {
      getMetaData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.authenticated]);

  // Register local for datepicker.
  registerLocale("fr", fr);
  setDefaultLocale(useSelector(selectLocale));

  // Hide modals when the route changes.
  useEffect(() => {
    if (modal) {
      dispatch(hideModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dispatch]);

  // Hide modals and overlays when the Escape key is pressed.
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        const closableModal = document.querySelector("[data-closable]");
        if (closableModal) {
          dispatch(hideModal());
        }
        dispatch(hideOverlay());
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  });

  // Disable scrolling when a modal or an overlay is open.
  const isModalOpen = useSelector(selectModal);
  const isOverlayOpen = useSelector(selectOverlay);
  const disableScrolling = isModalOpen || isOverlayOpen;

  useEffect(() => {
    const classAction = disableScrolling ? "add" : "remove";
    document.body.classList[classAction]("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [disableScrolling]);

  return (
    <>
      <CookieBotComponent key={settings.cookieBotKey} />
      {initialized &&
        (keycloak.authenticated ||
          "true" === process.env.REACT_APP_SKIP_LOGIN) && (
          <Routes>
            <Route>
              <Route index element={<Home />} />
              <Route path="/onboarding/*" element={<Onboarding />} />
              <Route path="/required-actions/*" element={<RequiredAction />} />
              {
                // We need to list all the act tabbed routes paths here to avoid
                // overlapping with the wildcard routes below
                [
                  "/acts",
                  "/acts/convention",
                  "/acts/divorce",
                  "/acts/digital",
                  "/acts/birth",
                  "/acts/expiring",
                  "/acts/closed",
                ].map((path, index) => (
                  <Route key={index} path={path} element={<Documents />} />
                ))
              }
              <Route path="/signatures" element={<Signatures />} />
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/acts/:actType/act-details/:publicId"
                element={<ActDetails />}
              />
              <Route path="/acts/:actType/*" element={<NewAct />} />
              <Route
                path="/lawyer-signing"
                element={<DocumentPreview lawyerSigning={true} />}
              />
              <Route
                path="/acts/:actType/new/documents/preview"
                element={<DocumentPreview />}
              />
              <Route path="*" />
            </Route>
          </Routes>
        )}
    </>
  );
};

export default App;

import axios from "axios";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import EntityTabs from "@components/fragments/EntityTabs";
import ActHistory from "@components/fragments/act-details/ActHistory";
import DocumentsSign from "@components/fragments/act-details/DocumentsSign";
import Layout from "@components/layout/Layout";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import ProgressBar from "@ui/ProgressBar";
import Spinner from "@ui/Spinner";

import {
  ActGeneralInformation,
  actMapAmount,
  actTypeNameMap,
  axiosErrorMessages,
  labels,
  toastOptionsError,
} from "@constants";

import {
  selectPageData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import { selectSettings, selectUser } from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";
import { setLoading } from "@reducers/tracesSlice";

import {
  ActGeneralInformationProps,
  ActHistoryTableProps,
  SignatoriesParties,
  StatusProps,
  actDetailsData,
} from "@types";

import { formatLabel, formatTimestamp, noop } from "@utils";

const ActDetails = () => {
  const [databaseActDetails, setDatabaseActDetails] =
    useState<actDetailsData | null>(null);
  const [actGeneralInformation, setActGeneralInformation] =
    useState<ActGeneralInformationProps>(ActGeneralInformation);
  const [changeOrderDisabled, setChangeOrderDisabled] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [actCreator, setActCreator] = useState<string | null>(null);
  const [statusData, setStatusData] = useState<StatusProps | null>(null);
  const [duplicating, setDuplicating] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector(selectPageData);
  const navigate = useNavigate();
  const { actType, publicId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const returnPath = queryParams.get("returnPath");
  const backPath = returnPath ?? `/acts/${actType}`;
  const [traceData, setTraceData] = useState<ActHistoryTableProps[]>([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [amount, setAmount] = useState<number>(0);
  const [partyData, setPartyData] = useState<SignatoriesParties[]>([]);
  const [actDetails, setActDetails] = useState<Record<string, any>>({});
  const settings = useSelector(selectSettings);
  const metadata = useSelector(selectUser);
  const loggedUser = metadata.numCNBF;

  const getTraces = (page: number) => {
    dispatch(setLoading(true));

    axios
      .post(`/api/v1/signbooks/${publicId}/traces/search?page=${page - 1}`)
      .then((response) => {
        dispatch(setLoading(false));
        const traces = response.data?.traceEvents;
        const total = response.data?.total;

        if (0 === maxPage) {
          const totalPages = Math.floor(total / 10);
          setMaxPage(totalPages + 1);
        }

        if (traces.length === 0) {
          return;
        }

        if (traces) {
          const tracesData = traces.map((trace: Record<string, string>) => {
            return {
              date: formatTimestamp(trace.date, true, true),
              details: trace.data,
              signatory: trace.userDisplayName,
            };
          });

          setTraceData(tracesData);
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  useEffect(() => {
    if (0 === page) {
      return;
    }
    getTraces(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.confirm) {
      fatchActData(data.actToUpdate.publicId);
      dispatch(setModalData(null));
      dispatch(setPageData(null));
    }
  }, [data, dispatch, navigate, backPath]);

  useEffect(() => {
    if (!publicId) {
      return;
    }
    fatchActData(publicId);
  }, [publicId]);

  useEffect(() => {
    if (!settings) {
      return;
    }
    // Map the act type to the correct amount
    const amount = actMapAmount[actType as keyof typeof actMapAmount];
    const actAmount = Number(settings[amount as keyof typeof settings]);
    if (actAmount && actAmount > 0) {
      setAmount(actAmount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, actType]);

  const fatchActData = (publicId: string) => {
    axios
      .get(`/api/v1/signbooks/${publicId}`)
      .then((response) => {
        setDatabaseActDetails({
          id: response.data.id,
          name: response.data.name,
          creationDate: response.data.creationDate,
          step: response.data.step,
        });

        setActGeneralInformation({
          actNumber: response.data.publicId,
          signingStatus: response.data.signingStatus,
        });
        setActDetails(response.data);
        if (response.data.signingStatus === "SIGNED") {
          setChangeOrderDisabled(true);
        }

        if (
          response.data.signingStatus === "SIGNING" &&
          !response.data.expired
        ) {
          axios
            .get(`/api/v1/signbooks/${publicId}/status`)
            .then((response) => {
              setStatusData(response.data);
            })
            .catch((error) => {
              toast.error(axiosErrorMessages[error.message], toastOptionsError);
            });
        }
        setActCreator(response.data.actCreatorCNBF);
        setInitializing(false);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  if (initializing) {
    return (
      <Layout sidebar={false}>
        <div className="flex justify-center items-center p-6 -mb-2 bg-[#F9F9F9]">
          <Spinner className="size-10" dark={true} />
        </div>
      </Layout>
    );
  }

  const buttonDisplayClass =
    databaseActDetails?.step === "cancelled"
      ? "justify-end"
      : "justify-between";

  const hasPayButton =
    databaseActDetails?.step === "waitingForPayment" && amount > 0;
  const duplicateButtonMargin = hasPayButton ? "md:mr-[10px]" : "";

  return (
    <Layout sidebar={false}>
      <Formik onSubmit={noop} initialValues={{}}>
        <Form>
          <div className="md:flex bg-ea-gray-50 max-md:overflow-hidden">
            <div className="lg:w-[200px]"></div>
            <div className="relative bg-white lg:w-[695px] mx-4 md:mx-6 lg:mx-auto rounded-lg pb-2 mb-16 mt-[57px] pt-[27px]">
              <div className="absolute top-[-50px] left-0 md:left-[37px] md:top-[24px] lg:top-[-10px] lg:left-[-110px]">
                <Link to={backPath}>
                  <Icon type="back-arrow" className="flex w-[41px] h-[41px]" />
                </Link>
              </div>

              <div className="mx-auto">
                <div className="mx-[12px] md:mx-[45px]">
                  <h1 className="mb-[24px] headline-lg flex flex-col lg:flex-row justify-between items-start md:items-center whitespace-nowrap pr-5">
                    {databaseActDetails?.name}

                    <span className="hidden md:block body-sm">
                      {`${labels.number} : ${actGeneralInformation.actNumber}`}
                    </span>
                  </h1>
                  {statusData && (
                    <>
                      <ProgressBar
                        currentStep={statusData?.progress ?? 0}
                        maxSteps={100}
                      />
                      <p className="body-lg mt-[12px]">{statusData?.message}</p>
                    </>
                  )}
                </div>

                <div className="px-5">
                  <EntityTabs
                    partyData={partyData}
                    actions={false}
                    setPartyData={setPartyData}
                    actDetails={actDetails}
                  />
                </div>
                <DocumentsSign
                  changeOrderDisabled={changeOrderDisabled}
                  actTitle={databaseActDetails?.name}
                />
                {actCreator === loggedUser && (
                  <ActHistory
                    rows={traceData}
                    maxPage={maxPage}
                    setPage={setPage}
                    title={databaseActDetails?.name ?? ""}
                  />
                )}
                <div className="mx-[6px] md:mx-6 lg:auto text-black my-16 body-lg">
                  <section className="w-full lg:w-[612px] mx-auto">
                    <div className={`flex items-center ${buttonDisplayClass}`}>
                      {databaseActDetails?.step !== "cancelled" && (
                        <Button
                          className="btn-base btn-secondary"
                          type="button"
                          onClick={() => {
                            dispatch(
                              setModalData({
                                title: labels.cancelationOfAct,
                                ctaButtonLabel: labels.cancelTheAct,
                                publicId,
                              }),
                            );
                            dispatch(showModal("confirm"));
                          }}
                        >
                          {labels.cancelActButton}
                        </Button>
                      )}
                      <div className="flex justify-between">
                        {"digital" !== actType && (
                          <Button
                            className={`btn-base btn-secondary flex justify-center items-center ${duplicateButtonMargin}`}
                            type="button"
                            disabled={duplicating}
                            onClick={() => {
                              setDuplicating(true);

                              axios
                                .post(`/api/v1/signbooks/${publicId}/duplicate`)
                                .then((response) => {
                                  const { publicId, type, businessSubType } =
                                    response.data;
                                  const normalizedType =
                                    "" !== businessSubType
                                      ? actTypeNameMap[businessSubType]
                                      : actTypeNameMap[type];

                                  navigate(
                                    `/acts/${normalizedType}/${publicId}/information`,
                                  );

                                  setDuplicating(false);
                                })
                                .catch((error) => {
                                  setDuplicating(false);
                                  toast.error(
                                    axiosErrorMessages[error.message],
                                    toastOptionsError,
                                  );
                                });
                            }}
                          >
                            {duplicating && <Spinner className="size-4 mr-4" />}
                            {labels.duplicateActButton}
                          </Button>
                        )}
                        {hasPayButton && (
                          <Link
                            className="btn-base btn-primary h-[34px] mr-[10px] hidden md:block"
                            to={""}
                          >
                            {formatLabel(labels.payAmount, amount.toString())}
                          </Link>
                        )}
                      </div>
                    </div>
                  </section>
                  {hasPayButton && (
                    <Link
                      className="btn-base btn-primary h-[34px] w-full mt-4 block text-center md:hidden"
                      to={""}
                    >
                      {formatLabel(labels.payAmount, amount.toString())}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </Layout>
  );
};

export default ActDetails;

import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import EntityTabs from "@components/fragments/EntityTabs";
import SearchOrCreateParties from "@components/fragments/SearchOrCreateParties";

import Button from "@ui/Button";
import Icon from "@ui/Icon";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { setValidatedData } from "@reducers/actCreationSlice";
import { selectUser } from "@reducers/metadataSlice";
import { resetPartyForm } from "@reducers/partyFormSlice";
import { setPage } from "@reducers/tracesSlice";

import { SignatoriesParties } from "@types";

const PartySignatures = () => {
  const { actType, publicId } = useParams();

  const [showAddParties, setShowAddParties] = useState<boolean>(false);
  const [partyData, setPartyData] = useState<SignatoriesParties[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [actDetails, setActDetails] = useState<any>({});

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    const extractPartiesForSealing = partyData.filter(
      (party: any) => party.linkedTo === user.numCNBF,
    );

    const locked = extractPartiesForSealing.every(
      (extract: any) => extract.isLocked,
    );

    if (partyData.length > 0 && actType === "digital") {
      setDisabled(false);
      dispatch(setValidatedData(true));
      return;
    }

    if (!locked && actType !== "convention" && actType !== "digital") {
      setDisabled(false);
      dispatch(setValidatedData(false));
      return;
    }

    dispatch(setValidatedData(true));
  }, [partyData, dispatch, actType, user.numCNBF]);

  const partyTitle =
    "convention" === actType ? labels.clients : labels.signingParties;
  const addPartyButtonTitle =
    "convention" === actType ? labels.addClient : labels.addParty;

  const disabledButtonStyle = disabled ? "btn-disabled-light" : "";

  // Disable button if there are 2 parties and the act type is divorce
  useEffect(() => {
    if ("divorce" === actType && partyData.length === 2) {
      setDisabled(true);
    } else if ("divorce" === actType && partyData.length < 2) {
      dispatch(setValidatedData(false));
      setDisabled(false);
    }
  }, [partyData, actType, dispatch]);

  useEffect(() => {
    if (!publicId) {
      return;
    }

    axios
      .get(`/api/v1/signbooks/${publicId}`)
      .then((response) => {
        setActDetails(response.data);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  }, [publicId]);

  return (
    <div className="[&_.party-card:hover]:z-50 mx-[6px] md:mx-[45px] py-[32px]">
      <section className="w-full lg:w-[605px] mx-auto ">
        <div className="flex justify-between mb-[20px]">
          <h2 className="mb-3 headline-lg ">{partyTitle}</h2>
          {!showAddParties ? (
            <Button
              className={`btn-secondary flex items-center px-3 py-1 rounded-lg border h-[34px] body-lg ${disabledButtonStyle}`}
              onClick={() => {
                setShowAddParties(true);
                dispatch(resetPartyForm());
                dispatch(setPage(0));
              }}
              disabled={disabled}
            >
              <Icon type="plus" className="size-[24px]" />
              {addPartyButtonTitle}
            </Button>
          ) : (
            <>
              <button
                onClick={() => {
                  dispatch(resetPartyForm());
                  setShowAddParties(false);
                }}
              >
                <Icon type="close" className="size-[15px] mt-8" />
              </button>
            </>
          )}
        </div>
        {showAddParties && (
          <SearchOrCreateParties setShowAddParties={setShowAddParties} />
        )}
        <EntityTabs
          partyData={partyData}
          setPartyData={setPartyData}
          actDetails={actDetails}
        />
      </section>
    </div>
  );
};

export default PartySignatures;

import { ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";

import Icon from "@ui/Icon";
import PhoneField from "@ui/PhoneField";

import { countryPhoneCodes, labels } from "@constants";

const GeneralInformation = ({ formik }: { formik: any }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    const hideDropdown = () => {
      if (dropdownVisible) {
        setDropdownVisible(false);
      }
    };

    window.addEventListener("click", hideDropdown);

    return () => {
      window.removeEventListener("click", hideDropdown);
    };
  }, [dropdownVisible]);

  const getIconClass = (fieldName: string) => {
    return `absolute mt-[35px] checkbox-ml-profile w-[20px] h-[20px] text-green-500 pointer-events-none ${
      formik.values[fieldName]?.length > 0 ? "block" : "hidden"
    }`;
  };

  return (
    <div className=" mt-7 lg:mt-[127px] mr-auto ml-auto">
      <section className="lg:w-[612px] mr-auto ml-auto">
        <h2 className="mb-3 headline-lg">{labels.generalInformationTitle}</h2>

        <div className="flex flex-row flex-wrap md:gap-x-[24px] mb-[24px]">
          <div className="flex flex-col w-full md:w-[293px]">
            <label
              htmlFor="firstName"
              className="body-sm mb-[10px] text-gray-400"
            >
              {labels.name}
            </label>
            <Field
              type="text"
              id="firstName"
              name="firstName"
              className="input md:w-[293px] border border-[#F5F5F5] rounded body-lg h-[34px] p-2 bg-white"
              value={formik.values.firstName}
              disabled
            />
            <Icon type="check" className={getIconClass("firstName")} />
          </div>
          <div className="mt-6 md:mt-0 flex flex-col w-full md:w-[293px]">
            <label
              htmlFor="lastName"
              className="body-sm mb-[10px] text-gray-400"
            >
              {labels.surname}
            </label>
            <Field
              type="text"
              id="lastName"
              name="lastName"
              className="input md:w-[293px] border border-[#F5F5F5] bg-white rounded body-lg h-[34px] p-2"
              value={formik.values.lastName}
              disabled
            />
            <Icon type="check" className={getIconClass("lastName")} />
          </div>
        </div>

        <div className="flex flex-row flex-wrap md:gap-x-[24px] mb-[24px]">
          <div className="flex flex-col w-full md:w-[293px]">
            <label
              htmlFor="numCNBF"
              className="body-sm mb-[10px] text-gray-400"
            >
              {labels.codeCNBF}
            </label>
            <Field
              type="text"
              id="numCNBF"
              name="numCNBF"
              className="input md:w-[293px] border border-[#F5F5F5] bg-white rounded body-lg h-[34px] p-2"
              value={formik.values.numCNBF}
              disabled
            />
            <Icon
              type="check"
              className={`absolute mt-[35px] checkbox-ml-profile w-[20px] h-[20px] text-green-500 pointer-events-none ${
                formik.values.numCNBF && formik.values.numCNBF?.length > 0
                  ? "block"
                  : "hidden"
              }`}
            />
          </div>
          <div className="mt-6 md:mt-0 flex flex-col w-full md:w-[293px]">
            <label htmlFor="office" className="body-sm mb-[10px] text-gray-400">
              {labels.homeOffice}
            </label>
            <Field
              type="text"
              id="office"
              name="office"
              className="input md:w-[293px] border border-[#F5F5F5] bg-white rounded body-lg h-[34px] p-2"
              value={formik.values.office}
              disabled
            />
            <Icon
              type="check"
              className={`absolute mt-[35px] checkbox-ml-profile w-[20px] h-[20px] text-green-500 pointer-events-none ${
                formik.values.office && formik.values.office.length > 0
                  ? "block"
                  : "hidden"
              }`}
            />
          </div>
        </div>
        <div className="flex flex-row flex-wrap md:gap-x-[24px] mb-[24px]">
          <div className="flex flex-col w-full md:w-[293px] ">
            <label htmlFor="email" className="body-sm mb-[10px] text-gray-400">
              {labels.email}
            </label>
            <Field
              type="email"
              id="email"
              name="email"
              className="input md:w-[293px] border border-[#B4B4B4] rounded body-lg h-[34px] p-2"
              values={formik.values.email}
              onChange={formik.handleChange}
            />
            <ErrorMessage name="email">
              {(msg) => (
                <span className="text-[10px] max-w-[293px] text-ea-red">
                  {msg}
                </span>
              )}
            </ErrorMessage>
          </div>
          <div className="mt-6 md:mt-0 flex flex-col w-full md:w-[293px]">
            <label htmlFor="phone" className="body-sm mb-[10px] text-gray-400">
              {labels.phone}
            </label>
            <div className="flex items-center md:w-[293px] relative">
              <PhoneField
                codes={countryPhoneCodes}
                phoneCodeName="countryCode"
                setFieldValue={formik.setFieldValue}
              />
            </div>
            <ErrorMessage name="phone">
              {(msg) => (
                <span className="text-[10px] max-w-[293px] text-ea-red">
                  {msg}
                </span>
              )}
            </ErrorMessage>
          </div>
        </div>
        <div className="flex flex-row flex-wrap lg:flex-nowrap md:gap-x-[24px] mb-[24px] sm:ml-0">
          <div className="flex flex-col min-w-full sm:min-w-fit lg:w-[293px] ">
            <label
              htmlFor="address"
              className="body-sm mb-[10px] text-gray-400"
            >
              {labels.billingAdress}
            </label>
            <Field
              type="text"
              id="address"
              name="address"
              className="input w-full md:w-[293px] border border-[#B4B4B4] rounded body-lg h-[34px] p-2"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
            <ErrorMessage name="address">
              {(msg) => (
                <span className="text-[10px] max-w-[293px] text-ea-red">
                  {msg}
                </span>
              )}
            </ErrorMessage>
          </div>
          <div className="mt-6 md:mt-0  flex flex-col min-w-full sm:min-w-fit lg:w-[293px]">
            <label
              htmlFor="postCode"
              className="body-sm mb-[10px] text-gray-400"
            >
              {labels.postCode}
            </label>
            <Field
              type="text"
              id="postCode"
              name="postCode"
              className="input md:w-[135px] border border-[#B4B4B4] rounded body-lg h-[34px] p-2"
              value={formik.values.postCode}
              onChange={formik.handleChange}
            />
            <ErrorMessage name="postCode">
              {(msg) => (
                <span className="text-[10px] max-w-[293px] text-ea-red">
                  {msg}
                </span>
              )}
            </ErrorMessage>
          </div>
          <div className="mt-6 md:mt-0 flex flex-col min-w-full sm:min-w-fit lg:w-[293px]">
            <label htmlFor="city" className="body-sm mb-[10px] text-gray-400">
              {labels.city}
            </label>
            <Field
              type="text"
              id="city"
              name="city"
              className="input md:w-[135px] border border-[#B4B4B4] rounded body-lg h-[34px] p-2"
              value={formik.values.city}
              onChange={formik.handleChange}
            />
            <ErrorMessage name="city">
              {(msg) => (
                <span className="text-[10px] max-w-[293px] text-ea-red">
                  {msg}
                </span>
              )}
            </ErrorMessage>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GeneralInformation;

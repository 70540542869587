import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Account from "@components/fragments/profile/Account";
import GeneralInformation from "@components/fragments/profile/GeneralInformation";
import NotificationSetup from "@components/fragments/profile/NotificationSetup";
import Signature from "@components/fragments/profile/Signature";
import Layout from "@components/layout/Layout";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import MultiSelect from "@ui/MultiSelect";
import Spinner from "@ui/Spinner";

import {
  axiosErrorMessages,
  labels,
  preferencesDropdownOptions,
  toastOptions,
  toastOptionsError,
} from "@constants";

import {
  selectFormikData,
  setFormikData,
  setModalData,
} from "@reducers/dataTransferSlice";
import {
  selectPrepaidAccountInfo,
  selectUser,
  setUser,
} from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";
import { selectUseByDefault } from "@reducers/signatureSlice";

import { MultiselectOption, ProfilePageProps } from "@types";

import { formatLabel } from "@utils";
import { updateAccountAlertThreshold } from "@utils/api/prepaidAccountApi";
import {
  addressRegex,
  cityName,
  frenchPhoneNumberRegex,
  postCodeRegex,
} from "@utils/regex";

const Profile = (props: ProfilePageProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [avatar, setAvatar] = useState("");
  const [selected, setSelected] = useState<MultiselectOption[]>([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const backLocation = location.pathname.split("/").slice(0, -1).join("/");
  const navigate = useNavigate();
  const formikData = useSelector(selectFormikData);
  const dispatch = useDispatch();
  const userData = useSelector(selectUser);
  const useByDefault = useSelector(selectUseByDefault);
  const prepaidAccountInfo = useSelector(selectPrepaidAccountInfo);
  const balanceAmount = prepaidAccountInfo?.balance;

  useEffect(() => {
    if (!userData.actTypes) {
      return;
    }

    const selectedItems = preferencesDropdownOptions.filter((option) =>
      userData.actTypes.includes(option.value),
    );

    setSelected(selectedItems);
  }, [userData]);

  // Extract the value for a given code from the metaDataValues array
  const getMetaDataValue = (
    metaDataValues: Record<string, string>[],
    code: string,
  ) => {
    if (metaDataValues) {
      const metaData = metaDataValues?.find((item) => item.code === code);
      return metaData ? metaData.value : "";
    }
    return "";
  };

  const setInitialValues = (userData: any | null) => {
    const splitPhoneNumber = userData.mainPhoneNumber?.slice(-9);
    const splitPhoneCode = userData.mainPhoneNumber?.slice(0, -9);
    return {
      lastName: userData?.lastName || "",
      firstName: userData?.firstName || "",
      companyName: userData?.metaDataValues
        ? getMetaDataValue(userData.metaDataValues, "companyName")
        : "",
      office: userData?.metaDataValues
        ? getMetaDataValue(userData.metaDataValues, "oda_barreau_code")
        : "",
      numCNBF: userData?.publicId || "",
      //in future the value will come form the backend for now it is hardcoded for eventual demo purpose
      countryCode: splitPhoneCode || "+33",
      phone: splitPhoneNumber || "",
      email: userData?.email || "",
      city: userData?.city || "",
      postCode: userData?.zipCode || "",
      address: userData?.adress || "",
      accountLimitWarning: false,
      actToBeExpired: false,
      signature: userData?.metaDataValues
        ? getMetaDataValue(userData.metaDataValues, "emailSignature")
        : "Bonne journée, votre avocat",
      greeting: userData?.metaDataValues
        ? getMetaDataValue(userData.metaDataValues, "greeting")
        : `Bien à vous, maître ${userData?.lastName}`,
      preferences: preferencesDropdownOptions,
    };
  };

  const ProfileSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatLabel(labels.compliantField, labels.email))
      .required(formatLabel(labels.requiredField, labels.email)),
    phone: Yup.string()
      .matches(
        frenchPhoneNumberRegex,
        formatLabel(labels.compliantField, labels.phone),
      )
      .required(formatLabel(labels.requiredField, labels.phone)),
    address: Yup.string()
      .required(formatLabel(labels.requiredField, labels.address))
      .matches(
        addressRegex,
        formatLabel(labels.compliantField, labels.address),
      ),
    city: Yup.string()
      .required(formatLabel(labels.requiredField, labels.city))
      .matches(cityName, formatLabel(labels.compliantField, labels.city)),
    postCode: Yup.string()
      .required(formatLabel(labels.requiredField, labels.postCode))
      .matches(
        postCodeRegex,
        formatLabel(labels.compliantField, labels.postCode),
      ),
  });

  const handleResetForm = (formik: any) => {
    setSelected(preferencesDropdownOptions);
    formik.handleReset();
    dispatch(setFormikData(null));
  };

  const shouldShowConfirmationModal = () => {
    // If there is no Formik data stored, we can navigate away
    if (!formikData) {
      return false;
    }

    // If there are no changes in the form, we can clear the stored data and navigate away
    if (formikData.initial === formikData.current) {
      dispatch(setFormikData(null));

      return false;
    }

    return true;
  };

  const handleQuitAction = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
    redirectTo: string,
    hasNativeRedirect: boolean = false,
  ) => {
    if (props.quitButtonWithoutModal) {
      navigate(backLocation);
      return;
    }
    // Check if conditions for showing the confirmation modal are met
    // and show it if needed
    const showConfirmationModal = shouldShowConfirmationModal();

    if (showConfirmationModal) {
      event.preventDefault();

      dispatch(setModalData({ redirectTo }));
      dispatch(showModal("cancelAct"));

      return;
    }

    // If there is no confirmation modal and the element is a button, navigate away
    if (!hasNativeRedirect) {
      navigate(redirectTo);
    }
  };

  return (
    <Layout sidebar={false}>
      <Formik
        initialValues={setInitialValues(userData)}
        validationSchema={ProfileSchema}
        validateOnBlur={true}
        enableReinitialize
        onSubmit={(values) => {
          const { phone, address, email, city, postCode, countryCode } = values;
          const updatedUser = {
            adress: address,
            zipCode: postCode,
            city,
            email,
            mainPhoneNumber: `${countryCode}${phone}`,
            greeting: values.greeting,
            emailSignature: values.signature,
            autoInsertSignature: useByDefault,
          };

          const updatedUserData = {
            ...userData,
            ...updatedUser,
          };

          let {
            metaDataValues,
          }: {
            metaDataValues: Record<string, any>[];
          } = updatedUserData;

          // Update "greeting" and "emailSignature" metadata values
          metaDataValues = [
            ...metaDataValues.filter(
              (item) => !["greeting", "emailSignature"].includes(item.code),
            ),
            { code: "greeting", value: values.greeting },
            { code: "emailSignature", value: values.signature },
          ];

          const storeUserData = {
            ...updatedUserData,
            metaDataValues,
          };

          setLoading(true);
          axios
            .put(`/api/v1/me`, updatedUser)
            .then(() => {
              if (!selected) {
                toast.success(labels.documentSuccesfullyUpdated, toastOptions);
                dispatch(setUser(storeUserData));
                setLoading(false);
                return;
              }

              const actTypes = selected.map(
                (preference: MultiselectOption) => preference.value,
              );

              setLoading(true);

              axios
                .put(`/api/v1/users/${userData.publicId}/preferences`, {
                  actTypes: actTypes,
                })
                .then((response) => {
                  dispatch(
                    setUser({
                      ...storeUserData,
                      actTypes: actTypes,
                    }),
                  );

                  setLoading(false);

                  if (response.status === 204) {
                    toast.success(
                      labels.documentSuccesfullyUpdated,
                      toastOptions,
                    );
                  }
                })
                .catch((error) => {
                  dispatch(setUser(storeUserData));
                  toast.error(
                    axiosErrorMessages[error.message],
                    toastOptionsError,
                  );
                });
            })
            .catch((error) => {
              setLoading(false);
              toast.error(axiosErrorMessages[error.message], toastOptionsError);
            });
          updateAccountAlertThreshold(
            balanceAmount,
            values.accountLimitWarning,
          );
        }}
        validate={(values) => {}}
      >
        {(formik) => (
          <Form>
            <div className="flex mx-auto justify-center">
              <div className="w-0 lg:w-[200px]"></div>
              <div className="mobile-screen lg:mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-6">
                  <div
                    id="1"
                    className="col-start-1 col-end-3 lg:col-start-1 lg:col-end-2 order-1 lg:mx-auto xl:w-[250px]"
                  >
                    <div className="lg:flex justify-between lg:mb-[33px] mt-[55px]">
                      <Link
                        to={"/"}
                        onClick={(event) => handleQuitAction(event, "/", true)}
                      >
                        <Icon
                          type="back-arrow"
                          className="flex w-[41px] h-[41px] mb-6 lg:ml-6 lg:mb-0"
                        />
                      </Link>
                      <div className="flex justify-end headline-xl w-[111px] lg:w-full">
                        <h1>{labels.myProfile}</h1>
                      </div>
                    </div>
                    <div className="hidden lg:flex flex-row-reverse mr-[15%] lg:mr-[5%]">
                      {avatar ? (
                        <img
                          className="w-[87px] h-[87px] rounded-[80.50px]"
                          srcSet={avatar}
                          alt="avatar"
                        />
                      ) : (
                        <Icon
                          type="avatar"
                          className="w-[87px] h-[87px] aspect-auto rounded-[80.50px]"
                        />
                      )}
                    </div>
                  </div>
                  <div
                    id="2"
                    className="lg:col-start-2 lg:col-span-4 items-center h-auto order-2 lg:mt-0 mx-auto"
                  >
                    <div className="col-start-1 col-span-7 md:col-start-2 md:col-span-4 items-center h-auto">
                      <GeneralInformation formik={formik} />
                      <Account formik={formik} />
                      <NotificationSetup formik={formik} />
                      <div className="headline-lg mr-auto ml-auto mt-8">
                        <section className="lg:w-[612px]">
                          <h2 className="mb-[24px]">{labels.preferences}</h2>
                          <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-[12px]">
                            <div className="flex md:flex-row md:w-[293px] mb-3">
                              <p className="flex justify-start body-lg font-bold">
                                {labels.preferencesTitleDropdown}
                              </p>
                            </div>
                            <div className="flex flex-row w-full justify-end">
                              <div className="w-full md:max-w-[293px] body-lg font-semibold profile-multiselect">
                                <Field
                                  type="hidden"
                                  name="preferences"
                                  id="preferences"
                                  value={selected}
                                />
                                <MultiSelect
                                  options={preferencesDropdownOptions}
                                  selected={selected}
                                  setSelected={setSelected}
                                  maxWidthOptionMenu="max-w-[293px]"
                                  allStatusOptions={labels.allActTypes}
                                  onChange={(options) =>
                                    formik.setFieldValue("preferences", options)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <p className="body-sm w-full lg:w-[293px] mt-[4px]">
                            {labels.preferencesInfoText}
                          </p>
                        </section>
                      </div>
                    </div>
                  </div>
                  <div
                    id="3"
                    className="col-start-1 col-span-1 lg:col-start-6 lg:col-span-2 w-[250px] order-3 mx-auto items-center text-center lg:items-start lg:text-left"
                  >
                    <div className="headline-md mt-[34px] lg:mt-[127px]">
                      <p className="pb-3">{labels.myHandwrittenSignature}</p>
                      <Signature />
                    </div>
                  </div>
                </div>
                <div className="md:flex flex-col justify-between w-full mx-auto md:w-[612px] mt-8 mb-28">
                  <div className="md:flex flex-row justify-between items-center">
                    <Button
                      type="reset"
                      onClick={() => handleResetForm(formik)}
                      className="flex items-center justify-center mx-auto md:mx-0"
                    >
                      <div className="w-full md:flex flex-">
                        <p className="flex justify-end body-md text-slate-500">
                          <Icon
                            type="refresh-profile"
                            className="w-[17.25px] h-[17px] ml-[1px] mr-[15px]"
                          />
                          {labels.resetToDefaultSettings}
                        </p>
                      </div>
                    </Button>
                    <div className="md:flex flex-row md:w-[293px] items-center justify-end mt-6 md:mt-0">
                      <Button
                        type="submit"
                        className="w-full md:w-auto px-4 py-2 body-md border btn btn-secondary-emphasize rounded-lg flex items-center justify-center"
                        disabled={loading}
                      >
                        {loading && <Spinner className="size-4 mr-4" />}
                        {labels.save}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default Profile;

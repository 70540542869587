import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@ui/Button";
import FieldDropdown from "@ui/FieldDropdown";
import Modal from "@ui/Modal";

import { facturationOptions, labels, wayOfPaymentOptions } from "@constants";

import {
  selectModalData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import { hideModal } from "@reducers/modalsSlice";

import { formatLabel } from "@utils";

const Payment = () => {
  const [facturation, setFacturation] = useState(facturationOptions[0].value);
  const dispatch = useDispatch();
  const data = useSelector(selectModalData);
  const initialValues = {
    billing: facturation,
    paymentMethod: "",
  };

  return (
    <Modal title={labels.payment} width={400}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          let updatedData = {
            ...data,
            ...values,
          };

          updatedData.payed = true;
          dispatch(setPageData(updatedData));
          dispatch(setModalData(null));
          dispatch(hideModal());
        }}
      >
        {(formik) => {
          return (
            <Form>
              <h3 className="mb-2 mt-5">{labels.actReference}</h3>
              <p className="mb-6 body-lg">{labels.activityTitle}</p>

              <div className="mb-6">
                <label htmlFor="billing" className="body-lg mb-[8px]">
                  {labels.billing}
                </label>
                <Field name="billing" type="hidden" />

                <FieldDropdown
                  arrowPosition="right"
                  options={facturationOptions}
                  initialValue={facturation}
                  fontSize="body-lg"
                  inputHeight="h-[38px]"
                  paddingLeft="pl-[10px]"
                  marginTop="mt-2"
                  onChange={(value) => {
                    setFacturation(value);
                    formik.setFieldValue("billing", value);
                  }}
                />
              </div>

              <div className="mb-6">
                <label className="body-lg mb-[8px]" htmlFor="paymentMethod">
                  {labels.wayOfPayment}
                </label>
                <Field name="paymentMethod" type="hidden" />
                <FieldDropdown
                  arrowPosition="right"
                  options={wayOfPaymentOptions}
                  initialValue={wayOfPaymentOptions[0].value}
                  fontSize="body-lg"
                  inputHeight="h-[38px]"
                  paddingLeft="pl-[10px]"
                  marginTop="mt-2"
                  onChange={(value) => {
                    formik.setFieldValue("paymentMethod", value);
                  }}
                />
              </div>

              <p className="body-sm text-ea-gray-300 mb-4">
                {formatLabel(
                  labels.invoiceOfPayment,
                  facturationOptions.find(
                    (option) => option.value === facturation,
                  )?.label || "",
                )}
              </p>

              <Button
                className="btn-primary px-3 py-2 rounded-[8px] body-md block mr-0 ml-auto"
                type="submit"
              >
                {formatLabel(labels.payAmount, data.amount)}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Payment;

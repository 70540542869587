import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@types";

const initialState = {
  modalData: null,
  pageData: null,
  formikData: null,
  actData: null,
  postState: false,
};

const dataTransferSlice = createSlice({
  name: "dataTransfer",
  initialState,
  reducers: {
    setModalData: (state, action) => {
      state.modalData = action.payload;
    },
    setPageData: (state, action) => {
      state.pageData = action.payload;
    },
    setFormikData: (state, action) => {
      state.formikData = action.payload;
    },
    setActData: (state, action) => {
      state.actData = action.payload;
    },
    setPostState: (state, action) => {
      state.postState = action.payload;
    },
  },
});

export const {
  setModalData,
  setPageData,
  setFormikData,
  setActData,
  setPostState,
} = dataTransferSlice.actions;

export const selectModalData = (state: RootState) =>
  state.dataTransfer.modalData;
export const selectPostState = (state: RootState) =>
  state.dataTransfer.postState;
export const selectPageData = (state: RootState) => state.dataTransfer.pageData;
export const selectFormikData = (state: RootState) =>
  state.dataTransfer.formikData;
export const selectActData = (state: RootState) => state.dataTransfer.actData;

export default dataTransferSlice.reducer;

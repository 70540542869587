import axios from "axios";
import { toast } from "react-toastify";

import { axiosErrorMessages, labels, toastOptionsError } from "@constants";

import { initEaf } from "@eaf";

const prepareAndFinalizeApi = (
  signbookId: string,
  token: Record<string, any>,
  signatureData: FormData,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  signingSuccessful: () => void,
) => {
  const certificate = token.certificate?.original;
  const stringifiedCertificate = JSON.stringify({ certificate });
  const certificateBlob = new Blob([stringifiedCertificate], {
    type: "application/json",
  });

  signatureData.append("prepareTokenRequest", certificateBlob);

  // Call prepare action API
  return axios
    .post(`/api/v1/signbooks/${signbookId}/preparation`, signatureData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      const documentsToFinalize: Record<string, string>[] = [];
      const { prepareDocResults, joseSignature } = response.data;
      const eaf = initEaf();

      eaf
        .onSignSuccess((result: Record<string, string>) => {
          const { encryptedData } = result;

          prepareDocResults.forEach(
            (preparedDocument: Record<string, string>, index: number) => {
              const { documentUid, preparedPKCS7 } = preparedDocument;
              const p7Signature = encryptedData[index];

              documentsToFinalize.push({
                documentUid,
                preparedPKCS7,
                p7Signature,
              });
            },
          );

          return axios
            .post(`/api/v1/signbooks/${signbookId}/finalize`, {
              documentsFinalizeSign: documentsToFinalize,
            })
            .then(() => {
              setLoading(false);
              signingSuccessful();
            })
            .catch((error) => {
              toast.error(error.message, toastOptionsError);

              setLoading(false);
            });
        })
        .onSignError(() => {
          toast.error(labels.signingError, toastOptionsError);

          setLoading(false);
        })
        .onSignCancel(() => {
          toast.error(labels.signingCancelled, toastOptionsError);

          setLoading(false);
        });

      eaf.sign(joseSignature, token);
    })
    .catch((error) => {
      const errorMessage = axiosErrorMessages[error.message] || error.message;

      toast.error(errorMessage, toastOptionsError);

      setLoading(false);
    });
};

export { prepareAndFinalizeApi };

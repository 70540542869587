import axios from "axios";
import { Route, Routes } from "react-router-dom";

import Signing from "@pages/otp/Signing";

const OTP = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BE_BASEURL;

  return (
    <>
      <Routes>
        <Route path="/">
          <Route path=":type/signature" element={<Signing />} />
        </Route>
      </Routes>
    </>
  );
};

export default OTP;

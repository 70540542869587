import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Layout from "@components/layout/Layout";

import Button from "@ui/Button";
import Icon from "@ui/Icon";
import Notifications from "@ui/Notifications";
import Overlay from "@ui/Overlay";
import ProgressBar from "@ui/ProgressBar";
import Spinner from "@ui/Spinner";

import {
  DAY_IN_MILLISECONDS,
  actTypeNameMap,
  axiosErrorMessages,
  labels,
  toastOptionsError,
} from "@constants";

import {
  selectPageData,
  setModalData,
  setPageData,
} from "@reducers/dataTransferSlice";
import {
  selectErrors,
  selectRequiredActionsCount,
  selectUser,
} from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";
import { selectOverlay, showOverlay } from "@reducers/overlaySlice";

import { ButtonDropdownOptions } from "@types";

import {
  addQueryParams,
  downloadPDF,
  formatLabel,
  formatTimestampForRequest,
  getActLink,
} from "@utils";

const Home = () => {
  // Track loading of all the AJAX requests
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [loadingExpiring, setLoadingExpiring] = useState(true);
  const [loadingArchived, setLoadingArchived] = useState(true);
  const [duplicating, setDuplicating] = useState<string | null>(null);

  // Store the results of the AJAX requests
  const [recentActivities, setRecentActivities] = useState<
    Record<string, any>[]
  >([]);
  const [expiringSoonCount, setExpiringSoonCount] = useState<number>(0);
  const [archivedCount, setArchivedCount] = useState<number>(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const errors = useSelector(selectErrors);
  const data = useSelector(selectPageData);
  const requiredActionsCount = useSelector(selectRequiredActionsCount);
  const loadingRequiredActions = requiredActionsCount.totalCount === null;

  // Default params for expiring and archived acts
  const defaultParams = {
    companyCode: "FR-CNB",
    userId: user.id,
    ascending: false,
    columnToOrder: "CREATIONDATE",
    page: 1,
  };

  const fetchRecentActivities = () => {
    setLoadingActivities(true);

    axios
      .post(`/api/v1/signbooks/search?page=0&size=3`, defaultParams)
      .then((response) => {
        setRecentActivities(response.data.results);
        getProgress(response.data.results);

        setLoadingActivities(false);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
        setLoadingActivities(false);
      });
  };

  useEffect(() => {
    if (data?.actCancelled) {
      fetchRecentActivities();
      dispatch(setPageData(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (errors?.user) {
      setLoadingExpiring(false);
      setLoadingArchived(false);
      setLoadingActivities(false);
      return;
    }

    if ("" === user.id) {
      return;
    }

    // Get expiring acts
    const dateToday = formatTimestampForRequest(new Date().getTime());
    const dateInOneWeek = formatTimestampForRequest(
      new Date().getTime() + 7 * DAY_IN_MILLISECONDS,
    );

    axios
      .post(`/api/v1/signbooks/search`, {
        ...defaultParams,
        expirationDateMin: dateToday,
        expirationDateMax: dateInOneWeek,
        steps: [
          "created",
          "shared",
          "signing",
          "signedByPlatform",
          "signedByLawyer",
          "signedByParties",
          "waitingForPayment",
          "send",
          "rejected",
        ],
      })
      .then((response) => {
        setExpiringSoonCount(response.data.totalCount);
        setLoadingExpiring(false);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
        setLoadingExpiring(false);
      });

    // Get archived acts
    axios
      .post(`/api/v1/signbooks/search`, {
        ...defaultParams,
        steps: ["closed"],
      })
      .then((response) => {
        setArchivedCount(response.data.totalCount);
        setLoadingArchived(false);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
        setLoadingArchived(false);
      });

    fetchRecentActivities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, errors]);

  const getStatus = (publicId: string) => {
    axios
      .get(`/api/v1/signbooks/${publicId}/status`)
      .then((response) => {
        if (
          response.data.message === labels.noDataFoundMessage ||
          !response.data.message
        ) {
          return;
        }
        setRecentActivities((prev) => {
          return prev.map((act) => {
            if (act.publicId === publicId) {
              return {
                ...act,
                statusProgress: response.data,
              };
            }

            return act;
          });
        });
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
        setLoadingActivities(false);
      });
  };

  const getProgress = async (acts: Record<string, any>[]) => {
    for (const act of acts) {
      if (act.signingStatus === "SIGNING" && act.status !== "expired") {
        await getStatus(act.publicId);
      }
    }
  };

  const getButton = (status: string | undefined) => {
    let button: string;

    switch (status) {
      case "created":
        button = "view";
        break;
      case "signing":
        button = "relaunch";
        break;
      case "waitingForPayment":
        button = "pay";
        break;
      case "cancelled":
      case "expired":
        button = "duplicate";
        break;
      case "closed":
        button = "download";
        break;
      default:
        button = "download";
    }

    return button;
  };

  const overlay = "drawer" === useSelector(selectOverlay);
  const drawerClass = overlay ? "bottom-0" : "bottom-[-100%]";

  const newActButtons: Record<string, any> = {
    econvention: (
      <Link
        to="/acts/convention/new"
        className="btn-secondary flex items-center py-2 px-4"
        data-testid="new-convention"
        key="new-convention"
      >
        <Icon type="plus" className="w-6 h-6" />
        <span className="ml-2">{labels.newConvention}</span>
      </Link>
    ),
    dcm: (
      <Link
        to="/acts/divorce/new"
        className="btn-secondary flex items-center py-2 px-4 border"
        data-testid="new-divorce-act"
        key="new-divorce-act"
      >
        <Icon type="plus" className="w-6 h-6" />
        <span className="ml-2">{labels.newDivorce}</span>
      </Link>
    ),
    natif: (
      <Link
        to="/acts/birth/new"
        className="btn-secondary flex items-center py-2 px-4 border"
        data-testid="new-birth-act"
        key="new-birth-act"
      >
        <Icon type="plus" className="w-6 h-6" />
        <span className="ml-2">{labels.buttonLawyer}</span>
      </Link>
    ),
    numerise: (
      <Link
        to="/acts/digital/new"
        className="btn-secondary flex items-center py-2 px-4"
        data-testid="new-digital-act"
        key="new-digital-act"
      >
        <Icon type="plus" className="w-6 h-6" />
        <span className="ml-2">{labels.newActDigital}</span>
      </Link>
    ),
  };

  return (
    <Layout sidebar={true}>
      <div className="pb-2 bg-white">
        <div className="bg-ea-darkblue pb-[200px] md:py-[110px] relative">
          <Notifications className="absolute top-[76px] md:top-[7px] left-1/2 -translate-x-2/4 w-[90%] md:w-[636px] xl:w-[982px]" />

          <div className="relative mx-[24px] md:mx-[5%] lg:mx-[9%] 2xl:mx-[10%]">
            <h1
              className="text-white headline-l md:headline-xl py-[19px] pr-[30%] md:pr-0"
              data-testid="welcome-message"
            >
              {formatLabel(
                labels.welcomeMessage,
                `${labels.master} ${user.lastName}`,
              )}
            </h1>
          </div>
        </div>
        <div className="xl:min-w-[712px] mx-[24px] md:mx-[10%] xl:mx-[20%] -mt-[150px] md:-mt-[74px] relative">
          <div className="md:flex bg-white rounded-t-[8px] justify-center overflow-hidden">
            <Link
              to="/required-actions"
              className="md:w-[calc(100%/3)] hover:bg-ea-lightblue"
              data-testid="required-actions"
            >
              <div className="overview-button">
                <div className="text-center text-[36px] md:text-[48px] leading-none md:leading-normal relative">
                  {loadingRequiredActions ? (
                    <Spinner className="size-10 my-[16px]" dark={true} />
                  ) : (
                    <>{errors?.user ? "-" : requiredActionsCount.totalCount}</>
                  )}
                  {Number(requiredActionsCount.totalCount) > 0 && (
                    <span
                      role="presentation"
                      className="absolute bg-ea-red w-[12px] h-[12px] top-[3px] right-[-14px] rounded-[50%]"
                    ></span>
                  )}
                </div>
                <div className="body-sm md:font-medium md:text-sm text-center pt-4 px-2 md:px-8">
                  <span className="relative">{labels.actionsRequired}</span>
                </div>
                <div className="md:hidden text-ea-gray-200 absolute bottom-5 right-[24px]">
                  <Icon type="arrow-right-pagination" className="size-[20px]" />
                </div>
              </div>
            </Link>
            <Link
              to="/acts/expiring"
              className="md:w-[calc(100%/3)] hover:bg-ea-lightblue"
              data-testid="expiring-soon"
            >
              <div className="overview-button">
                <div className="text-center text-[36px] md:text-[48px] leading-none md:leading-normal relative">
                  {loadingExpiring ? (
                    <Spinner className="size-10 my-[16px]" dark={true} />
                  ) : (
                    <>{errors?.user ? "-" : expiringSoonCount}</>
                  )}
                </div>
                <div className="body-sm md:font-medium md:text-sm text-center pt-4 px-2 md:px-8">
                  <span className="relative">{labels.actionsExpiring}</span>
                </div>
                <div className="md:hidden text-ea-gray-200 absolute bottom-5 right-[24px]">
                  <Icon type="arrow-right-pagination" className="size-[20px]" />
                </div>
              </div>
            </Link>
            <Link
              to="/acts/closed"
              className="md:w-[calc(100%/3)] hover:bg-ea-lightblue"
              data-testid="archived-acts"
            >
              <div className="overview-button">
                <div className="text-center text-[36px] md:text-[48px] leading-none md:leading-normal relative">
                  {loadingArchived ? (
                    <Spinner className="size-10 my-[16px]" dark={true} />
                  ) : (
                    <>{errors?.user ? "-" : archivedCount}</>
                  )}
                </div>
                <div className="body-sm md:font-medium md:text-sm text-center pt-4 px-2 md:px-8">
                  <span className="relative">{labels.actionsArchived}</span>
                </div>
                <div className="md:hidden text-ea-gray-200 absolute bottom-5 right-[24px]">
                  <Icon type="arrow-right-pagination" className="size-[20px]" />
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div
          className="new-act-buttons hidden md:flex justify-center mt-11 text-sm font-medium text-black whitespace-nowrap"
          data-testid="quick-act-creation-buttons"
        >
          {Object.keys(newActButtons).map((actType) => {
            if (!user.actTypes) {
              return null;
            }

            if (user.actTypes.includes(actType)) {
              return newActButtons[actType];
            }

            return null;
          })}
        </div>

        <div className="mb-0 mt-11 md:my-11 mx-[24px] md:mx-[5%] lg:mx-[10%]">
          <div
            className="mb-2 text-xl font-bold text-black"
            data-testid="recent-activity-title"
          >
            {labels.recentActivities}
          </div>
          <div className="flex w-full">
            {errors?.user ? (
              <div className="text-center mt-10 mb-6 flex items-center flex-col w-full">
                <Icon type="archive-error" className="size-10" />
                <p className="text-sm mt-4">
                  {labels.errorLoadingRecentActivities}
                </p>
              </div>
            ) : (
              <>
                {!loadingActivities ? (
                  <div className="w-full xl:mr-[70px]">
                    {recentActivities.map((activity, index) => {
                      let activityClassName;

                      switch (index) {
                        case 0:
                          activityClassName =
                            "flex justify-between items-center py-5 px-4 md:px-8 mb-2.5 bg-white rounded box-shadow";
                          break;
                        case 1:
                          activityClassName =
                            "hidden md:flex justify-between items-center py-5 px-8 mb-2.5 bg-white rounded box-shadow";
                          break;
                        case 2:
                          activityClassName =
                            "hidden xl:flex justify-between items-center py-5 px-8 mb-2.5 bg-white rounded box-shadow";
                          break;
                        default:
                          activityClassName =
                            "flex justify-between items-center py-5 px-4 md:px-8 mb-2.5 bg-white rounded box-shadow";
                      }

                      const statusLabel = () => {
                        if (activity.status === "expired") {
                          return labels.expired;
                        }

                        switch (activity.signingStatus) {
                          case "BEFORE_SIGNING":
                            if (activity.status === "created") {
                              return labels.created;
                            }
                            if (activity.status === "shared") {
                              return labels.sharing;
                            }
                            if (activity.status === "cancelled") {
                              return labels.cancelled;
                            }
                            break;
                          case "AFTER_SIGNING":
                            if (activity.status === "waitingForPayment") {
                              return labels.waitingForPayment;
                            }
                            if (activity.status === "closed") {
                              return labels.closedAndArchived;
                            }
                            if (activity.status === "cancelled") {
                              return labels.cancelled;
                            }
                            break;
                          default:
                            return labels.signing || "";
                        }
                      };

                      const {
                        publicId,
                        status,
                        signingStatus,
                        type: actType,
                        businessSubType,
                        invoiceId,
                      } = activity;

                      const actLink = getActLink({
                        type: actType,
                        status,
                        signingStatus,
                        publicId,
                        subType: businessSubType,
                      });

                      const urlActType = actTypeNameMap[actType];

                      const actionsMap: Record<string, ButtonDropdownOptions> =
                        {
                          download: {
                            label: labels.downloadEvidence,
                            icon: "download",
                            onClick: () => {
                              if (invoiceId) {
                                downloadPDF([invoiceId]);
                              }
                            },
                          },
                          pay: {
                            label: labels.payAct,
                            onClick: () => {},
                          },
                          relaunch: {
                            label: labels.relaunch,
                            link: addQueryParams(
                              `/acts/${urlActType}/act-details/${publicId}`,
                              {
                                returnPath: "/",
                              },
                            ),
                            icon: "paper-plane",
                          },
                          view: {
                            label: labels.openAct,
                            link: actLink,
                          },
                          history: {
                            label: labels.viewHistory,
                            link: addQueryParams(
                              `/acts/${urlActType}/act-details/${publicId}`,
                              {
                                returnPath: "/",
                              },
                              "act-history",
                            ),
                          },
                          duplicate: {
                            label: labels.duplicateDocument,
                            onClick: () => {
                              setDuplicating(publicId);

                              axios
                                .post(`/api/v1/signbooks/${publicId}/duplicate`)
                                .then(() => {
                                  setDuplicating(null);

                                  fetchRecentActivities();
                                })
                                .catch((error) => {
                                  setDuplicating(null);

                                  toast.error(
                                    axiosErrorMessages[error.message],
                                    toastOptionsError,
                                  );
                                });
                            },
                            icon: "duplicate",
                          },
                          cancel: {
                            label: labels.cancelAct,
                            onClick: (_: null) => {
                              dispatch(
                                setModalData({
                                  title: labels.cancelationOfAct,
                                  ctaButtonLabel: labels.cancelTheAct,
                                  publicId: publicId,
                                  skipSuccessToast: true,
                                }),
                              );
                              dispatch(showModal("confirm"));
                            },
                          },
                          downloadInvoice: {
                            label: labels.downloadInvoice,
                            onClick: () => {},
                          },
                        };

                      const buttonType = getButton(status);
                      const buttonAction = actionsMap[buttonType];
                      const buttonClass =
                        "btn-secondary flex border items-center rounded-lg pl-3 pr-3 py-2 body-lg";

                      return (
                        <Link
                          to={actLink}
                          className={activityClassName}
                          data-testid="act-details-link"
                          key={index}
                        >
                          <div className="body-lg md:text-base md:font-medium text-black whitespace-nowrap w-[143px] truncate">
                            {activity.name}
                          </div>
                          {activity.statusProgress ? (
                            <>
                              <div className="hidden md:block w-[107px]">
                                <ProgressBar
                                  currentStep={activity.statusProgress.progress}
                                  maxSteps={100}
                                />
                              </div>
                              <div className="hidden md:block text-xs font-medium text-black whitespace-nowrap">
                                {activity.statusProgress.message}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="hidden md:block w-[175px]">
                                {statusLabel()}
                              </div>
                            </>
                          )}
                          <Button
                            type="button"
                            className={buttonClass}
                            disabled={!!duplicating}
                            onClick={(event) => {
                              event.preventDefault();

                              if (buttonAction.link) {
                                navigate(buttonAction.link);
                                return;
                              }

                              if (buttonAction.onClick) {
                                buttonAction.onClick();
                              }
                            }}
                          >
                            {!duplicating && buttonAction.icon && (
                              <Icon
                                type={buttonAction.icon}
                                className="w-[13px] h-[13px] mr-[5px]"
                              />
                            )}
                            {duplicating === publicId && (
                              <Spinner className="size-4 mr-4" />
                            )}
                            {buttonAction.label}
                          </Button>
                        </Link>
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-full flex items-center justify-center">
                    <Spinner className="size-10" dark={true} />
                  </div>
                )}
              </>
            )}

            <div className="hidden xl:flex flex-col items-center px-6 py-7 text-black whitespace-nowrap bg-white max-w-[200px] box-shadow rounded mb-2.5">
              <div
                className="text-base font-bold"
                data-testid="need-help-title"
              >
                {labels.needHelp}
              </div>
              <Icon type="operator" className="w-[76px] text-ea-red" />
              <div className="flex items-center mt-7">
                <a
                  href="https://assistance.cnb.avocat.fr/hc/fr/sections/360001418320-%20e-acte%20-%20E-acte%20-d-Avocat-"
                  className="flex px-1 text-sm font-medium"
                  data-testid="contact-support-link"
                >
                  {labels.contactSupport}
                  <Icon type="arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="md:hidden mx-[24px] my-[24px]">
          <Button
            className="btn-primary flex items-center justify-center py-2 px-4 rounded-lg w-full"
            onClick={() => {
              dispatch(showOverlay("drawer"));
            }}
          >
            <Icon type="plus" className="w-6 h-6" />
            <span className="ml-2">{labels.newDocument}</span>
          </Button>
        </div>

        <Overlay name="drawer" />
        <div
          className={`${drawerClass} fixed left-0 z-[200] w-full transition-[bottom] duration-500 md:hidden`}
        >
          <div className="mx-[20px] mb-[10px] p-[20px] bg-white rounded box-shadow">
            <ul className="[&>li+li]:pt-[24px]">
              <li className="flex items-center">
                <Icon type="plus" className="size-4 mr-1" />
                <Link to="/acts/convention/new">{labels.newConvention}</Link>
              </li>
              <li className="flex items-center">
                <Icon type="plus" className="size-4 mr-1" />
                <Link to="/acts/divorce/new">{labels.newDivorce}</Link>
              </li>
              <li className="flex items-center">
                <Icon type="plus" className="size-4 mr-1" />
                <Link to="/acts/birth/new">{labels.buttonLawyer}</Link>
              </li>
              <li className="flex items-center">
                <Icon type="plus" className="size-4 mr-1" />
                <Link to="/acts/digital/new">{labels.newActDigital}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

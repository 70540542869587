import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";

import Button from "@ui/Button";
import CustomDatePickerHeader from "@ui/DatepickerCustomHeader";
import Icon from "@ui/Icon";
import Modal from "@ui/Modal";

import { labels } from "@constants";

import { showModal } from "@reducers/modalsSlice";

import { areApart, formatLabel } from "@utils";

const DivorceVerification = () => {
  const [partyOneDate, setPartyOneDate] = useState<Date | null>(null);
  const [partyTwoDate, setPartyTwoDate] = useState<Date | null>(null);
  const [datesAreValidated, setDatesAreValidated] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!partyOneDate || !partyTwoDate) {
      setDatesAreValidated(false);
      return;
    }

    if (!areApart(partyOneDate, partyTwoDate, 15)) {
      setDatesAreValidated(false);
      return;
    }

    setDatesAreValidated(true);
  }, [partyOneDate, partyTwoDate]);

  return (
    <Modal title={labels.verifyBeforeLaunch} width={590}>
      <Formik
        validateOnMount
        initialValues={{}}
        onSubmit={() => {
          dispatch(showModal("divorceConfirmation"));
        }}
      >
        {() => (
          <Form>
            <div className="text-sm mt-4">
              <ul className="pl-3 list-disc marker:text-[5px]">
                <li>{labels.registeredLetterNotice}</li>
                <li>{labels.receiptExpirationNotice}</li>
              </ul>

              <p className="my-4">{labels.receiptDeadlineNotice}</p>

              <div className="my-8 space-y-4">
                <div className="flex justify-between items-center">
                  {`${formatLabel(labels.partyX, "1")} - SP TEST SP`}
                  <div className="relative">
                    <Icon
                      type="calendar"
                      color="#000"
                      className="w-[18px] h-[20px] mr-[19px] absolute top-[50%] left-[16px] transform -translate-y-1/2 z-10"
                    />

                    <DatePicker
                      renderCustomHeader={(props) => (
                        <CustomDatePickerHeader {...props} />
                      )}
                      className="font-medium text-black pl-[40px] py-2 pr-16 bg-white rounded border border-solid border-ea-gray-100 max-md:pr-5"
                      selected={partyOneDate}
                      onChange={(date) => {
                        setPartyOneDate(date);
                      }}
                      dateFormat="dd/MM/yyyy"
                      placeholderText={labels.receiptDate}
                    />
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  {`${formatLabel(labels.partyX, "2")} - TESTE TESTE SP`}
                  <div className="relative">
                    <Icon
                      type="calendar"
                      color="#000"
                      className="w-[18px] h-[20px] mr-[19px] absolute top-[50%] left-[16px] transform -translate-y-1/2 z-10"
                    />

                    <DatePicker
                      renderCustomHeader={(props) => (
                        <CustomDatePickerHeader {...props} />
                      )}
                      className="font-medium text-black pl-[40px] py-2 pr-16 bg-white rounded border border-solid border-ea-gray-100 max-md:pr-5"
                      selected={partyTwoDate}
                      onChange={(date) => {
                        setPartyTwoDate(date);
                      }}
                      dateFormat="dd/MM/yyyy"
                      placeholderText={labels.receiptDate}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  className="btn-base btn-primary"
                  disabled={!datesAreValidated}
                >
                  {labels.checkDelay}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DivorceVerification;

import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Button from "@ui/Button";
import CustomDatePickerHeader from "@ui/DatepickerCustomHeader";
import FieldDropdown from "@ui/FieldDropdown";
import FileUpload from "@ui/FileUpload";
import Icon from "@ui/Icon";
import Loader from "@ui/Loader";
import Modal from "@ui/Modal";
import PhoneField from "@ui/PhoneField";

import {
  actTypeNameMap,
  axiosErrorMessages,
  countryPhoneCodes,
  labels,
  toastOptionsError,
} from "@constants";

import { selectModalData, setPostState } from "@reducers/dataTransferSlice";
import { selectUser } from "@reducers/metadataSlice";
import { hideModal } from "@reducers/modalsSlice";

import { Document } from "@types";

import {
  addQueryParams,
  downloadFile,
  downloadPDF,
  formatLabel,
  getMimeType,
  isPastDate,
  validateEmail,
  validatePhone,
  validateSiren,
} from "@utils";
import {
  deletePartyIdentificationDocumentAPi,
  downloadPartyIdentificationDocumentAPi,
  partyIdentificationDocumentAPi,
  partyInformationApi,
  partySignBooksInformationApi,
} from "@utils/api/partyInformationApi";
import { nameRegex } from "@utils/regex";

const SealParty = () => {
  const data = useSelector(selectModalData);

  // The following code is used to determine the publicId of the act, depending on how is the route constructed
  const publicId = data.actId;

  const [partyData, setPartyData] = useState<any>({});
  const [initializing, setInitializing] = useState(true);
  const [actTitle, setActTitle] = useState<string>("");
  const [actType, setActType] = useState<string>("");

  const signatoryCode = data?.id;
  const dispatch = useDispatch();
  const location = useLocation();
  const [type, setType] = useState(data?.type || "person");
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [files, setFiles] = useState<Document[] | Record<string, string>[]>(
    data.IDdocument || [],
  );
  const [deletingIndices, setDeletingIndices] = useState<number[]>([]);
  const [numberFilesUploaded, setNumberFilesUploaded] = useState(1);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!data) {
      return;
    }

    setLoading(true);
    const fetchPartyInformation = async () => {
      const response = await partyInformationApi(data?.id);

      if (!response) {
        return;
      }
      setPartyData(response);
      setLoading(false);
      setInitializing(false);
    };
    fetchPartyInformation();
  }, [data.publicId, data, data.id, updated]);

  useEffect(() => {
    if (!publicId) {
      return;
    }
    setLoading(true);
    const fetchSignBooksInformation = async () => {
      const response = await partySignBooksInformationApi(publicId);

      if (!response) {
        return;
      }
      setActTitle(response.name);
      setActType(actTypeNameMap[response.type]);
      setLoading(false);
    };
    fetchSignBooksInformation();
  }, [publicId]);

  useEffect(() => {
    setLoading(true);

    if (!publicId) {
      return;
    }

    const fetchPartyIdentificationDocument = async () => {
      const response = await partyIdentificationDocumentAPi(
        publicId,
        signatoryCode,
      );
      if (!response) {
        return;
      }
      const documents = response;
      if (documents?.length > 0) {
        const newDocuments = documents.map((document: Document) => {
          return {
            id: document.documentUid,
            name: document.documentName?.split("/")[1] || document.documentName,
            size: document.fileSize,
          };
        });

        setFiles(newDocuments);
      }
      setLoading(false);
      setInitializing(false);
    };
    fetchPartyIdentificationDocument();
  }, [signatoryCode, publicId, type]);

  const storeFiles = (
    filesToStore: Document[] | Record<string, string>[],
    existingFiles: Document[] | Record<string, string>[] = files,
  ) => {
    const newFiles = [...existingFiles, ...filesToStore] as Document[];

    setFiles(newFiles);
  };

  const emptyModalFields = Object.keys(partyData).filter(
    (key) => key !== "phoneCode",
  );
  const editMode = 0 !== emptyModalFields.length;

  const createAndDownloadFile = (file: Document | Record<string, string>) => {
    const fileName = file.name;
    const documentId = file.id;
    const fileExtension = fileName?.split(".")?.pop()?.toLowerCase();

    downloadPartyIdentificationDocumentAPi(publicId, documentId).then(
      (response) => {
        // Determine the MIME type based on the file extension
        if (fileExtension) {
          const mimeType = getMimeType(fileExtension);

          if (mimeType) {
            // Create a Blob from the response data
            const fileBlob = new Blob([response], { type: mimeType });

            if (mimeType === "application/pdf") {
              const fileUrl = URL.createObjectURL(fileBlob);
              downloadPDF([fileUrl], fileName);
              return;
            }

            downloadFile(fileBlob, fileName);
          }
        }
      },
    );
  };

  const fieldClass =
    "body-lg border border-[#B4B4B4] py-[4px] px-[16px] rounded-[4px] w-full";
  const fieldClassDisabled = fieldClass.replace(
    "border-[#B4B4B4]",
    "border-[#F5F5F5] bg-white",
  );
  const iconClass =
    "absolute top-1/2 -translate-y-2/4 right-[16px] size-[14px]";

  // Map of field names to their corresponding labels.
  const labelsMap: Record<string, Record<string, string>> = {
    TYPE_NATURAL: {
      lastName: labels.surname,
      firstName: labels.name,
      birthDate: labels.birthDate,
      email: labels.email,
      phone: labels.phone,
    },
    TYPE_LEGAL: {
      sirenNumber: labels.sirenNumber,
      socialReason: labels.socialReason,
      domiciliation: labels.domiciliation,
      firstName: labels.legalName,
      lastName: labels.legalSurname,
      email: labels.email,
      phone: labels.phone,
    },
  };

  // Validation schemas for the form.
  const validationSchemaParty = Yup.object().shape({
    firstName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.name))
      .required(formatLabel(labels.requiredField, labels.name))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.name)),
    lastName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.surname))
      .required(formatLabel(labels.requiredField, labels.surname))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.surname)),
    birthDate: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.birthDate))
      .required(formatLabel(labels.requiredField, labels.birthDate))
      .test(
        "is-past-date",
        labels.birthDateMustBeBeforeCurrentDate,
        isPastDate,
      ),
    phoneCode: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.phoneCode))
      .required(formatLabel(labels.requiredField, labels.phoneCode)),
    IDdocument: Yup.array().required(
      formatLabel(labels.requiredField, labels.addNewID),
    ),
  });

  const validationSchemaCompany = Yup.object().shape({
    phoneCode: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.phoneCode))
      .required(formatLabel(labels.requiredField, labels.phoneCode)),
    socialReason: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.socialReason))
      .required(formatLabel(labels.requiredField, labels.socialReason)),
    firstName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.legalName))
      .required(formatLabel(labels.requiredField, labels.legalName))
      .matches(nameRegex, formatLabel(labels.compliantField, labels.legalName)),
    lastName: Yup.string()
      .trim(formatLabel(labels.requiredField, labels.legalSurname))
      .required(formatLabel(labels.requiredField, labels.legalSurname))
      .matches(
        nameRegex,
        formatLabel(labels.compliantField, labels.legalSurname),
      ),
  });

  const getValidationSchema = () => {
    return "TYPE_NATURAL" === type
      ? validationSchemaParty
      : validationSchemaCompany;
  };

  // Whether the entity is sealed or not
  const isSealed: boolean = false;

  // List of read-only fields if the entity is sealed.
  const readOnlyFieldsIfSealed =
    "TYPE_NATURAL" === type
      ? ["lastName", "firstName", "birthDate"]
      : ["sirenNumber", "socialReason", "firstName", "lastName"];

  // Values that we can't edit in the modal if sealed for both physical and company.
  // const formattedBirthDate = new Date(data.birthDate);

  // Function to format the date before sending to BE or display to be in 'DD/MM/YYYY' format
  const formatDateBirth = (dateStr: string) => {
    if (dateStr) {
      if (dateStr.match(/^\d{4}\/\d{2}\/\d{2}$/)) {
        const [year, month, day] = dateStr.split("/");
        return `${day}/${month}/${year}`;
      } else {
        // If already in 'DD/MM/YYYY' format or invalid format, return as-is
        return dateStr;
      }
    }
    return;
  };

  const deleteUploadedFile = (
    documentId: string,
    index: number,
    formik: any,
  ) => {
    setLoading(true);
    setDeletingIndices((prev) => [...prev, index]);
    deletePartyIdentificationDocumentAPi(publicId, signatoryCode, documentId)
      .then(() => {
        const newFiles = (files as Document[]).filter((_, i) => i !== index);
        setFiles(newFiles);

        if (newFiles.length === 0) {
          formik.setFieldValue("IDdocument", []);
        }

        setDeletingIndices((prev) => prev.filter((i) => i !== index));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setDeletingIndices((prev) => prev.filter((i) => i !== index));
        // Error is already handled inside the deleteIdentificationDocument function
      });
  };

  const splitPhoneNumber = partyData.mobileNumber?.slice(-9);
  const splitPhoneCode = partyData.mobileNumber?.slice(0, -9);

  const readOnlyValuesIfSealed = {
    lastName: partyData.lastName || "",
    firstName: partyData.firstName || "",
    birthDate: formatDateBirth(partyData.birthDate),
    sirenNumber: partyData.siren || "",
    socialReason: partyData.companyName || "",
  };

  // Values that we can edit in the modal.
  const editableValues = {
    email: partyData.email || "",
    phone: splitPhoneNumber || "",
    phoneCode: splitPhoneCode || "",
    IDdocument: files || "",
  };

  // Combine both values for initializing Formik.
  const initialValues = { ...readOnlyValuesIfSealed, ...editableValues };

  const titleLabel = actType === "convention" ? labels.client : labels.party;

  const dirtyValues = (values: any, initialValues: any) => {
    const data = { ...values };
    const keyValues = Object.keys(data);

    const dirtyValues = keyValues.filter(
      (keyValue) => data[keyValue] !== initialValues[keyValue],
    );

    keyValues.forEach((key) => {
      if (!dirtyValues.includes(key)) delete data[key];
    });

    return Object.keys(data).length > 0;
  };

  return (
    <Modal
      title={titleLabel}
      description={labels.identityOfPartyIsSealing}
      width={377}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getValidationSchema()}
        onSubmit={(values) => {
          const isDirty = dirtyValues(values, initialValues);

          const updatedPartyData = {
            ...data,
            ...values,
            type: type,
          };

          if (!editMode) {
            updatedPartyData.icon =
              type === "person" ? "profile-male" : "apartment";
          }

          updatedPartyData.IDdocument =
            0 !== files.length
              ? files.map((file) => {
                  const { name } = file;

                  return {
                    name,
                  };
                })
              : [];

          if (isDirty) {
            axios
              .put(`/api/v1/contacts/${signatoryCode}`, {
                publicId: signatoryCode,
                firstName: updatedPartyData.firstName,
                lastName: updatedPartyData.lastName,
                siren: updatedPartyData.sirenNumber,
                companyName: updatedPartyData.socialReason,
                birthDate: formatDateBirth(updatedPartyData.birthDate),
                email: updatedPartyData.email,
                mobileNumber: `${updatedPartyData.phoneCode}${updatedPartyData.phone}`,
                personType:
                  updatedPartyData.type === "TYPE_NATURAL"
                    ? "person"
                    : "company",
              })
              .then((response) => {
                if (response.status === 204) {
                  setUpdated(true);
                }
              })
              .catch((error) => {
                toast.error(
                  axiosErrorMessages[error.message],
                  toastOptionsError,
                );
              });
          } else {
            axios
              .post(
                `/api/v1/signbooks/${publicId}/signatories/${signatoryCode}/sealing`,
              )
              .then((response) => {
                if (response.status === 204) {
                  dispatch(hideModal());
                  dispatch(setPostState(true));
                }
              })
              .catch((error) => {
                toast.error(
                  axiosErrorMessages[error.message],
                  toastOptionsError,
                );
              });
          }
        }}
      >
        {(formik) => {
          const fieldClassFinal = isSealed ? fieldClassDisabled : fieldClass;
          return (
            <Form>
              <div
                className={`scrollbar-outside max-h-[660px] my-8 "overflow-y-auto"`}
              >
                {!initializing ? (
                  <>
                    <div className="party-type-dropdown relative">
                      <Field name="type" type="hidden" />
                      <FieldDropdown
                        arrowPosition="right"
                        initialValue={type}
                        options={[
                          {
                            label: labels.physicalPerson,
                            value: "TYPE_NATURAL",
                          },
                          { label: labels.company, value: "TYPE_LEGAL" },
                        ]}
                        onChange={(value) => {
                          setType(value);
                        }}
                        disabled={editMode}
                      />
                      {editMode && (
                        <Icon
                          type="check"
                          className={iconClass}
                          color="#00C45A"
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <Loader
                    style={{
                      height: "30px",
                    }}
                  />
                )}
                {readOnlyFieldsIfSealed.map((value, key) => {
                  const labelText = labelsMap[type][value];

                  if (!labelText) {
                    return null;
                  }

                  const isDatepicker = "birthDate" === value;
                  const isSirenNumber = "sirenNumber" === value;

                  return (
                    <div key={key}>
                      {labelText && (
                        <p className="my-2">
                          <label
                            className="body-sm text-[#8F8F8F]"
                            htmlFor={value}
                          >
                            {labelText}
                          </label>
                        </p>
                      )}
                      {!initializing ? (
                        <>
                          <div className="relative [&>.react-datepicker-wrapper]:block">
                            {isDatepicker ? (
                              <Field name={value}>
                                {({ field }: any) => (
                                  <DatePicker
                                    {...field}
                                    renderCustomHeader={(props) => (
                                      <CustomDatePickerHeader {...props} />
                                    )}
                                    id={value}
                                    className={`${fieldClassFinal} bg-white w-full`}
                                    disabled={isSealed}
                                    onChange={(date) => {
                                      if (date) {
                                        const formattedDate = new Date(
                                          date,
                                        ).toLocaleDateString("fr-FR");
                                        formik.setFieldValue(
                                          value,
                                          formattedDate,
                                        );
                                      }
                                    }}
                                  />
                                )}
                              </Field>
                            ) : (
                              <Field
                                id={value}
                                name={value}
                                type="text"
                                className={`${fieldClassFinal} bg-white`}
                                disabled={isSealed}
                                validate={
                                  isSirenNumber ? validateSiren : undefined
                                }
                              />
                            )}

                            {isSealed && (
                              <Icon
                                type="check"
                                className={iconClass}
                                color="#00C45A"
                              />
                            )}
                            <ErrorMessage name={value}>
                              {(msg) => (
                                <span className="text-[10px] max-w-[293px] text-ea-red">
                                  {msg}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                        </>
                      ) : (
                        <Loader
                          style={{
                            height: "30px",
                          }}
                        />
                      )}
                    </div>
                  );
                })}
                {/* Email */}
                <p className="my-2">
                  <label className="body-sm text-[#8F8F8F]" htmlFor="email">
                    {labels.email}
                  </label>
                </p>
                {!initializing ? (
                  <>
                    <div className="relative">
                      <Field
                        id="email"
                        name="email"
                        type="text"
                        className={fieldClass}
                        validate={validateEmail}
                      />
                      <ErrorMessage name="email">
                        {(msg) => (
                          <span className="text-[10px] max-w-[293px] text-ea-red">
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                    </div>
                  </>
                ) : (
                  <Loader
                    style={{
                      height: "30px",
                    }}
                  />
                )}
                {/* Phone */}
                <p className="my-2">
                  <label className="body-sm text-[#8F8F8F]" htmlFor="phone">
                    {labels.phone}
                  </label>
                </p>
                {!initializing ? (
                  <>
                    <PhoneField
                      codes={countryPhoneCodes}
                      setFieldValue={formik.setFieldValue}
                      inversedDropdown={true}
                      validate={validatePhone}
                    />
                    <ErrorMessage name="phoneCode">
                      {(msg) => (
                        <p className="text-[10px] max-w-[293px] text-ea-red mt-2">
                          {msg}
                        </p>
                      )}
                    </ErrorMessage>
                    <ErrorMessage name="phone">
                      {(msg) => (
                        <p className="text-[10px] max-w-[293px] text-ea-red mt-2">
                          {msg}
                        </p>
                      )}
                    </ErrorMessage>
                  </>
                ) : (
                  <Loader
                    style={{
                      height: "30px",
                    }}
                  />
                )}
                {/* ID Document */}
                {actType !== "convention" && (
                  <>
                    <p className="my-2">
                      <label className="body-sm text-[#8F8F8F]">
                        labels.addNewID
                      </label>
                    </p>
                    {!initializing ? (
                      <>
                        <Field name="IDdocument">
                          {() => (
                            <div className="modal-file-upload">
                              <FileUpload
                                files={files}
                                setFiles={storeFiles}
                                minimized={true}
                                isLocked={isSealed}
                                url={`/api/v1/signbooks/${publicId}/signatories/${signatoryCode}/identification-documents`}
                                acceptedFileTypes={{
                                  "image/jpeg": [],
                                  "image/png": [],
                                  "image/bmp": [],
                                  "application/pdf": [],
                                }}
                                onChange={(files: Document[]) => {
                                  setNumberFilesUploaded(files.length);
                                  const IDfiles =
                                    files.length !== 0
                                      ? files.map((file) => {
                                          const { name } = file;
                                          return { name };
                                        })
                                      : [];

                                  formik.setFieldValue("IDdocument", IDfiles);
                                }}
                                setLoading={setLoading}
                              />
                              {!initializing ? (
                                <>
                                  {files.map((file, index) => {
                                    const isDeleting =
                                      deletingIndices.includes(index);
                                    if (isDeleting) {
                                      return (
                                        <Loader
                                          key={`loading-${index}`}
                                          style={{ height: "30px" }}
                                        />
                                      );
                                    }
                                    return (
                                      <div
                                        key={index}
                                        className="flex items-center py-1 px-2 border border-black border-dashed rounded-md h-[34px] w-full mx-auto relative mt-[10px]"
                                      >
                                        <span className="body-sm truncate max-w-[165px]">
                                          {file.path || file.name}
                                        </span>
                                        <div className="absolute top-0 right-0 px-2 h-full flex justify-center items-center">
                                          <Link
                                            className="mr-5"
                                            to={addQueryParams(
                                              `/acts/${actType}/new/documents/preview`,
                                              {
                                                documentId: file.id || "single",
                                                returnPath: location.pathname,
                                                documentName: file.name,
                                                actId: publicId,
                                                actTitle: actTitle,
                                                lawyer: user?.id ? "true" : "",
                                              },
                                            )}
                                            onClick={() => {
                                              dispatch(hideModal());
                                            }}
                                          >
                                            <Icon
                                              type="preview"
                                              className="size-[18px]"
                                            />
                                          </Link>
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              createAndDownloadFile(file);
                                            }}
                                          >
                                            <Icon
                                              type="download"
                                              className="size-[18px]"
                                            />
                                          </Button>
                                          {!isSealed && (
                                            <Button
                                              type="button"
                                              onClick={() => {
                                                deleteUploadedFile(
                                                  file.id,
                                                  index,
                                                  formik,
                                                );
                                              }}
                                            >
                                              <Icon
                                                type="trash"
                                                className="size-[18px]"
                                              />
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                  {/* Display as much loaders as number of uploaded files */}
                                  {loading &&
                                    deletingIndices.length === 0 &&
                                    [...Array(numberFilesUploaded)].map(
                                      (_, index) => (
                                        <Loader
                                          key={`loading-${index}`}
                                          style={{ height: "30px" }}
                                        />
                                      ),
                                    )}
                                </>
                              ) : (
                                <Loader style={{ height: "30px" }} />
                              )}
                            </div>
                          )}
                        </Field>
                        <ErrorMessage name="IDdocument">
                          {(msg) => (
                            <p className="text-[10px] max-w-[293px] text-ea-red mt-2">
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </>
                    ) : (
                      <Loader style={{ height: "90px" }} />
                    )}
                  </>
                )}
              </div>

              <div className="flex justify-between">
                <Button
                  className="btn-secondary flex justify-start items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
                  onClick={() => dispatch(hideModal())}
                >
                  {labels.cancel}
                </Button>
                {!formik.dirty ? (
                  <Button
                    className="btn btn-secondary-emphasize flex justify-end items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
                    type="submit"
                  >
                    {labels.seal}
                  </Button>
                ) : (
                  <Button
                    className="btn btn-secondary-emphasize flex justify-end items-center rounded-[8px] px-[21px] py-[8px] body-md h-[34px]"
                    type="submit"
                  >
                    {labels.save}
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default SealParty;

import { useContext, useEffect, useState } from "react";

import Icon from "@ui/Icon";
import Spinner from "@ui/Spinner";

import { labels } from "@constants";

import { ParamsContext } from "@pages/Documents";

import { TableProps } from "@types";

import { sortData } from "@utils";

function Table({ rows, columns, sortableColumns }: TableProps) {
  const requestKeyMap: Record<string, string> = {
    title: "NAME",
    creationDate: "CREATIONDATE",
    expirationDate: "EXPIRATIONDATE",
  };

  const params = useContext(ParamsContext);

  const [sortedColumn, setSortedColumn] = useState<string>("");
  const orderParam = params.requestParams?.ascending ? "asc" : "desc";
  const [sortingOrder, setSortingOrder] = useState<"asc" | "desc">(orderParam);
  const [tableRows, setTableRows] = useState<Record<string, any>[]>([]);

  // Only add the overflow auto if there are more than 3 records.
  const tableContainerClass =
    tableRows.length > 3
      ? "overflow-y-auto overflow-x-visible"
      : "overflow-visible";

  useEffect(() => {
    setTableRows(rows);
  }, [rows]);

  useEffect(() => {
    if ("" === sortedColumn) {
      return;
    }

    const labelFind = (obj: Record<string, any>) => obj.label === sortedColumn;
    const sortKey = sortableColumns?.find(labelFind)?.rowKey || "";
    const dataType = sortableColumns?.find(labelFind)?.dataType || "";
    const dataFormat = sortableColumns?.find(labelFind)?.dataFormat || "";

    if (0 !== Object.keys(params).length) {
      const columnKey = requestKeyMap[sortKey];
      const ascending = "asc" === sortingOrder;

      params.setRequestParams({
        ...params.requestParams,
        columnToOrder: columnKey,
        ascending: ascending,
      });

      return;
    }

    if ("" !== sortKey) {
      const rowsToSort = [...tableRows];
      const sortedRows = sortData(
        rowsToSort,
        sortingOrder,
        sortKey,
        dataType,
        dataFormat,
      );

      setTableRows(sortedRows);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedColumn, sortingOrder]);

  const renderedHeaders = columns.map((column, headerIndex) => {
    const isColumnSortable = sortableColumns?.some(
      (obj) => obj.label === column.label,
    );

    let arrowColor =
      sortedColumn === column.label ? "text-black" : "text-[#DBDBDB]";

    if (0 !== Object.keys(params).length) {
      arrowColor =
        params.requestParams.columnToOrder === column.param
          ? "text-black"
          : "text-[#DBDBDB]";
    }

    if (column.header) {
      return <th key={`th-${headerIndex}`}>{column.header()}</th>;
    }

    return (
      <th key={`th-${headerIndex}`} className="py-[10px] body-lg" scope="col">
        {isColumnSortable ? (
          <div
            className="flex items-center cursor-pointer select-none"
            onClick={() => {
              setSortedColumn(column.label);
              setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
            }}
          >
            {column.label}
            <Icon
              type={
                sortingOrder === "asc" ? "arrow-sort-up" : "arrow-sort-down"
              }
              className={`${arrowColor} size-[12px] ml-[10px]`}
            />
          </div>
        ) : (
          column.label
        )}
      </th>
    );
  });

  const emptyTableContent = () => {
    if (params.fetchingActs || params.initializing) {
      return (
        <tr
          key={`index-${new Date().getTime()}`}
          className="table-fixed w-full table-row"
        >
          <td
            className="px-[25px] py-[10px] text-center first-of-type:min-w-[250px]"
            colSpan={100}
          >
            <div className="w-full h-full pt-[40px] flex justify-center items-center">
              <Spinner className="size-10" dark={true} />
            </div>
          </td>
        </tr>
      );
    }

    return (
      <tr
        key={`index-${new Date().getTime()}`}
        className="table-fixed w-full table-row"
      >
        <td
          className="px-[25px] py-[10px] text-center first-of-type:min-w-[250px]"
          colSpan={100}
        >
          <div className="w-full h-full pt-[73px]">
            <Icon
              type="empty-folder"
              className="w-[48px] h-[36px] mx-auto mb-[23px]"
            />
            <p>{labels.noDocumentsInTable}</p>
          </div>
        </td>
      </tr>
    );
  };

  const renderedRows =
    rows.length === 0 || params.fetchingActs
      ? emptyTableContent()
      : tableRows.map((row, index) => {
          const lastRowClass = index === tableRows.length - 1 ? "last-row" : "";
          const renderedCells = columns.map((column, cellIndex) => {
            return (
              <td
                key={`td-${cellIndex}`}
                className={column.className}
                data-index={index}
              >
                {column.render(row, index)}
              </td>
            );
          });

          return (
            <tr
              className={`hover:bg-ea-lightblue h-[90px] max-w-[250px] lg:w-[100%] table-row table-fixed ${lastRowClass}`}
              // this is done, because react needs a unique key for each element in a list and currently I have no id for each row
              // in real life, if it is possible I will use the id of the record
              key={`index-${index}-${new Date().getTime()}`}
            >
              {renderedCells}
            </tr>
          );
        });

  return (
    <div
      className={`${tableContainerClass} px-[25px] mt-4 block relative border-collapse max-w-full max-h-[490px]`}
    >
      <table className="overflow-auto whitespace-nowrap px-[25px] lg:min-w-[800px] w-full border-collapse table-auto table">
        <colgroup className="table-column-group">
          {renderedHeaders.map((header, index) => {
            return <col span={1} key={`col-${index}`} />;
          })}
        </colgroup>
        <thead className="table-header-group w-full sticky top-[-1px] left-0 bg-white text-left text-neutral-500 table-fixed after:content-[''] after:w-full after:h-[1px] after:bg-ea-gray-200 after:absolute after:bottom-0 after:left-0 z-40">
          <tr>{renderedHeaders}</tr>
        </thead>
        <tbody className="overflow-auto">{renderedRows}</tbody>
      </table>
    </div>
  );
}

export default Table;

import { useEffect, useRef, useState } from "react";

import Button from "@ui/Button";
import Icon from "@ui/Icon";

import { MultiSelectProps, MultiselectOption } from "@types";

const MultiSelect = ({
  options,
  selected,
  setSelected,
  maxWidthOptionMenu,
  allStatusOptions,
  onChange,
}: MultiSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isOpenDropdownStyle = isOpen ? "dropdown-open" : "";
  const rotateArrow = isOpen ? "rotate-180" : "";

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleOption = (value: MultiselectOption) => {
    const updatedOptions = selected.includes(value)
      ? selected.filter((option) => option !== value)
      : [...selected, value];

    if (updatedOptions.length === 0) {
      setSelected(options);
      onChange && onChange(options);
      return;
    }

    setSelected(updatedOptions);
    onChange && onChange(updatedOptions);
  };

  const handleSelectAll = () => {
    setSelected(options);
    onChange && onChange(options);
    toggleDropdown();
  };

  const handleRemoveOption = (value: MultiselectOption) => {
    const updatedOptions = selected.filter((option) => option !== value);
    if (updatedOptions.length === 0) {
      setSelected(options);
      onChange && onChange(options);
      return;
    }

    setSelected(updatedOptions);
    onChange && onChange(updatedOptions);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`dropdown ${isOpenDropdownStyle}`} ref={dropdownRef}>
      <div
        onClick={toggleDropdown}
        className={`relative max-h-[42px] h-full overflow-y-auto font-medium text-black pl-[36px] py-2 pr-16 bg-white rounded border border-solid border-ea-gray-100 max-md:pr-5 ${
          selected.length === options.length
            ? "custom-checkbox-checked__input-dropdown"
            : ""
        }`}
      >
        {selected.length === options.length
          ? allStatusOptions
          : selected.map((option) => (
              <span
                key={`option-${option.value}`}
                className="inline-flex items-center px-2 mr-1 mb-1 bg-ea-gray-100 rounded max-w-fit"
              >
                {option.label}
                <Button
                  className="ml-2 text-xs "
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveOption(option);
                  }}
                >
                  <Icon type="close" color="#25233D" className="w-2 h-2" />
                </Button>
              </span>
            ))}
        <Icon
          type="dropdown-arrow-slim"
          color="#000"
          className={`absolute top-[50%] right-4 transform -translate-y-1/2 w-[18px] h-[9px] ${rotateArrow}`}
        />
      </div>
      {isOpen && (
        <div
          className={`absolute font-medium text-black pl-[40px] py-2 pr-16 bg-white rounded border border-solid border-ea-gray-100 max-md:pr-5 z-[41] ${maxWidthOptionMenu} w-full`}
        >
          <label className="relative">
            <input
              type="checkbox"
              checked={selected.length === options.length}
              onChange={handleSelectAll}
              className={` ${
                selected.length === options.length
                  ? "custom-checkbox-checked"
                  : "custom-checkbox"
              }`}
            />
            {allStatusOptions}
          </label>
          {options.map((option) => (
            <div key={option.value}>
              <label className="relative">
                <input
                  type="checkbox"
                  checked={selected.includes(option)}
                  onChange={() => handleToggleOption(option)}
                  className={` ${
                    selected.includes(option)
                      ? "custom-checkbox-checked"
                      : "custom-checkbox"
                  }`}
                />
                {option.label}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;

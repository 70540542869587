import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@ui/Button";
import Icon from "@ui/Icon";

import { showForm } from "@reducers/partyFormSlice";

import { DropdownListOptions, DropdownListProps } from "@types";

const FieldDropdown = ({
  arrowPosition = "left",
  options,
  width,
  initialValue,
  onChange,
  disabled = false,
  fontSize,
  paddingLeft,
  inputHeight,
  marginTop,
}: DropdownListProps) => {
  const [value, setValue] = useState(initialValue || "");

  const [label, setLabel] = useState(
    options.find((option) => option.value === value)?.label || "",
  );

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setDropdownVisible(!dropdownVisible);
  };
  const dispatch = useDispatch();
  const handleSelection = (label: string, value: string) => {
    setValue(value);
    setLabel(label);

    dispatch(showForm(value));
  };

  const dropdownWidth = width ? width : "min-w-full";

  useEffect(() => {
    const hideDropdown = () => {
      if (dropdownVisible) {
        setDropdownVisible(false);
      }
    };

    window.addEventListener("click", hideDropdown);

    return () => {
      window.removeEventListener("click", hideDropdown);
    };
  }, [dropdownVisible]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const iconRotation = dropdownVisible ? "transform rotate-180" : "";
  const hideDropdown = dropdownVisible ? "" : "hidden";
  const arrowPositionClass =
    arrowPosition === "right" ? "right-[14px]" : "left-[14px]";
  const disabledClass = disabled
    ? "border-[#F5F5F5] cursor-default"
    : "border-ea-gray-200";
  const fontSizeClass = fontSize ? fontSize : "body-sm";
  const inputHeightClass = inputHeight ? inputHeight : "h-[34px]";
  const marginTopClass = marginTop ? marginTop : "mt-0";
  const paddingLeftClass = paddingLeft ? paddingLeft : "pl-[40px]";

  return (
    <div
      className="flex relative w-full"
      onClick={disabled ? undefined : (event) => toggleDropdown(event)}
    >
      <Button
        type="button"
        className={`${disabledClass} ${dropdownWidth} dropdown-selected flex ${paddingLeftClass} pr-[10px] pt-[5px] ${inputHeightClass} ${fontSizeClass} ${marginTopClass} bg-white rounded border border-solid`}
      >
        {label}
      </Button>
      {!disabled && (
        <Icon
          type="dropdown-arrow"
          className={`pointer-events-none absolute size-[16px] top-[50%] translate-y-[-50%] ${iconRotation} ${arrowPositionClass}`}
          color="black"
        />
      )}
      <div
        className={`z-20 absolute border-none rounded ${dropdownWidth} flex-none bg-white border-[#B4B4B4] h-auto overflow-none top-[35px] box-shadow ${hideDropdown}`}
      >
        <ul className="dropdown-items body-sm text-left py-2">
          {options.map((list: DropdownListOptions, index: number) => (
            <li key={index}>
              <Button
                type="button"
                className={`w-full ${fontSizeClass} text-left ${paddingLeftClass} hover:bg-ea-lightblue py-[4px] flex justify-between items-center pr-3`}
                role="menuitem"
                onClick={() => {
                  handleSelection(list.label, list.value);
                }}
              >
                {list.label}{" "}
                {list.note ? (
                  <span className="font-bold">{list.note}</span>
                ) : (
                  ""
                )}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FieldDropdown;

import { getYear } from "date-fns";
import { range } from "pdf-lib";
import { Bounce, ToastOptions } from "react-toastify";

import CloseIcon from "@ui/CloseIcon";

import {
  ActGeneralInformationProps,
  PartsToBeSealedProps,
  SignatoriesTableProps,
} from "@types";

const DOCUMENTS_MAX_SIZE_MB = 20;
const DOCUMENTS_MAX_SIZE = DOCUMENTS_MAX_SIZE_MB * 1024 * 1024;

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

const axiosErrorMessages: Record<string, string> = {
  "Network Error": "Erreur réseau",
  "Request failed with status code 400":
    "Requête échouée avec le code d'état 400",
  "Request failed with status code 401":
    "Votre session est expirée. Veuillez recharger la page",
  "Request failed with status code 404":
    "Requête échouée avec le code d'état 404",
  "Request failed with status code 500":
    "Requête échouée avec le code d'état 500",
  "Request failed with status code 503":
    "Requête échouée avec le code d'état 503",
};

const labels = {
  welcomeMessage: "Bonjour $1, ravis de vous revoir",
  signedByActivity: "Signé par $1 $2",
  actionRequired: "Action requise",
  actionsRequired: "Actions requises",
  actionsSignature: "Actes en attente de signature",
  actionsExpiring: "Actes bientôt expirés",
  actionsArchived: "Actes clos et archivés",
  newConvention: "Nouvelle e-convention",
  buttonLawyer: "Nouvel e-acte d’avocat",
  recentActivities: "Activité récente",
  activityTitle: "e-Convention Maffre",
  euroSign: "€",
  date: "Date",
  details: "Détails",
  act: "e-acte",
  document: "Acte",
  amount: "Montant",
  titleSearch: "Rechercher par nom, prénom ou e-mail",
  invoiceHistoryTitleSearch: "Rechercher par nom ou identifier de l'acte",
  signaturesSearch: "Rechercher par nom ou e-mail",
  filters: "Filtres",
  export: "Exporter",
  invoicesHistory: "Historique des factures",
  invoicesDescription:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  forSigningTabLabel: "Actes à signer",
  forSealingTabLabel: "Parties à sceller",
  forValidatingTabLabel: "Documents à valider",
  forPaymentsToBeMadeTabLabel: "Paiements à effectuer",
  filtersAndAdvancedSearch: "Filtres et recherche avancée",
  advancedSearchTabLabel: "Recherche avancée",
  lastSentConvention: "Dernière convention envoyée le",
  status: "Etat",
  creationDate: "Date de création",
  deadline: "Date limite",
  apply: "Appliquer",
  nameOfParty: "Nom ou prénom de la partie",
  lawyerName: "Nom ou prénom de l’avocat",
  keywords: "Mots clés",
  search: "Rechercher",
  new: "Nouveau",
  title: "Titre",
  lawyer: "Avocat",
  lawyers: "Avocats",
  sign: "Signer",
  party: "Partie",
  partyX: "Partie $1",
  parties: "Parties",
  newLawyer: "Nouvel avocat",
  newParty: "Nouvelle partie",
  IDdocuments: "Documents d'identité",
  addNewID: "Ajouter des documents d'identité",
  addNewLawyer: "Ajouter un avocat conseil",
  view: "Visualiser",
  delete: "Supprimer",
  signatures: "Signataires",
  seal: "Sceller",
  verifyAndSeal: "Vérifier et sceller",
  documentName: "Nom du document",
  draftingLawyer: "Avocat rédacteur",
  signBy: "signé par",
  signOn: "signé le",
  validate: "Valider",
  conventionTabLabel: "e-Convention",
  divorceTabLabel: "e-Divorce",
  lawyersDocumentTabLabel: "e-Acte d’avocat",
  participatoryProcedureTabLabel: "Procédure Participative",
  soonExpiringDocumentsTabLabel: "e-Actes bientôt expirés",
  closedAndArchivedDocumentsTabLabel: "e-Actes clos et archivés",
  closedAndArchived: "Clos et archivé",
  relaunch: "Relancer",
  downloadInvoice: "Télécharger la facture",
  viewHistory: "Consulter l’historique",
  downloadEvidence: "Télécharger les preuves",
  duplicateDocument: "Dupliquer l’acte",
  cancel: "Annuler",
  newDivorce: "Nouvel e-divorce",
  newDocument: "Nouvel e-acte",
  newActDigital: "Nouvel e-acte numérisé",
  // newParticipatoryProcedure: "Nouvelle procédure participative",
  email: "E-mail",
  phone: "Téléphone",
  phoneCode: "Code de téléphone",
  partiesOrLawyer: "Parties/avocats",
  viewParty: "Voir la partie",
  signingParties: "Parties signataires",
  documentsToSign: "Documents à signer",
  lawyerEditorNote:
    "Vous apparaissez ici en tant qu’avocat rédacteur, vous serez le dernier à signer l’acte.",
  balance: "Solde",
  accountAlertThreshold: "Seuil d’alerte de compte",
  accountAlertThresholdDescription:
    "Recevez une notification par e-mail lorsque votre compte atteint 30,00 €.",
  registredBankCards: "Cartes bancaires enregistrées",
  manageMyBankCards: "Gérer mes cartes",
  addDocumentButton: "Ajouter des documents",
  viewDocuments: "Visualiser les documents",
  printHistoryButton: "Imprimer l’historique",
  ActHistoricTitle: "Historique",
  cancelActButton: "Annuler l’e-acte",
  duplicateActButton: "Dupliquer l’e-acte",
  generalInformationTitle: "Informations générales",
  notificationMessageTitle: "Notifications & messages",
  paymentToBeMadeInfoText:
    "Recevez une notification par e-mail lorsque vous avez un paiement à réaliser.",
  expiringActTitle: "Acte bientôt expiré",
  expiringActInfoText:
    "Recevez une notification par e-mail lorsqu’il reste moins de 7 jours avant l’expiration d’un acte.",
  defaultAppliedLabel: "appliquer par défaut",
  lastActivity: "Dernière activité",
  allStatuses: "Tous les états",
  allActTypes: "Tous les types d’actes",
  openDocument: "Ouvrez l'acte",
  noDocumentsInTable: "Vous n’avez aucun e-acte dans cette section.",
  needHelp: "Besoin d’aide ?",
  contactSupport: "Contacter le support",
  rechargeAccount: "Recharger le compte",
  recharge: "Recharger",
  rechargeAmount: "+ 30",
  rechargePrepaidAccount: "Rechargez votre comptre prépayé facilement.",
  greetingEmail: "Formule de remerciement",
  signatureEmail: "Signature de l’e-mail",
  addSignature: "Enregistrer la signature",
  preferences: "Préférences",
  preferencesTitleDropdown: "E-actes les plus utilisés",
  preferencesInfoText:
    "Renseignez ici les types d’actes que vous souhaitez affficher en priorité dans la page d'accueil",
  signature: "Signature",
  signatureDescription:
    "Dessiner votre signature directement dans la zone de dessin ou importer votre signature au format PNG, BMP ou JPEG.",
  signatureDescriptionSignatory:
    "Dessiner votre signature directement dans la zone de dessin. En l’absence de signature manuscrite, vos nom et prénom apparaitront en lieu et place de la signature.",
  validateSignature: "Valider la signature",
  pleaseReadAllDocuments: "Lisez l’ensemble des pages pour pouvoir signer",
  import: "Importer",
  save: "Enregistrer",
  addParty: "Ajouter une partie",
  addClient: "Ajouter un client",
  modificationParty: "Modifier la partie",
  modificationLawyer: "Modifier l'avocat",
  changeLawyer: "Changer l'avocat",
  modify: "Modifier",
  modifyClient: "Modifier le client",
  lockParty: "Sceller mes parties et continuer",
  relaunchLawyer: "Relancer l'avocat",
  agreementsToSign: "Convention d’honoraire à signer",
  addAgreements: "Ajoutez votre convention d’honoraire ou",
  downloadAgreementTemplate: "téléchargez un modèle de convention",
  birthAct: "e-acte natif",
  newBirthAct: "Nouvel e-acte natif",
  welcomeToNewEAct: "Bienvenue sur Nouvel E-Actes",
  welcomeToNewEActPageDescription:
    "Découvrez la nouvelle interface E-Actes, plus ergonomique et plus simple d’utilisation.",
  yourPreferredEActUse: "Votre utilisation d’E-Actes",
  yourPreferredEActPageDescription:
    "Quelle utilisation ferez-vous de ce Nouvel E-Actes ? Vous pouvez cocher plusieurs réponses.",
  yourPreferredEActUseInfoText:
    "L’écran d’accueil vous proposera des liens personnalisés d’action rapide vers les actes les plus fréquement utilisés.",
  yourProfile: "Votre profil",
  YourProfilePageDescription:
    "Mettez à jour vos coordonnées et paramétrez votre signature.",
  yourProfileInfoText:
    "Vous pouvez modifier ces informations à tout moment depuis votre onglet Profil.",
  completeActInformation:
    "Complétez les informations relatives à l’e-acte comme le nom, la description ou la date d’expiration.",
  newActName: "Nom de l’e-acte",
  newDigitalActName: "Nom de l’e-acte numérisé",
  newDivorceActName: "Nom de l’e-divorce",
  newConventionName: "Nom de la nouvelle e-convention",
  descriptionOfNewAct: "Description (facultatif)",
  expirationDateOfAct: "Date d’expiration",
  signingDateInformation:
    "Après cette date, vous ne pourrez plus accéder à l’acte, seul la duplication sera possible.",
  information: "Informations",
  documents: "Documents",
  cancelTheAct: "Annuler l’acte",
  finaliseNyProfile: "Finaliser mon profil",
  startNewEAct: "Commencer avec Nouvel e-actes",
  next: "Suivant",
  signAndCertifyAct: "Signer et certifier l’acte",
  previous: "Précédent",
  quit: "Quitter",
  sealedBy: "Scellement par",
  advisedBy: "conseillée par",
  identityToBeSealed: "Identité à sceller",
  advisingTheParty: "Je conseille cette partie",
  validateParty: "Je valide cette partie",
  sealThisParty: "Je scelle cette partie",
  advise: "Conseille",
  and: "et",
  sealIdentity: "Sceller l’identité",
  sealPartyModalTitle: "Scellement des parties",
  sealPartyModalDescription:
    "Vous êtes sur le point de sceller les parties dans cet e-acte.",
  sealPartyModalDescription2:
    "Vous ne pourrez plus modifier les identités des parties.",
  sealPartyModalButton: "Sceller et continuer",
  validateDocument: "Valider le document",
  validateDocuments: "Validation des documents",
  validateDocumentAndContinue: "Valider les documents et continuer",
  aboutToValidate:
    "Vous êtes sur le point de valider les documents de cet e-acte",
  canNoLongerModify:
    "Vous ne pourrez plus ajouter ou modifier des documents à cet e-acte.",
  continuePrompt: "Souhaitez-vous continuer?",
  sendForSigningTitle: "Envoi pour signatures",
  sendForSigning: "Envoyer pour signature",
  createdAt: "créé le",
  expiringAt: "expire le",
  payAndSend: "Payer et envoyer",
  signAndSend: "Signer et envoyer",
  defineOrderOfSigning: "Définir un ordre de signature",
  obligationToReadAllPages: "Obligation de lire toutes les pages",
  sealed: "Scellé",
  notSealed: "non scellé",
  dragAndDropDocuments: "Glisser-déposer vos documents ici ou",
  importDocuments: "importer les",
  share: "Partager pour validation",
  shareDocuments: "Partage des documents",
  shareShort: "Partager",
  sharing: "Partagé",
  actAmount: "montant de l’acte",
  continueWithoutRegistering: "Continuer sans enregister",
  registerAct: "Enregistrement de l’e-acte",
  generatedCertificate: "Certificat généré",
  applyHandwrittenSignature: "apposer la signature manuscrite",
  applyHandwrittenSignatureClient: "Apposer une signature manuscrite",
  registerActDescription:
    "Vous allez quitter le parcours de création d’un e-acte. Souhaitez-vous enregistrer l’acte?",
  logOut: "Quitter l'application",
  modification: "Modifications",
  saveModification:
    "Vous venez d’effectuer des modifications non enregistrées. Souhaitez-vous les enregistrer?",
  quitWithoutSaving: "Quitter sans enregistrer",
  documentViewLegalMentions:
    "Avant toute opération, nous vous demandons d'être équipé d'une imprimante ou d'avoir la possibilité d'enregistrer sur votre disque dur les éléments contractuels que nous vous transmettrons. Vous êtes désormais prêt à signer les documents ci-contre. Vous devez accepter les conditions avant de pouvoir signer. Vous pouvez les consulter en cliquant sur le lien ci-dessous:",
  addOrSearchForParty:
    "Recherchez des parties parmi les signataires ou ajoutez une nouvelle partie.",
  addOrSearchForClient:
    "Recherchez des clients parmi les signataires ou ajoutez un nouveau client.",
  physicalPerson: "Personne physique",
  acceptAndSign: "Accepter et signer",
  company: "Personne morale",
  legalRepresentative: "Personne physique représentée",
  companyREpresentative: "Personne morale représentée",
  lawyerAddPartyInfo:
    "L’avocat conseil sera en charge du scellement de cette partie",
  identityToBeValidatedBy: "Je valide l'identité",
  signatureInPresence: "Signature en présentiel",
  addThisParty: "Ajouter cette partie",
  searchLabelForLawyer: "Rechercher par nom, prénom ou CNBF",
  Mr: "Monsieur",
  Mrs: "Madame",
  Miss: "Mademoiselle",
  name: "Prénom",
  surname: "Nom",
  birthDate: "Date de naissance",
  honorifics: "Civilité",
  dragAndDropOr: "glisser-déposer ou",
  oneDocument: "un document",
  requiredField: "Le champ $1 est obligatoire",
  compliantField: "Le champ $1 n'est pas conforme",
  documentsAddText:
    "Ajoutez des documents au format .pdf dans une limite de 20Mo. Pour ajouter plusieurs documents en une fois utiliser Shift+select puis glisser-déposer vos documents.",
  filesizeLimitReached: "La limite de $1 Mo en total est depassée.",
  documentsSuccessfullyShared: "Documents partagés avec succès",
  documentsSuccessfullyAdded: "Documents ajoutés avec succès",
  documentSuccessfullyAdded: "Document ajouté avec succès",
  documentSuccesfullyUpdated: "Modification enregistrées",
  invalidEmail: "E-mail non conforme",
  partySealed: "L’identité de cette partie a été précedemment scellée.",
  clientSealed: "L’identité de ce client a été précedemment scellée.",
  deleteParty: "Supprimer cette partie",
  minCharLabel: "Au moins $1 caractères",
  maxCharLabel: "Au plus $1 caractères",
  download: "Télécharger",
  print: "Imprimer",
  page: "Page",
  doc: "Doc.",
  accept: "J’accepte",
  termsAndConditions: "les conditions du service de signature en ligne",
  help: "Aide",
  searchByNameSurnameOrEmail:
    "Rechercher par nom, prénom ou adresse électroniques",
  searchByNameChbfNumberOrEmail:
    "Rechercher par nom, prénom, numéro CNBF ou adresse électroniques",
  codeCNBF: "Numéro CNBF",
  homeOffice: "Barreau d'appartenance",
  billingAdress: "Adresse de facturation",
  electronicAddress: "Adresse électronique",
  sirenNumber: "Numéro de SIREN",
  socialReason: "Raison sociale",
  domiciliation: "Domiciliation",
  legalSurname: "Nom du représentant légal",
  legalName: "Prénom du représentant légal",
  typeOfActs: "Types d’actes",
  allActs: "Tous les actes",
  loading: "Chargement...",
  errorWhileLoadingPdf: "Une erreur est survenue lors du chargement du pdf",
  paymentDate: "Date de paiement",
  errorLoadingUserInfo:
    "Erreur lors du chargement des informations utilisateur",
  errorLoadingVersion: "Erreur de chargement de la version",
  errorLoadingRecentActivities: "Erreur de chargement des activités récentes",
  charLimitSiren: "Le numéro SIREN doit contenir 9 chiffres",
  invalidSirenNumber: "Le numéro SIREN est invalide",
  searchByNameEmailOrCnbf: "Rechercher par nom, e-mail ou numéro CNBF",
  deletePartyAndAdvocatLabel:
    "Vous etes sur le point de supprimer une partie de l’e-acte.",
  deleteClientFomEconvention:
    "Vous etes sur le point de supprimer un client de l'e-convention",
  deleteClient: "Supprimer ce client",
  adviceParty: "$1 conseille cette partie",
  keyConnected: "Signature disponible, certificat valable jusqu’au $1",
  keyNotConnected: "Clef RPVA non connectée, signature non disponible",
  keyExpiring: "Votre certificat de signature expire le $1",
  myProfile: "Mon profil",
  myHandwrittenSignature: "Ma signature manuscrite",
  resetToDefaultSettings: "Réinitialiser les paramètres par défaut",
  amountAlertMessage: "le montant doit être supérieur à 0",
  myPrepaidAccount: "Mon compte prépayé",
  diagnostic: "Diagnostic",
  signOut: "Se déconnecter",
  welcome: "Accueil",
  acts: "E-Actes",
  cookieManagement: "Gestion des cookies",
  cookiePolicies: "Politiques de cookies",
  privacyPolicy: "Politique de confidentialité",
  legalNotice: "Mentions légales",
  startDateShouldBeBeforeEndDate:
    "La date de début doit être inférieure à la date de fin",
  version: "Version",
  userInfo: "Informations utilisateur",
  postCode: "Code postal",
  city: "Ville",
  address: "Adresse",
  identityOfPartyIsSealing:
    "L’identité de cette partie est en cours de scellement.",
  idCard: "Carte d’identité",
  conformationDataIsCorrect:
    "J’atteste que ces informations sont conformes aux documents d’identités",
  requiredFieldText: "champ requis",
  affirmationToHaveReadTheDocuments:
    "Je reconnais avoir pris connaissance de l’ensemble des pages de l’acte et ses annexes qui m’a été présenté",
  acceptTermsAndConditions:
    "J’accepte les conditions du service de signature en ligne",
  signedActAndAnnexesLabel: "Vous avez signé l'acte et ses annexes.",
  downloadDocumentOnFinalizedActLabel:
    "Vous pourrez télécharger tous les documents signés lorsque l'acte sera finalisé.",
  resetForm: "Réinitialiser le formulaire",
  successfullySignedAct: "Acte signé avec succés",
  unsuccessfullySignedAct:
    "La signature a échoué, veuillez réessayer à partir du lien",
  signWithQrCode: "Signer avec un QR code",
  signWithCertificate: "Signer avec un certificat",
  payment: "Paiement",
  actReference: "Référence e-acte",
  billing: "Facturation",
  wayOfPayment: "Mode de paiement",
  invoiceOfPayment:
    "La facture pour le paiement de cet acte sera éditée au nom de $1",
  payAmount: "Payer $1 €",
  at: "à",
  confirm: "Confirmer",
  confirmCancelAct: "Êtes-vous sûr(e) de vouloir annuler l’e-acte ?",
  diagnosticsSupportedBrowsers:
    "Les navigateurs supportés sont Firefox 90, Chrome 90, Edge 90",
  operatingSystem: "Système d’exploitation",
  browser: "Navigateur",
  browserNotSupported: "Navigateur non supporté",
  signatureSoftware: "Logiciel de signature",
  notInstalled: "Non installé",
  installed: "Installé",
  downloadTrustKeyManager: "Télécharger Trust Key Manager",
  downloadeBeeSoftware: "Télécharger le logiciel eBee Access Foundation",
  keyRPVA: "Clef RPVA",
  diagnosticEBEE: "Diagnostic eBee",
  ok: "OK",
  expiring: "Bientôt expirée",
  expired: "Expirée",
  notConnected: "Non connecté",
  certificate: "Certificat",
  deliveredBy: "Délivré par",
  deliveredTo: "Délivré à",
  validity: "Validité",
  certificateDescription:
    "Un certificat a été généré pour vous permettre de signer les documents qui vous ont été présentés. Il n'est valide que pour cette opération de signature. Nous vous laissons vérifier les informations ci-dessous :",
  warningSigningCertificate:
    "Vous vous apprêtez à signer les 2 document(s) suivant(s) (Cette Signature est irrévocable):",
  beysTrustedSolutionDescription:
    "Be-Ys Trusted Solutions SASU est le tiers de confiance chargé de procéder à la génération des certificats.",
  XofY: "$1 sur $2",
  moreInformation: "Plus d'infos",
  lessInformation: "Moins d'infos",
  representedBy: "représenté par $1",
  master: "Maître",
  invalidLawyer: "Sélection d'avocat invalide.",
  pleaseSelectLawyer: "Veuillez sélectionner un avocat.",
  noResultsFound: "Aucun résultat trouvé",
  addThisLawyer: "Ajouter cet avocat",
  searchByNameSurnameEmailOrCnbf:
    "Rechercher par nom, prénom, email ou numéro CNBF",
  osNotSupported: "Système d’exploitation non supporté",
  edgeBrowser: "Microsoft Edge",
  internetExplorerBrowser: "Internet Explorer",
  chromeBrowser: "Google Chrome",
  fireFoxBrowser: "Mozilla Firefox",
  operaBrowser: "Opera",
  safariBrowser: "Safari",
  windowsOs: "Windows",
  macOs: "Mac OS X",
  cancelled: "Annulé",
  cancelationOfAct: "Annulation de l’acte",
  cancelationOfExistingActPrompt: "Vous allez annuler l’acte existant.",
  copy: "copie",
  number: "Numéro",
  restrictedAccess: "Accès protégé",
  receiveCodeBySms: "Recevoir le code par SMS",
  blockedAccess: "Accès bloqué",
  blockedAccessMessage:
    "Votre accès à l’acte a été temporairement bloqué. Il sera débloqué prochainement.",
  validateWithPhoneLabel:
    "Pour accéder à la signature de cet e-acte vous devez valider l’accès avec votre téléphone. Si ce numéro est le vôtre cliquez sur recevoir le code par SMS.",
  enterTheReceivedCodeBySms: "Entrez le code reçu par SMS",
  haveYouNotReceivedCode: "Vous n’avez pas reçu le code?",
  resendCode: "Renvoyer le code",
  readAllDocuments: "Lisez l’ensemble des pages pour pourvoir signer",
  code: "code",
  clients: "Clients",
  client: "Client",
  convention: "Convention",
  divorceProcedureInformation: "Informations sur la procédure e-DCM",
  actionConfirmation: "J'ai compris les actions et je m'engage à les réaliser",
  commence: "Commencer",
  verifyBeforeLaunch: "Vérification avant lancement",
  receiptDate: "Date de réception",
  checkDelay: "Vérifier le délai",
  registeredLetterNotice:
    "La lettre recommandée doit provenir d'un service qualifié par l'ANSSI",
  receiptExpirationNotice:
    "Le délai de 15 jours court à compter du lendemain de la réception, et doit être expiré la veille de la signature et non le jour de la signature",
  receiptDeadlineNotice:
    "Afin de vérifier que le délai de 15 jours est bien respecté, merci de renseigner la date de réception de l'accusé de réception des LR par chaque partie.",
  confirmation: "Confirmation",
  continue: "Continuer",
  finalizingProcedure: "Finalisation de la procédure",
  mb: "Mo",
  cancelAct: "Annuler l'acte",
  openAct: "Ouvrir l'acte",
  deletePartyLabel: "Supprimer la partie",
  deleteClientLabel: "Supprimer le client",
  signaturesLabel: "Signatures",
  digitalAct: "e-Acte numérisé",
  actSignedInfo: "Signé par $1 le $2 à $3",
  signedOn: "A signé le $1",
  partiesAndAdvocates: "Parties & Avocats",
  newConventionAgreement: "Nouvelle convention d’honoraire",
  orderSignaturesPrompt: "Souhaitez-vous enregistrer l’ordre des signataires ?",
  orderDocumentsPrompt: "Souhaitez-vous enregistrer l’ordre des documents ?",
  cancelDescription:
    "Vous allez quitter le parcours de création d’un e-acte. Souhaitez-vous continuer?",
  cancelTitle: "Modifications en cours",
  continueModifications: "Continuer les modifications",
  importedPartyFromSearchLabel:
    "Cette partie est importée des signataires. Souhaitez-vous la modifier?",
  requestTookTooLong: "La requête a pris trop de temps.",
  malingListValidation:
    "Vous êtes sur le point d'envoyer un Acte d'Avocats électronique pour validation. Veuillez confirmer la liste d'envoi.",
  birthDateMustBeBeforeCurrentDate:
    "La date de naissance doit être inférieure ou égale à la date du jour",
  notifyParty: "Notifier la partie",
  notifyClient: "Notifier le client",
  notificationSuccess: "Notification envoyée avec succès",
  relaunched: "Relancé",
  relaunchInProgress: "Relance en cours",
  payAct: "Payer l'acte",
  actCancelledSuccessfully: "Acte annulé avec succès",
  notStarted: "Non démarré",
  created: "Créé",
  signing: "En signature",
  waitingForPayment: "En attente de paiement",
  noDataFoundMessage: "<no data found> (0/1)",
  fileDuplicationForbidden: "La duplication de fichiers est interdite",
  practiceGuide: "Guide Pratique",
  attemptsNumberLeft:
    "Code non valide, $1 essais restants avant blocage de l’accès",
  test: "Tester",
  rotatePhoneMessage:
    "Pour une meilleure expérience, basculez l’écran de votre téléphone",
  receivedDocumentForValidationLabel:
    "Vous venez de recevoir une demande de validation de document.",
  readTheDocumentsAndValidate:
    "Prenez connaissance des documents ci-après puis validez-les.",
  newBalance: "Nouveau Solde",
  requiredActionsSearch: "Rechercher par titre, numéro ou clients",
  noInfo: "Pas d'information",
  signingDocumentsList:
    "Vous vous apprêtez à signer les $1 document(s) suivant(s) (Cette Signature est irrévocable):",
  noSignatureFound: "Aucune signature trouvée.",
  noCertificateDetected: "Aucun certificat détecté",
  signingCancelled: "Signature annulée",
  signingError: "Erreur de signature",
};

const actTypeNameMap: Record<string, string> = {
  dcm: "divorce",
  natif: "birth",
  econvention: "convention",
  numerise: "digital",
};

const actMapAmount = {
  dcm: "dcmAmount",
  econvention: "eConventionAmount",
  natif: "natifAmount",
  numerise: "numeriseAmount",
};

const certificateNumber = "876577889899-Y76878";
const certificateDeliveryName =
  "BE-YS USER SIGNING CA NA, BE INVEST INTERNATIONAL SAS";

const logos = {
  windows: "/assets/windowsLogo.png",
  apple: "/assets/appleLogo.png",
  chrome: "/assets/chromeLogo.png",
  fireFox: "/assets/fireFoxLogo.png",
  edge: "/assets/edgeLogo.png",
  safari: "/assets/safariLogo.png",
};

const toastOptions: ToastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

const toastOptionsError: ToastOptions = {
  ...toastOptions,
  icon: CloseIcon,
  className: "toastify-error-container",
};

const dropdownOptions = [
  { label: labels.created, value: "created" },
  { label: labels.sharing, value: "shared" },
  { label: labels.signing, value: "signing" },
  { label: labels.waitingForPayment, value: "waitingForPayment" },
  { label: labels.closedAndArchived, value: "closed" },
  { label: labels.cancelled, value: "cancelled" },
  { label: labels.expired, value: "expired" },
];

const dropdownOptionsHistoryFilters = [
  { label: labels.conventionTabLabel, value: "convention" },
  { label: labels.divorceTabLabel, value: "divorce" },
  { label: labels.lawyersDocumentTabLabel, value: "lawyer-document" },
  {
    label: labels.closedAndArchivedDocumentsTabLabel,
    value: "closed-and-archived",
  },
  { label: labels.digitalAct, value: "e-acts-digital" },
];

const dataForToBeSealedTable: PartsToBeSealedProps[] = [
  {
    id: "21",
    imageUrl: "/assets/avatar-male.png",
    firstName: "Albert",
    lastName: "Dupont",
    email: "albert.dupont@gmal.com",
    phone: "0678435316",
    phoneCode: "+33",
    document: "e-Convention Maffre",
    IDdocument: [{ name: "albert-dupont.png" }],
    birthDate: "11/02/1959",
    deadline: "11/07/24",
    state: `${labels.signBy} Albert Maffre (2/5)`,
    currentStep: 3,
    maxSteps: 5,
    type: "physical",
    sealed: false,
  },
  {
    id: "22",
    imageUrl: "/assets/avatar-male.png",
    firstName: "Albertt",
    lastName: "Dupont",
    email: "albert.dupont@gmal.com",
    phone: "0678435316",
    phoneCode: "+33",
    document: "e-Convention Maffre",
    birthDate: "11/02/1959",
    deadline: "11/07/24",
    state: `${labels.signBy} Albert Maffre (2/5)`,
    currentStep: 3,
    maxSteps: 5,
    type: "physical",
    sealed: false,
  },
];

const pdfs = [
  "https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf",
];

const dataForPartiesTable: SignatoriesTableProps[] = [
  {
    imageUrl: "assets/avatar-male.png",
    document: "e-divorce Dupont",
    IDdocument: [{ name: "albert-dupont.png" }],
    surname: "Dupont",
    name: "A Albert",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:36",
    email: "albert.dupont@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "person",
    toBeSealedMessage: `${labels.sealedBy}`,
    toBeSealedBy: [{ id: "125", name: "Maître Cléa Jobert" }],
    advisedByMessage: `${labels.advisedBy}`,
    advisedBy: [{ id: "", name: "" }],
  },
  {
    icon: "profile-male",
    document: "e-divorce Dupont",
    surname: "Dupont",
    name: "B Albert",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:12",
    email: "albert.dupont@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "person",
    toBeSealedMessage: `${labels.sealedBy}`,
    toBeSealedBy: [{ id: "125", name: "Maître Cléa Jobert" }],
    advisedByMessage: `${labels.advisedBy}`,
    advisedBy: [{ id: "", name: "" }],
  },
  {
    icon: "apartment",
    document: "e-convention-031125",
    legalName: "Matthieu",
    legalSurname: "Lopez",
    lastActivity: "11/07/24 23:26",
    email: "matthieu.lopez@persus.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "company",
    sirenNumber: "21906464900003",
    socialReason: "PERSUS SAS",
    toBeSealedMessage: `${labels.sealedBy}`,
    toBeSealedBy: [{ id: "531", name: "Maître Gérard Manvussa" }],
    advisedByMessage: `${labels.advisedBy}`,
    advisedBy: [{ id: "531", name: "Maître Gérard Manvussa" }],
  },
  {
    imageUrl: "assets/avatar-male.png",
    document: "e-divorce Dupont",
    surname: "Dupont",
    name: "C Albert",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:36",
    email: "albert.dupont@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "person",
    toBeSealedMessage: `${labels.sealedBy}`,
    toBeSealedBy: [{ id: "215", name: "Maître Caroline Dumond" }],
    advisedByMessage: `${labels.advisedBy}`,
    advisedBy: [{ id: "215", name: "Maître Caroline Dumond" }],
  },
  {
    imageUrl: "assets/avatar-male.png",
    document: "e-divorce Dupont",
    surname: "Dupont",
    name: "D Albert",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:36",
    email: "albert.dupont@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "person",
    toBeSealedMessage: `${labels.sealedBy}`,
    toBeSealedBy: [{ id: "215", name: "Maître Caroline Dumond" }],
    advisedByMessage: `${labels.advisedBy}`,
    advisedBy: [{ id: "215", name: "Maître Caroline Dumond" }],
  },
  {
    imageUrl: "assets/avatar-male.png",
    document: "e-divorce Dupont",
    surname: "Dupont",
    name: "E Albert",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:36",
    email: "albert.dupont@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "person",
    toBeSealedMessage: `${labels.sealedBy}`,
    toBeSealedBy: [{ id: "215", name: "Maître Caroline Dumond" }],
    advisedByMessage: `${labels.advisedBy}`,
    advisedBy: [{ id: "215", name: "Maître Caroline Dumond" }],
  },
  {
    imageUrl: "assets/avatar-male.png",
    document: "e-divorce Dupont",
    surname: "Dupont",
    name: "F Albert",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:36",
    email: "albert.dupont@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "person",
    toBeSealedMessage: `${labels.sealedBy}`,
    toBeSealedBy: [{ id: "125", name: "Maître Cléa Jobert" }],
    advisedByMessage: `${labels.advisedBy}`,
    advisedBy: [{ id: "125", name: "Maître Cléa Jobert" }],
  },
  {
    imageUrl: "assets/avatar-male.png",
    document: "e-divorce Dupont",
    surname: "Dupont",
    name: "G Albert",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:36",
    email: "albert.dupont@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "person",
    toBeSealedMessage: `${labels.sealedBy}`,
    toBeSealedBy: [{ id: "215", name: "Maître Caroline Dumond" }],
    advisedByMessage: `${labels.advisedBy}`,
    advisedBy: [{ id: "215", name: "Maître Caroline Dumond" }],
  },
  {
    imageUrl: "assets/avatar-male.png",
    document: "e-divorce Dupont",
    surname: "Dupont",
    name: "H Albert",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:36",
    email: "albert.dupont@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "person",
    toBeSealedMessage: `${labels.sealedBy}`,
    toBeSealedBy: [{ id: "125", name: "Maître Cléa Jobert" }],
    advisedByMessage: `${labels.advisedBy}`,
    advisedBy: [{ id: "125", name: "Maître Cléa Jobert" }],
  },
  {
    imageUrl: "assets/avatar-male.png",
    document: "e-divorce Dupont",
    surname: "Dupont",
    name: "I Albert",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:36",
    email: "albert.dupont@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "person",
    toBeSealedMessage: `${labels.sealedBy}`,
    toBeSealedBy: [{ id: "125", name: "Maître Cléa Jobert" }],
    advisedByMessage: `${labels.advisedBy}`,
    advisedBy: [{ id: "125", name: "Maître Cléa Jobert" }],
  },
];

const dataForLawyersTable: SignatoriesTableProps[] = [
  {
    icon: "profile-female",
    document: "e-divorce Dupont",
    IDdocument: [{ name: "regine-dutertre.png" }],
    surname: "Régine",
    name: "Dutertre",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:36",
    email: "regine.dutertre@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "physical",
  },
  {
    icon: "profile-female",
    document: "e-divorce Dupont",
    surname: "Céron",
    name: "Francis",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:12",
    email: "francis.ceron@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "physical",
  },
  {
    imageUrl: "assets/avatar-female.png",
    document: "e-divorce Dupont",
    surname: "Jobert",
    name: "Cléa",
    birthDate: "11/02/1959",
    lastActivity: "11/07/24 14:12",
    email: "clea.jobert@gmal.com",
    phone: "7 01 02 03 04",
    phoneCode: "+33",
    type: "physical",
  },
];

const ActGeneralInformation: ActGeneralInformationProps = {
  actNumber: "20251229152606-iaGSSY2sIdNwJMJ6r",
  signingStatus: "en attente de signature",
};

const preferencesDropdownOptions = [
  { value: "econvention", label: labels.conventionTabLabel },
  { value: "dcm", label: labels.divorceTabLabel },
  { value: "natif", label: labels.lawyersDocumentTabLabel },
  { value: "numerise", label: labels.digitalAct },
  // { value: "participatory-procedure", label: labels.participatoryProcedureTabLabel },
  // { value: "e-acts-soon-to-expire", label: labels.soonExpiringDocumentsTabLabel},
  // { value: "e-acts-closed-and-archived", label: labels.closedAndArchivedDocumentsTabLabel },
];
const countryPhoneCodes = [
  "+1", // United States
  "+44", // United Kingdom
  "+33", // France
  "+49", // Germany
  "+81", // Japan
  "+86", // China
  "+91", // India
  "+7", // Russia
  "+61", // Australia
  "+34", // Spain
  "+39", // Italy
  "+52", // Mexico
  "+55", // Brazil
  "+971", // United Arab Emirates
  "+966", // Saudi Arabia
  "+65", // Singapore
  "+82", // South Korea
  "+64", // New Zealand
  "+31", // Netherlands
  "+41", // Switzerland
];

const signatoriesPartyTypeList = [
  { value: "TYPE_NATURAL", label: labels.physicalPerson },
  { value: "TYPE_LEGAL", label: labels.company },
  // {
  //   value: "legalRepresentative",
  //   label: labels.legalRepresentative,
  // },
  // {
  //   value: "companyREpresentative",
  //   label: labels.companyREpresentative,
  // },
];

const signatoriesLawyerDivorceTypeList = [
  { value: "physicalPerson", label: labels.physicalPerson },
];

const facturationOptions = [{ label: "D&E associés", value: "d&e" }];

const wayOfPaymentOptions = [
  { label: "Carte bancaire", value: "creditCard" },
  {
    label: "Compte prépayé",
    value: "prepaidAccount",
    note: `130,00 ${labels.euroSign}`,
  },
];

const multiplePdfs: Record<string, string[]> = {
  single: ["https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf"],
  singular: [
    "https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf",
  ],
  multiple: [
    "https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf",
    "https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf",
    "https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf",
  ],
};

const guideTemplateName =
  "2020_12_02_-_Guide_pratique_Numerisation_des_actes_V1";

const certificateProp = {
  validityPeriod: "24h à partir du 31/05/2025 14:37",
  deliveryName: "Dupont Albert",
  documents: ["CGU de Signature", "e-Convention Maffre"],
};

const years = range(1990, getYear(new Date()) + 2);
const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export {
  DOCUMENTS_MAX_SIZE_MB,
  DOCUMENTS_MAX_SIZE,
  DAY_IN_MILLISECONDS,
  axiosErrorMessages,
  labels,
  toastOptions,
  toastOptionsError,
  dataForToBeSealedTable,
  dataForPartiesTable,
  dataForLawyersTable,
  ActGeneralInformation,
  dropdownOptions,
  dropdownOptionsHistoryFilters,
  preferencesDropdownOptions,
  countryPhoneCodes,
  signatoriesPartyTypeList,
  pdfs,
  facturationOptions,
  wayOfPaymentOptions,
  logos,
  multiplePdfs,
  certificateDeliveryName,
  certificateNumber,
  signatoriesLawyerDivorceTypeList,
  actTypeNameMap,
  actMapAmount,
  guideTemplateName,
  certificateProp,
  years,
  months,
};

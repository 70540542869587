import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import ButtonDropdown from "@ui/ButtonDropdown";
import DndComponent from "@ui/DndComponent";
import FileUpload from "@ui/FileUpload";
import Icon from "@ui/Icon";
import ProgressBar from "@ui/ProgressBar";
import Spinner from "@ui/Spinner";

import {
  DOCUMENTS_MAX_SIZE,
  DOCUMENTS_MAX_SIZE_MB,
  actTypeNameMap,
  axiosErrorMessages,
  labels,
  toastOptionsError,
} from "@constants";

import {
  setLastReachedStep,
  setValidatedData,
} from "@reducers/actCreationSlice";
import { selectUser } from "@reducers/metadataSlice";
import { showModal } from "@reducers/modalsSlice";
import {
  selectDocuments,
  selectOrderChanged,
  selectValidatedDocuments,
  setDocuments,
  setOrderChanged,
  setValidatedDocuments,
} from "@reducers/validationSlice";

import { Document, PathMap, SortableFileProps } from "@types";

import {
  addQueryParams,
  bytesToMegabytes,
  fetchAndDownloadTemplate,
  formatLabel,
  getPathMap,
} from "@utils";

const DocumentsForSigning = () => {
  const navigate = useNavigate();
  const { actType, publicId } = useParams();
  const [files, setFiles] = useState<Document[]>([]);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [actTitle, setActTitle] = useState<string>("");
  const [hasCGU, setHasCGU] = useState(false);
  const dispatch = useDispatch();
  const documents = useSelector(selectDocuments);
  const validatedDocuments = useSelector(selectValidatedDocuments);
  const pathMap: PathMap = getPathMap(actType, publicId);
  const secondStepPath = pathMap[2]?.url || "";
  const fourthStepPath = actType !== "digital" ? pathMap[4]?.url || "" : "";
  const orderChanged = useSelector(selectOrderChanged);
  const user = useSelector(selectUser);

  // Show the validation button if we've uploaded documents but
  // Not all of them have been validated yet.
  const showValidationButton =
    0 !== documents.length &&
    !documents.every((document) => validatedDocuments.includes(document));

  useEffect(() => {
    if (0 === files.length) {
      dispatch(setValidatedData(false));
    }
  }, [files, dispatch]);

  useEffect(() => {
    const isStepValid =
      0 !== documents.length &&
      documents.every((document) => {
        return validatedDocuments.includes(document);
      });

    dispatch(setValidatedData(isStepValid));
  }, [documents, validatedDocuments, dispatch]);

  useEffect(() => {
    if (!publicId) {
      return;
    }
    // GET request for the act title and type
    axios
      .get(`/api/v1/signbooks/${publicId}`)
      .then((response) => {
        setActTitle(response.data.name);
        // If the act type is different from the one we're on, we redirect to the correct one
        const actTypeDcm = actTypeNameMap[response.data.businessType];

        const normalizedType =
          undefined !== actTypeDcm
            ? actTypeDcm
            : actTypeNameMap[response.data.type];
        if (normalizedType !== actType) {
          navigate(`/acts/${normalizedType}/${publicId}/documents`);
        }
        const responseStep = response.data.metaDataValues.find(
          (item: any) => item.code === "wfStep",
        )?.value;

        dispatch(setLastReachedStep(responseStep));
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  }, [publicId, actType, navigate, dispatch]);

  const storeFiles = (
    filesToStore: Document[],
    existingFiles: Document[] = files,
  ) => {
    const newFiles = [...existingFiles, ...filesToStore];

    setFiles(newFiles);
    // Calculate the total filesize of every file in bytes
    // taking existing uploaded files into account.

    const totalFileSize = newFiles.reduce((acc, file) => acc + file.size, 0);

    // Check if the total filesize exceeds the limit.
    if (totalFileSize > DOCUMENTS_MAX_SIZE) {
      toast.error(
        formatLabel(
          labels.filesizeLimitReached,
          bytesToMegabytes(DOCUMENTS_MAX_SIZE).toString(),
        ),
        toastOptionsError,
      );
      return;
    }
    dispatch(setDocuments(newFiles.map((file) => file.id)));
    const uploadedFilesSize = Math.ceil(bytesToMegabytes(totalFileSize));

    setTotalFileSize(uploadedFilesSize);
  };

  useEffect(() => {
    if (!publicId) {
      return;
    }
    setLoading(true);

    // GET request to see if there are uploaded documents
    axios
      .get(`/api/v1/signbooks/${publicId}/documents`)
      .then((response) => {
        setInitializing(false);

        const documents = response.data;

        if (documents.length > 0) {
          const otherDocuments: any[] = [];

          const newDocuments = documents.map(
            (document: Document, index: number) => {
              if (document.type === "CGU") {
                setHasCGU(true);
              }

              const formattedDocument = {
                id: document.documentUid,
                name:
                  document.documentName?.split("/")[1] || document.documentName,
                size: document.fileSize,
                order:
                  document.type === "CGU" ? 0 : document.order || index + 1,
                validated: document?.metadata?.conversionValidated,
                fixed: document.type === "CGU",
              };
              otherDocuments.push(formattedDocument);

              return formattedDocument;
            },
          );

          storeFiles([], newDocuments);

          otherDocuments.sort((a: any, b: any) => a.order - b.order);
          setFiles(otherDocuments);

          dispatch(
            setValidatedDocuments(
              newDocuments
                .filter((document: Document) => document.validated)
                .map((file: Document) => file.id),
            ),
          );
          dispatch(setDocuments(newDocuments.map((file: Document) => file.id)));
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicId, dispatch]);

  const validateFile = (documentId: string) => {
    setLoading(true);
    axios
      .put(`/api/v1/signbooks/${publicId}/documents/${documentId}`)
      .then(() => {
        const newValidatedDocuments = [...validatedDocuments, documentId];

        dispatch(setValidatedDocuments(newValidatedDocuments));
        dispatch(setValidatedData(true));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const deleteFile = (documentId: string) => {
    setLoading(true);
    axios
      .delete(`/api/v1/signbooks/${publicId}/documents/${documentId}`)
      .then(() => {
        const newFiles = files.filter((file) => file.id !== documentId);

        storeFiles([], newFiles);
        if (newFiles.length === 0) {
          dispatch(setValidatedData(false));
        }

        const newValidatedDocuments = validatedDocuments.filter(
          (id) => id !== documentId,
        );
        dispatch(setValidatedDocuments(newValidatedDocuments));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const isDocumentValidated = (id: string) => validatedDocuments.includes(id);

  const updatedNewlySortedFiles = (path: string) => {
    const dataToUpdate = files.map((file) => ({
      documentUid: file.id,
      order: file.fixed ? 0 : file.order,
      documenType: "other",
    }));

    axios
      .put(
        `/api/v1/signbooks/${publicId}/documents/signatureorder`,
        dataToUpdate,
      )
      .then(() => {
        dispatch(setOrderChanged(false));
        navigate(path);
      })
      .catch((error) => {
        toast.error(axiosErrorMessages[error.message], toastOptionsError);
      });
  };

  const SortableFile = ({
    file,
    itemId,
    index,
    isFixed,
  }: SortableFileProps) => {
    const { active, attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: itemId, disabled: isFixed });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    const handleIcon = active?.id === itemId ? "handle-active" : "handle";

    const validated = isDocumentValidated(itemId);

    const buttonOptions = [
      {
        label: labels.view,
        link: addQueryParams(`/acts/${actType}/new/documents/preview`, {
          documentId: itemId,
          returnPath: `/acts/${actType}/${publicId}/documents`,
          actId: publicId,
          actTitle: actTitle,
          documentName: file.name,
          lawyer: user?.id ? "true" : "",
        }),
      },
      {
        label: labels.delete,
        onClick: () => {
          deleteFile(itemId);
        },
      },
    ];

    // If the document is validated, we don't need the "validate" option.
    if (validated) {
      buttonOptions.shift();
    }

    if (isFixed) {
      buttonOptions.pop();
    }

    // Add class to the last two items if there is a scrollbar.
    const itemClass =
      files.length > 5 && index >= files.length - 2 ? "dropdown-top" : "";

    return (
      <div
        ref={setNodeRef}
        style={style}
        className={`${itemClass} flex justify-between items-center mt-[24px] first:mt-0`}
        // data-order={index + 1}
      >
        <span className="flex w-full items-center py-[5px]">
          {files.length > 2 && !isFixed && (
            <span
              className="cursor-pointer mr-[14px]"
              {...attributes}
              {...listeners}
            >
              <Icon type={handleIcon} className="size-[24px]" />
            </span>
          )}
          <img src="/assets/pdf-icon.png" alt="" />
          <span className="ml-4 body-sm">{file.name}</span>
        </span>

        {validated && (
          <Icon
            type="check"
            color="#00C45A"
            className="size-[20px] mr-[20px]"
          />
        )}

        <ButtonDropdown
          options={buttonOptions}
          disabled={loading}
          buttonLabel={validated ? labels.view : labels.validate}
          onClick={() => {
            // If the document is not validated we validated it on click and return.
            // Otherwise, we navigate to the preview page.
            if (!validated) {
              dispatch(setValidatedData(false));
              validateFile(file.id);

              return;
            }

            const documentsPage =
              "convention" === actType ? "convention" : "documents";

            navigate(
              addQueryParams(`/acts/${actType}/new/documents/preview`, {
                documentId: file.id,
                returnPath: `/acts/${actType}/${publicId}/${documentsPage}`,
                actId: publicId,
                actTitle: actTitle,
                documentName: file.name,
                lawyer: user?.id ? "true" : "",
              }),
            );
          }}
        />
      </div>
    );
  };

  // Only add the scrolling classes if there are more than 5 files.
  const filesContainerClass =
    files.length > 5
      ? "adjustable-dropdown scrollbar-outside overflow-y-auto max-h-[266px]"
      : "";

  const blockTitle = () => {
    switch (actType) {
      case "birth":
        return labels.documentsToSign;
      case "convention":
        return labels.agreementsToSign;
      case "divorce":
        return labels.addDocumentButton;
      default:
        return labels.documentsToSign;
    }
  };

  const description =
    "convention" === actType ? (
      <div className="flex">
        <p className="body-sm">{labels.addAgreements}</p>
        <Button
          onClick={() => {
            fetchAndDownloadTemplate("signTemplateECONV");
          }}
          className="body-sm font-bold underline ml-1"
        >
          {labels.downloadAgreementTemplate}
        </Button>
      </div>
    ) : (
      <p className="body-sm">{labels.documentsAddText}</p>
    );

  const uploadUrl = `/api/v1/signbooks/${publicId}/documents`;

  if (initializing) {
    return (
      <div className="flex justify-center items-center p-6 -mb-2 bg-[#F9F9F9]">
        <Spinner className="size-10 !border-t-black" />
      </div>
    );
  }
  const nextButtonLabel =
    actType === "digital" ? labels.signAndCertifyAct : labels.next;

  return (
    <>
      <div className="p-[40px]">
        <div className="flex justify-between mb-[20px]">
          <h2 className="headline-lg">{blockTitle()}</h2>
          {0 !== files.length && actType !== "convention" && (
            <div className="flex">
              <button
                className="flex items-center body-lg btn-secondary py-[5px] px-[20px] rounded-lg"
                onClick={() => {
                  dispatch(showModal("shareDocuments"));
                }}
              >
                <Icon type="share" className="size-[20px] mr-[10px]" />
                {labels.shareShort}
              </button>
            </div>
          )}
        </div>
        <div>{description}</div>

        <div className="my-[40px]">
          {0 !== files.length && actType === "divorce" && (
            <div className="flex body-sm mb-5">
              <ProgressBar
                currentStep={totalFileSize}
                maxSteps={DOCUMENTS_MAX_SIZE_MB}
              />
              <p className="ml-2">
                {`${totalFileSize}/${DOCUMENTS_MAX_SIZE_MB} ${labels.mb}`}
              </p>
            </div>
          )}

          {loading ? (
            <div className="w-full flex justify-center">
              <Spinner className="my-4 size-4 !border-t-slate-500" />
            </div>
          ) : (
            <FileUpload
              files={files}
              setFiles={storeFiles}
              minimized={0 !== files.length}
              url={uploadUrl}
              maxFileSize={DOCUMENTS_MAX_SIZE}
              setLoading={setLoading}
            />
          )}
        </div>

        {0 !== files.length && (
          <>
            <div className={`${filesContainerClass} mt-[48px]`}>
              <DndComponent items={files} setItems={setFiles}>
                {files.map((file, index) => (
                  <SortableFile
                    file={file}
                    key={index}
                    itemId={file.id}
                    index={index}
                    isFixed={file.fixed}
                  />
                ))}
              </DndComponent>
            </div>
          </>
        )}

        <div className="flex justify-between mt-[40px]">
          <Button
            className="btn-base btn-secondary"
            onClick={() => {
              if (orderChanged) {
                updatedNewlySortedFiles(`/acts/${actType}${secondStepPath}`);
                return;
              }
              navigate(`/acts/${actType}${secondStepPath}`);
            }}
          >
            {labels.previous}
          </Button>

          {showValidationButton && (
            <Button
              className="btn-base btn-primary"
              onClick={() => {
                dispatch(showModal("validateDocuments"));
              }}
              data-testid="validate-and-continue"
            >
              {labels.validateDocumentAndContinue}
            </Button>
          )}

          {0 !== files.length && !showValidationButton && (
            <Button
              className="btn btn-primary rounded-lg py-2 px-4 body-md flex items-center justify-center"
              data-testid="next"
              disabled={loading || (hasCGU && 1 === files.length)}
              onClick={() => {
                if (actType !== "digital") {
                  if (orderChanged) {
                    setLoading(true);
                    updatedNewlySortedFiles(
                      `/acts/${actType}${fourthStepPath}`,
                    );
                    return;
                  }
                  navigate(`/acts/${actType}${fourthStepPath}`);
                  return;
                }

                navigate(`/acts/${actType}`);
              }}
            >
              {loading && <Spinner className="mr-2 size-4" />}
              {nextButtonLabel}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentsForSigning;

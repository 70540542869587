import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import Spinner from "@ui/Spinner";
import Table from "@ui/Table";

import { actTypeNameMap, labels } from "@constants";

import useIsMobile from "@hooks/useIsMobile";
import useWindowSize from "@hooks/useWindowSize";

import { RequiredActionsContext } from "@pages/RequiredAction";

import { setModalData } from "@reducers/dataTransferSlice";
import { selectUser } from "@reducers/metadataSlice";

import { DocumentsForValidatingTableProps } from "@types";

import { addQueryParams, formatTimestamp } from "@utils";

const DocumentsForValidatingTable = () => {
  const dispatch = useDispatch();
  const data = useContext(RequiredActionsContext);
  const user = useSelector(selectUser);
  const isMobile = useIsMobile();
  const paddingMobile = !isMobile ? "pr-[40px]" : "";

  const columns = [
    {
      label: labels.documentName,
      render: (data: DocumentsForValidatingTableProps) => {
        return (
          <div className="flex">
            <img
              src="/assets/pdf-icon.png"
              alt="pdf icon"
              className="mr-[16px]"
            />
            <p className="body-md overflow-hidden whitespace-nowrap text-ellipsis body-xl">
              {data.sbDocumentName}
            </p>
          </div>
        );
      },
      className: "body-lg py-[15px] h-[90px] overflow-hidden",
    },
    {
      label: labels.draftingLawyer,
      render: (data: DocumentsForValidatingTableProps) => data.sbCreatorName,
    },
    {
      label: labels.document,
      render: (data: DocumentsForValidatingTableProps) => data.name,
    },
    {
      label: labels.deadline,
      render: (data: DocumentsForValidatingTableProps) =>
        formatTimestamp(data.expirationDate, false),
    },
    {
      label: "",
      render: (data: DocumentsForValidatingTableProps) => {
        const actType = actTypeNameMap[data.type] || "";

        return (
          <NavLink
            to={addQueryParams(`/acts/${actType}/new/documents/preview`, {
              actId: data.publicId || "",
              documentId: data.sbDocumentUid || "",
              action: "validate",
              returnPath: "/required-actions/validate-documents",
              lawyer: user?.id ? "true" : "",
            })}
            className="flex items-center justify-center px-[15px] py-[10px] body-md rounded-lg max-w-[80px] w-[100%] btn-secondary-emphasize"
            onClick={() => dispatch(setModalData(data))}
          >
            {labels.validate}
          </NavLink>
        );
      },
      className: `body-lg py-[15px] flex justify-end h-[90px] items-center ${paddingMobile}`,
    },
  ];

  if (useWindowSize() <= 740) {
    columns.splice(1, columns.length - 2);
  }

  return (
    <>
      {data.loading ? (
        <div className="w-full h-full pt-[40px] flex justify-center items-center">
          <Spinner className="size-10" dark={true} />
        </div>
      ) : (
        <Table columns={columns} rows={data.tableData.validating} />
      )}
    </>
  );
};

export default DocumentsForValidatingTable;
